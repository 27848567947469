/* Basic Adjustments */

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.percentage-versus-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.percentage-versus-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 0;
  display: block;
  color: #263238;
  font-family: "Roboto" !important;
  font-weight: bold;
}

.percentage-versus-line {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
}

.account-values-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;
}

.account-value {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 0.5rem 0.5rem;
}

.account-value-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.375rem;
}

.account-active-value-title-indicator {
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  background-color: #2D2E8F;
  border-radius: 2px;
}

.account-inactive-value-title-indicator {
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  background-color: #020658;
  border-radius: 2px;
}

.account-value-title-small {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #263238;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  font-weight: bold;
}

.account-value-number-active {
  position: relative;
  width: auto;
  height: auto;
  padding: 0 0 0 1rem;
  color: #2D2E8F;
  font-family: "Roboto" !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.account-value-number-inactive {
  position: relative;
  width: auto;
  height: auto;
  padding: 0 0 0 1rem;
  color: #020658;
  font-family: "Roboto" !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.right-total-accounts-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0;
}

.right-total-accounts {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #717171;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  font-weight: normal;
}

.percentage-data-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.percentage-data-1 {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 1rem;
  color: #ffffff;
  background-color: #2D2E8F;
  text-align: right;
  font-family: "Roboto" !important;
  font-size: 0.9rem;
  font-weight: normal;
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}

.percentage-data-2 {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 1rem;
  color: #ffffff;
  background-color: #020658;
  text-align: right;
  font-family: "Roboto" !important;
  font-size: 0.9rem;
  font-weight: normal;
  border-end-end-radius: 5px;
  border-top-right-radius: 5px;
}

/* .percentage-versus-line-has {
  // display: flex;
  // flex-direction: column;
  // text-align: center;
  background-color: #737dff;
  height: 5px;
  border-radius: 2px;
}

.percentage-versus-line-has-percents {
  display: flex;
  flex-direction: row;
}
.percentage-1 {
  font-family: "Roboto Bold";
  font-size: 24px;
  color: #737dff;
  margin-right: 10px;
}
.percentage-2 {
  font-size: 12px;
  font-weight: bold;
  color: #0ab8b2;
}
.percentage-data-1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding-bottom: 5px;
}
.border-color1 {
  border-bottom: 5px solid #5c9afe !important;
}
.border-color2 {
  border-bottom: 5px solid #d5dcf8 !important;
}
.data-title-percentage {
  color: #67698b;
  font-size: 14px;
  margin-top: 8px;
}

.percentage-versus-line-hasnt {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.percentage-versus-line-hasnt-percents {
  display: flex;
  flex-direction: row;
}

.title-bottom {
  display: flex;

  .percentage-versus-line-has-title {
    width: 50%;
    text-align: center;
  }
  .percentage-versus-line-hasnt-title {
    width: 50%;
    text-align: center;
  }
}

.percentage-data {
  display: flex;

  .percentage-versus-line-has-title {
    width: 50%;
    text-align: center;
  }
  .percentage-versus-line-hasnt-title {
    width: 50%;
    text-align: center;
  }
}

.percentage-line-full {
  background-color: #d5dcf8;
  width: 100%;
  height: 5px;
  border-radius: 2px;
} */
