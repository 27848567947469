@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.select-container-clients-wallet {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  .option-select-clients-wallet-description{
    font-family: "Roboto";
    color: $color-primary;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    padding: 0;
    margin: 0;
  }
}

.select-options-clients-wallet {
  position: absolute;
  min-width: 120px;
  width: 100%;
  height: auto;
  padding: 0;
  top: 2.3rem;
  right: auto;
  bottom: auto;
  left: 0rem;
  display: none;
  color: $color-primary;
  font-size: $msg-font-size;
  font-weight: $font-weight-sm;
  border: none;
  border-radius: 2px 2px 20px 20px;
  transition: all 0.3s;
  z-index: 9999;
  box-shadow: 0px 9px 28px 8px #0000000d;
  box-shadow: 0px 6px 16px 0px #00000014;
  box-shadow: 0px 3px 6px -4px #0000001f;
  background-color: $white;
  text-align: center;
}

.select-options-clients-wallet::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background: transparent;
}

.select-options-clients-wallet::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  box-shadow: inset 0 0 3px $color-primary;
}

.select-options-clients-wallet:hover::-webkit-scrollbar {
  background: $color-primary;
  color: $white;
}

.select-options-clients-wallet.open-clients-wallet {
  display: block;
}
.option-select-clients-wallet-general-container:hover{
  background-color: $color-background;
}
.option-select-clients-wallet-general-container:last-child:hover{
  background-color: $color-background;
  border-radius: 0 0 20px 20px;
}

.option-select-clients-wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px 8px 12px;
  width: 100%;
  z-index: 500;
  text-align: center;
  gap: 8px;
  white-space: nowrap;
  .option-select-clients-wallet-description {
    font-family: "Roboto";
    color: $color-primary;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    margin: 0;
    padding: 0;
  }
}

.arrow-invert {
  transform: rotate(180deg);
}

.select-clients-wallet-arrow {
  margin-left: auto;
}

.select-container-clients-wallet-disable-option {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0;
  gap: 0;
  border: 0;
  border-radius: 0;
}
.select-container-clients-wallet-active-option {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0;
  gap: 0;
  border: 0;
  border-radius: 0;
}

.option-select-clients-wallet-divider {
  border-bottom: none;
}

.select-search-loading {
  font-family: "Roboto";
  font-size: $msg-font-size;
  color: $color-gray;
  font-weight: $font-weight-sm;
  margin: 0;
  padding: 0px 5px;
}
