@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.input-text-clients-wallet-container {
  position: relative;
  width: auto;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  border-radius: 62px;
  border: 1px solid $color-gray;
  margin-top: 10px;
  margin-bottom: 5px;
  min-width: 300px;
  .input-text-clients-wallet {
    min-width: 250px;
    font-size: $msg-font-size;
    color: $color-gray;
    font-weight: $font-weight-sm;
    border: none;
    outline: none;
    font-family: "Roboto";
    cursor: pointer;
    background-color: transparent;
    &::placeholder {
      font-size: $msg-font-size;
      color: $color-gray;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
      background-color: transparent;
    }
  }
}
