@import "../../../../index.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.ordenes-en-proceso {
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .ordenes-en-proceso-header {
    .ordenes-en-proceso-header-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .ordenes-en-proceso-header-tabs-title {
        position: relative;
        padding-bottom: 8px;
        width: 18%;
        top: 9px;
        text-align: center;
        font-size: 11px;
        font-family: "Roboto" !important;
        font-weight: $font-weight-semi-bold;
        color: $mid-blue;
        border-bottom-style: solid;
        border-bottom-color: $color-primary;
        border-bottom-width: 2px;
      }

      .ordenes-linea-horizontal {
        position: absolute;
        height: 1px;
        width: calc(100% - 34px);
        color: rgb(192, 186, 186);
        background: rgb(212, 209, 209);
        bottom: 8px;
      }
    }

    .ordenes-en-proceso-header-selections {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .datepickers {
        display: flex;
        flex-direction: row;
        padding: 5px 20px 5px 25px;
        background: $color-background;
        border-radius: 20px;
        .ant-picker {
          background-color: transparent !important;
          gap: 15px !important;
        }
      }

      .operate-process-orders-reload-button-container {
        cursor: pointer;

        .operate-process-orders-reload-button {
          .operate-process-orders-reload-icon {
            width: 24px;
            height: 24px;
          }

          .operate-process-orders-reload-icon-disabled {
            width: 24px;
            height: 24px;
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
    .operate-dashboard-filter-tabs{
      .operate-sub-items-container{
        .sub-items{
          font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        text-align: center;
        font-family: "Roboto";
        padding: 5px 15px 5px 15px;
        gap: 10px;
        color: $color-text-black;
        cursor: pointer;
          &.selected-sub-item{
            border-radius: 100px;
            border: 1px solid $color-primary;
            color: $color-primary
          }
        }
      }
    }
  }

  .ordenes-en-proceso-table-header {
    position: relative;
    width: calc(100% - 7.25px);

    .filter-header-options {
      display: none;

      .filter-header-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: $light-gray;
        font-family: "Roboto" !important;
        height: 33%;
        padding-left: 10px;
        cursor: pointer;
      }
    }

    .openFilterHeader {
      display: block;
      position: absolute;
      top: 40px;
      left: 5px;
      width: 220px;
      height: 140px;
      border-radius: 10px;
      border: 2px solid $dark-blue;
      background: $white;

      .filter-header-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: $light-gray;
        font-family: "Roboto" !important;
        height: 33%;
        padding-left: 10px;
        cursor: pointer;
      }

      .filter-header-option:hover {
        color: $mid-blue;
        font-weight: 700;
      }

      .optionSelectedType {
        color: $dark-blue;
      }
    }
  }

  .ordenes-en-proceso-table-pendientes {
    width: 100%;
    overflow: auto;
    zoom: 93%;
  }

  .ordenes-en-proceso-table-pendientes .ant-table-thead > tr > th {
    font-family: "Roboto" !important;
    font-weight: $font-weight-semi-bold;
    font-size: $msg-font-size;
    padding: 8px !important;
    border-radius: 0px !important;
    background-color: $color-primary;
    color: $white;
    text-align: center;
  }

  .ordenes-en-proceso-table-pendientes .ant-table-tbody > tr > td {
    font-family: "Roboto" !important;
    font-weight: normal;
    font-size: $roboto-12-medium-font-size;
    padding: 8px !important;
    text-align: center;
  }

  .ordenes-en-proceso-table-pendientes .ant-table-tbody > tr:nth-child(even) {
    background-color: $white;
  }

  .ordenes-en-proceso-table-pendientes .ant-table-pagination.ant-pagination {
    margin: 0px;
    padding: 16px;
    background: $color-primary;
    border-radius: 0px 0px 10px 10px;
  }

  .venta {
    color: $color-success;
    font-weight: $font-weight-bold;
  }

  .compra {
    color: $color-error;
    font-weight: $font-weight-bold;
  }

  .acciones-active {
    position: relative;
    width: auto;
    height: auto;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0;
    font-family: "Roboto" !important;
    font-size: 0.625rem;
    font-weight: 700;
    cursor: pointer;
    color: $color-primary;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid $color-primary;
    border-radius: 20px;
    background: transparent;
    &:hover{
      background-color: $color-primary;
      color: $white;
      border: 1px solid $color-primary;
    }
  }

  .acciones-inactive {
    font-size: $roboto-12-medium-font-size;
    font-family: "Roboto" !important;
    font-weight: $font-weight-bold;
    color: $color-primary-light;
    text-decoration: underline;
  }
  .operate-dashboard-filter-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .operate-sub-items-container {
      .sub-items-left,
      .sub-items-right {
        padding: 5px 15px 5px 15px;
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        text-align: center;
        color: $color-primary;
        font-family: "Roboto";
        background-color: transparent;
        height: 32px;
        cursor: pointer;
        &.selected-sub-item {
          border-radius: 100px;
          border: 1px solid $color-primary;
        }
      }
    }
  }
}
