@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.form-problem {
  position: relative;
  background-color: transparent;
  margin-top: 11vh;
  .form-problem-title{
    padding-bottom: 1vh;
  }
  .form-problem-login {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 20px;
    color: $color-text-black;
    
    .container-name-surname-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 15px;
      .container-data-user {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .input-data-user {
        position: relative;
        width: 100%;
        outline: none;
        border: 1px solid $color-gray;
        background: transparent;
        padding: 4px 15px;
        border-radius: 20px;
        font-family: 'Roboto Regular', sans-serif;
        font-size: $roboto-12-medium-font-size;
        &::placeholder {
          font-family: 'Roboto Regular', sans-serif;
          font-size: $roboto-12-medium-font-size;
          background-color: transparent;
          color: $color-text-black;
        }
      }

      .container-code-number-user {
        width: 50%;
      }
      .container-number-user {
        width: 100%;
      }
    }
    .container-telephone {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      .description-telephone {
        font-size: $roboto-12-medium-font-size;
        color: $color-gray;
        margin: 0 auto;
        text-align: center;
      }
    }
    .container-button-form-problem-login {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 10px;
      .button-send-form {
        background: $color-primary;
        border-radius: 100px;
        border: none;
        color: $white;
        padding: 10px 25px;
        font-size: $roboto-12-medium-font-size;
        font-family: 'Roboto', Arial, Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
      }
      .button-return-login {
        background: transparent;
        border-radius: 100px;
        border: 1px solid $color-primary;
        color: $color-primary;
        padding: 10px 25px;
        font-size: $roboto-12-medium-font-size;
        font-family: 'Roboto', Arial, Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}
.container-email-user {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .input-data-user {
    position: relative;
    width: 100%;
    outline: none;
    border: 1px solid $color-gray;
    background: transparent;
    padding: 4px 15px;
    border-radius: 20px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: $roboto-12-medium-font-size;
    &::placeholder {
      font-family: 'Roboto Regular', sans-serif;
      font-size: $roboto-12-medium-font-size;
      background-color: transparent;
      color: $color-text-black;
    }
  }
}

.container-textarea-data {
  width: 100%;
  display: flex;
  .textarea-data-form {
    width: 100%;
    height: 100px;
    background: transparent;
    border: 1px solid $color-gray;
    border-radius: 20px;
    resize: none;
    padding: 5px 15px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: $roboto-12-medium-font-size;
    outline: none;
    &::placeholder {
      font-family: 'Roboto Regular', sans-serif;
      font-size: $roboto-12-medium-font-size;
      background-color: transparent;
      color: $color-text-black;
    }
  }
}
