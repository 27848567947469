:root {
    --title: #4d4d4d;
    --text: #717171;
}

.team-modal-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    //margin: 0px 20px;
}

.left-modal-title {
    font-family: "Roboto" !important;
    font-size: 1rem;
    color: var(--title);
    margin-bottom: 30px;
    text-align: left;
    //text-transform: uppercase;
}

.modal-text {
    font-family: "Roboto" !important;
    font-size: 18px;
    color: var(--text);
    margin-bottom: 20px;
}

.modal-xl-text {
    font-family: "Roboto" !important;
    font-size: 1.25rem;
    color: var(--title);
    margin-bottom: 20px;
}

.text-center {
    text-align: center;
}

.info-container {
    padding: 10px;
}

.radio-buttons-group {
    padding: 30px;
}

.radio-button {
    padding: 20px;
}

.my-10px {
    margin: 10px 0px;
}

.team-form-container > .team-form-control {
    background-color: #C4C4C41A;
}

.team-modal-container > .new-btn-primary:hover {
    background-color: #737DFF !important;
    border-color: #737DFF !important;
}

.buttons-footer-container > .new-btn-primary:hover {
    background-color: #737DFF !important;
    border-color: #737DFF !important;
}

.footer-cta-button:hover {
    background-color: #737DFF !important;
    border-color: #737DFF !important;
}

.modal-internal-component-title {
    font-family: "Roboto" !important;
    font-size: 0.8rem;
    color: #2D2E8F;
}

.team-item-separator {
    position: relative;
    width: 100%;
    height: 1rem;
    padding: 0;
}

.input-search-producer {
    position: relative;
    width: inherit;
    height: inherit;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.input-search-producer > .input-text-clients-wallet {
    position: relative;
    width: 100%;
    padding: 0;
}

.input-search-producer > .input-text-clients-wallet::placeholder {
    color: #A4A7BE;
}

.input-search-producer img {
    margin: 0 !important;
}

.skip-upload-button {
    font-size: 0.8rem;
}

.calendar-container {
    height: 200px !important;
    box-shadow: 0px 3px 20px #00000029 !important;
}

.radio-buttons-group {
    padding: 1rem 0 !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 1rem 1rem;
}

.radio-buttons-group > .radio-button {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
}

.modal-important-text {
    font-family: "Roboto" !important;
    font-size: 1.25rem;
    font-weight: 600;
    color: #2D2E8F;
}