.container-table-treasury{
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.container-data-table-treasury{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
 border-radius: 10px;
 background-color: #737dff;
  color: #ffffff;
  padding: 0.75rem;
  font-family: "Roboto" !important !important;
}
.container-data-table-treasury div{
text-align: center;
}
 button.boton-filtro-treasury {
  vertical-align: bottom;
  background-color: #737dff;
  
  text-align: center;
  font-size: 12px;
  border: 0px;
  cursor: pointer;
}
.active {
  button.filter-div {
    font-weight: bold;
    color: #5C9AFE;
    background: #5C9AFE12;
    border: 1px solid #5C9AFE !important;
    border-radius: 15px;
  }
}
 button.boton-filtro-treasury.ascending::after {
  content: '▼';
  display: inline-block;
  margin-left: 1em;
  color: #5C9AFE;
}
 button.boton-filtro-treasury.descending::after {
  content: '▲';
  display: inline-block;
  margin-left: 1em;
  color: #5C9AFE;
}
 button.boton-filtro-treasury.neutral::after {
  content: '-';
  display: inline-block;
  margin-left: 1em;
  color: #5C9AFE;
}
.filter-active{
  border: 0px;
  background-color: transparent;
  cursor: pointer;
}

.container-information-treasury{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  text-align: center;
}
.container-individual-data{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0.75rem;
  border-radius: 10px;
  font-family: "Roboto" !important !important;
}
.container-individual-data div{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}
.container-individual-data:nth-of-type(even) {
  background-color: var(--strip);
}
.width5{
  width: 5%;
}
.width10{
  width: 10%;
}
.width20{
  width: 20%;
}
.width15{
  width: 15%;
}
t{
  color: #A4A7BE;
}
/* cambio de colores  */
:root {
  --strip: #F7FAFF;
  --date: #707070;
  --bold-blue: #5C9AFE;
  --success: #0AB8B2;
  --pending: #C4CB01;
  --cancelled: #FFAA00;
  --rejected: #E80451;
  --active-icon: #423994;
  --inactive-icon: #D0CDE4;
}
/* .date {
  color: var(--date);
} */
.bold-blue {
  font-family: "Roboto" !important;
  font-weight: 600;
  color: var(--bold-blue);
}
.underline-btn {
  margin-left: 12px;
  text-decoration: underline;
}
.underline-btn:hover {
  text-decoration: none;
}
.text-bold {
  font-family: "Roboto" !important;
  font-weight: 600;
}
.text-success {
  font-family: "Roboto" !important;
  font-weight: 600;
  color: var(--success);
}
.text-pending {
  font-family: "Roboto" !important;
  font-weight: 600;
  color: var(--pending);
}
.text-cancelled {
  font-family: "Roboto" !important;
  font-weight: 600;
  color: var(--cancelled);
}
.text-rejected {
  font-family: "Roboto" !important;
  font-weight: 600;
  color: var(--rejected);
}
.btn-row {
  display: flex;
  justify-content: space-around;
}
.active-icon::before {
  font-size: 15px;
  color: var(--active-icon) !important;
}
.inactive-icon::before {
  font-size: 15px;
  color: var(--inactive-icon) !important;
}
// filter dropdown
.filter-dropdown-container {
  position: relative;
  display: inline-block;
}
.icon-filter {
  color: var(--active-icon);
  margin: 0 5px;
}
.filter-dropdown {
  position: absolute;
  top: 100%;
  left: -10px;
  margin-top: 14px;
  z-index: 1;
  background: #fff;
  border: 1px solid #5C9AFE;
  border-radius: 10px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1);
  ul {
    width: 150px;
    overflow: hidden;
  }
  li {
    font-family: "Roboto" !important;
    font-weight: normal;
    font-size: 14px;
    padding: 15px 20px;
    color: #A4A7BE;
    text-align: left;
  }
  li:nth-child(even) {
    border-top: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;
  }
  li:last-child {
    border-bottom: 0px;
  }
}
