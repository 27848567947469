.team-form-control {
    font-family: "Segoe UI Regular";
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #717171;
    background-color: #fff;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.team-form-container {
    margin-bottom: 1rem;
}

.filepond--credits {
    display: none;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    cursor: pointer;
    font-size:  1.1rem;;
}

.icon-input {
    padding-right: 1rem;
}

input[type="month"]:before {
    content: attr(placeholder)':';
    margin-right: 0.5em;
}

input[type="month"]:valid:before {
    content: "";
    margin-right: 0em;
    // margin-right: 0em;
}