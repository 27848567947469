@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.user-profile-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 400;
  top: 4rem;
  height: auto;
  width: 600px;
  display: none;
  transition: all 0.3s;
  background: $white;
  border-radius: 20px;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px #00000026;
  padding: 1rem;
  gap: 15px;
  right: 5rem;
  .user-profile-view-container-header {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    .user-profile-img {
      position: relative;
      width: auto;
      height: auto;
      vertical-align: middle;
    }
  }
}

.circle-partner-letter-big {
  width: 78px;
  height: 78px;
  line-height: 78px;
  border-radius: 50%;
  background: #B1E9E6;
  color: var(--active);
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
}

.user-profile-view-container.openProfileView {
  display: flex;
}
.user-profile-view-container.open-bank-view {
  display: flex;
  width: 475px !important;
}
.user-profile-view-container-header-info {
  position: relative;
  height: auto;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  gap: 0;
  .user-profile-view-container-header-info-name {
    color: $color-text;
    font-size: $h5-font-size;
    font-weight: $font-weight-medium;
    margin: 0;
  }
  .user-profile-view-container-header-info-email {
    color: $color-primary;
    font-size: $body-sm-font-size;
    margin: 0px 0px 10px;
  }
  .user-profile-view-container-header-info-codRef {
    color: $color-primary;
    font-size: $footnote-description-font-size;
    padding: 4px 10px 4px 10px;
    gap: 3px;
    border-radius: 16px;
    font-family: 'Roboto';
    border: 1px solid #d6d9f7;
    background-color: #ebebfd;
    font-weight: $font-weight-sm;
    span {
      font-weight: $font-weight-bold;
    }
  }
}

.user-profile-view-container-personal-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.user-profile-view-container-personal-data-section-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  gap: 10px;
  .section-title-user-profile {
    text-align: start;
    font-weight: $font-weight-medium;
    font-size: $body-sm-font-size;
    margin: 0px 0px 15px;
    color: $color-text;
  }
}

.section-title-user-profile-numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-title-user-profile-numbers > div {
  width: 29.5%;
}

.section-title-user-profile-location {
  display: flex;
  flex-direction: row;
}

.section-title-user-profile-location-cp {
  width: 35%;
  margin: 0px 0px 0px 15px;
}

.user-profile-view-container-personal-data-section-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 8px;
  gap: 10px;
  .section-title-user-profile {
    text-align: start;
    font-weight: $font-weight-medium;
    font-size: $body-sm-font-size;
    margin: 0px 0px 15px;
    color: $color-text;
  }
}

.user-profile-view-container-transaction-data {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
}

.user-profile-view-container-transaction-data-payment {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0;
  .user-profile-transaction-data-title {
    font-weight: $font-weight-medium;
    font-size: $msg-font-size;
    color: $color-text;
  }
  .container-account-information{
    display: flex;
    flex-direction: row;
    gap: 30px;
    .transaction-data-payment-list-cbu {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      font-weight: normal;
    }
    .transaction-data-payment-list-account {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      font-weight: normal;
    }
    .transaction-data-p {
      margin: 5px 0px;
      color: $color-text;
      font-weight: $font-weight-sm;
      font-size: $body-sm-font-size;
      font-family: "Roboto";
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span{
        margin-left: 5px;
      }
      .copy-text{
        margin-left: 5px;
        color: $color-success;
        font-weight: $font-weight-sm;
        font-size: $msg-font-size;
      }
      .icon-copy{
        margin-left: 6px;
      }
    }
  }
  .payment-data {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem 2rem;
    .checkboxes {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 15px;
      .checkbox-currency {
        position: relative;
        width: auto;
        height: auto;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 0;
        .currency-p {
          margin: 0px 0px 0px 10px;
          color: $color-primary;
          font-weight: $font-weight-sm;
          font-size: $body-sm-font-size;
        }
      }
    }
  }
}

.transaction-data-payment-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin: 15px 0px;
}

.transaction-cbu {
  font-size: 12px;
  font-weight: bold;
}

.cbu-account-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0px 0px 20px;
  font-size: 14px;
  font-weight: normal;
  color: #4d4f5c;
}

.cbu-account-container-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
}

.cbu-account-container-data-names {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
}

.img-radio {
  border-radius: 50%;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.img-radio.checked {
  background: radial-gradient(#7381F7, rgb(255, 255, 255), rgb(255, 255, 255));
}
