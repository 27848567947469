$font-weight-sm: 400;
$font-weight-medium: 500;
$font-weight-semi-bold : 600;
$font-weight-bold : 700;
$font-weight-regular: regular;
$h1-font-size: 38px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h5-font-size: 16px;
$body-sm-font-size: 14px;
$logo-home-login-font-size: 24px;
$roboto-12-medium-font-size: 12px;
$footnote-description-font-size: 12px;
$msg-font-size: 14px;
$title-modal-font-size: 20px;
$xsmall-font-size: 8px;
$small-font-size: 18px;
$x-medium-font-size: 20px;
$medium-font-size: 22px;
$large-font-size: 30px;
$extra-large-font-size: 38px;