@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.boton-comparar-active{
    color: $white;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    cursor: pointer;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
    border-radius: 100px;
    gap: 10px;
    padding: 5px 25px;
}
.boton-comparar-active:hover{
    background-color: #737DFF !important;
}
.boton-comparar{
    cursor: no-drop;
    color: $color-gray;
    border: 1px solid $color-gray;
    background-color: #F5F5F5;
    cursor: pointer;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
    border-radius: 100px;
    gap: 10px;
    padding: 5px 25px;
}

