@import "../../index.scss";
@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.operate-dashboard {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
  .operate-dashboard-tab-bar-container {
    position: relative;
    width: 100%;
    height: auto;
    .tab-bar-general-container {
      position: relative;
      width: 100%;
      height: 5%;
      padding: 0 0 20px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      .tab-bar-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        .tab-bar-options-list-container {
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 15px;
          .tab-bar-option-container {
            position: relative;
            padding: 5px 0 5px 0;
            width: 180px;
            height: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 0;
            border-radius: 100px;
            border: 1px solid $color-primary;
            &.text-selected {
              transition-duration: 900ms;
              color: $white;
              background-color: $color-primary;
              border: 1px solid $color-primary;
            }
            &:not(.text-selected) {
              transition-duration: 900ms;
              background-color: $color-background;
              color: $color-primary;
              border: 1px solid $color-primary;
            }
            .tab-bar-option-text {
              font-family: 'Roboto';
              font-size: $roboto-12-medium-font-size;
              font-weight: $font-weight-sm;
              cursor: pointer;
              text-align: center;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .operate-dashboard-tab-bar-container-gray {
    position: relative;
    width: 100%;
    height: auto;
    .tab-bar-general-container {
      position: relative;
      width: 100%;
      padding: 0 0 20px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      .tab-bar-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        .tab-bar-options-list-container {
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 15px;
          .tab-bar-option-container {
            position: relative;
            padding: 5px 0 5px 0;
            width: 180px;
            height: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            gap: 0;
            border-radius: 100px;
            border: 1px solid $color-gray;
            &.text-selected {
              transition-duration: 900ms;
              color: $color-gray;
              background-color: transparent;
              border: 1px solid $color-gray;
            }
            &:not(.text-selected) {
              transition-duration: 900ms;
              background-color: transparent;
              color: $color-gray;
              border: 1px solid $color-gray;
            }
            .tab-bar-option-text {
              font-family: 'Roboto';
              font-size: $roboto-12-medium-font-size;
              font-weight: $font-weight-sm;
              cursor: not-allowed;
              text-align: center;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .operate-dashboard-general-container {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 !important;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    gap: 30px;
    .wallet-dashboard-container-left {
      width: 29%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 92vh;
      .table-container {
        flex: 1; 
        overflow-y: auto;
      }
    }
    .operate-dashboard-title-container {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      .operate-dashboard-title-text{
        font-family: "Roboto";
        font-size: $x-medium-font-size;
        font-weight: $font-weight-medium;
        color: $color-secondary;
        padding: 0;
        margin: 0;
      }
      .operate-dashboard-info-details{
        font-size: $roboto-12-medium-font-size;
        font-family: "Roboto";
        font-weight: $font-weight-sm;
        color: $color-secondary;
        padding: 0;
        margin-bottom: 1rem;
      }
    }

    .operate-dashboard-selection-container {
      position: relative;
      width: 100%;
      height: auto;
      
    }
    .operate-dashboard-container-right {
      display: flex;
      flex-direction: column;
      width: 65%;
    }
    .wallet-dashboard-container-left{
      .table-container{
        .wallet-clients-list{
          .element.active{
            border: 1px solid $color-primary !important;
          }
        }
      }
    }
  }
}

.operate-dashboard.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.operate-dashboard-general-container > .col-md-8 {
  padding: 0;
}

.data {
  width: 100%;
  padding: 15px 0px;
}

.element-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000000F;
  height: 30px;
}

.element-data-description,
.element-data-value {
  margin: 0px;
}

.element-data-description {
  color: $color-gray;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  font-family: "Roboto";
}

.element-data-value {
  color: $color-text-black;
  font-weight: $font-weight-bold;
  font-size: $roboto-12-medium-font-size;
  font-family: "Roboto";
}

.positive-day-percentage-change {
  color: $color-accent;
}

.negative-day-percentage-change {
  color: $color-error;
}

.container {
  h3 {
    padding: 30px 0px 10px;
    color: var(--dark-gray);
    font-size: 14px;
  }
}

.ordenes-en-proceso-lote-table-pendientes-element {
  .sub-data-list {
    display: none;
    width: 100%;
    margin: auto;

    .sub-data-element {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 10% 14% 7% 8% 11% 5% 5% 8%;
      grid-gap: 1%;
      width: 97%;
      padding: 10px 0px;
      margin: 0px 5px 0px 20px;
      justify-content: space-around;
      background: #fafafa;
      justify-items: center;

      p {
        box-sizing: border-box;
        padding: 5px;
        width: 80px;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-align: center;
      }
    }
  }

  .openSubData {
    display: block;
    margin: 0% 0% 1.25% 0%;
  }

  .sub-data-header-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 10% 14% 7% 8% 11% 5% 5% 8%;
    grid-gap: 1%;
    justify-content: space-around;
    margin: 0px 5px 0px 20px;
    height: 25px;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    background: #f0f1f6;
    justify-items: center;

    .sub-data-column-title {
      text-align: center;
      padding: 10px;
      width: 55px;
      font-size: 12px;
    }
  }

  .sub-data-element {
    .sub-data-element-state-red {
      color: var(--red);
    }

    .sub-data-element-state-green {
      color: var(--bright-green);
    }
  }
}

.modal-general-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 57.5px;
  z-index: 5;
  background-color: rgba(2, 29, 135, 0.19);

  .modal-container {
    position: relative;
    width: 465px;
    height: 392px;
    padding: 15px;
    background: #ffffff;
    margin: 0 auto;
    top: calc(30vh - 150px);
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 25%);
  }

  .close-icon-container {
    position: relative;
    display: flex;
    justify-content: flex-end;

    img {
      cursor: pointer;
    }
  }

  .orders-detail-container {
    width: 80%;
    margin: auto;
    overflow-y: auto;

    .orders-detail-container-header {
      font-family: "Roboto" !important;
      font-size: 12px;
      color: #a9a9bd;
      background: #f6f6fa;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin: 20px 0px;
      text-align: center;

      h5 {
        padding: 0px 20px;
        width: 100%;
      }
    }
  }
}

.ant-table-cell-scrollbar {
  box-shadow: 0 0 0 0 transparent !important;
}

.operate-dashboard-filter-container
  .ant-table-thead
  > tr:first-child
  th:last-child::before {
  content: "";
  width: 100%;
  height: 22px;
  background-color: #737dff;
  position: absolute;
}

.operate-dashboard-filter-container .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0 !important;
  vertical-align: middle !important;
}
.operate-dashboard-filter-container .ant-table-header table thead tr th {
  padding: 0 !important;
  vertical-align: top !important;
}
.custom-check {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.ant-radio-checked::after {
  box-shadow: none !important;
}
.ant-radio-inner::after {
  background-color: $color-primary !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-primary !important;
}
.ant-radio-wrapper:hover .ant-radio {
  border-color: transparent !important;
}
.custom-check .ant-radio-wrapper .ant-radio {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  background-color: transparent;
}

.custom-check .ant-radio-wrapper .ant-radio-checked::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color-primary;
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  border: transparent;
}
.ant-radio-inner::after {
  background-color: $color-primary !important;
}
.custom-check .ant-radio-wrapper .ant-radio + span {
  padding-left: 5px;
  color: $color-primary;
  font-weight: $font-weight-sm;
  font-size: $msg-font-size;
  font-family: "Roboto";
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}
.custom-check-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  height: 22px;
}

.custom-name {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
}

.operate-dashboard-filter-container
  > .ant-table-wrapper
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-body {
  position: relative;
  width: inherit;
  height: 150px;
  max-height: 150px;
}
.performance-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 35px;
  > div{
    width: 100%;
  }
  .element-data .field {
    color: $color-gray;
    font-size: $roboto-12-medium-font-size;
    font-family: "Roboto";
    font-weight: $font-weight-sm;
  }
  .element-data .value {
    color: $color-text-black;
    font-size: $roboto-12-medium-font-size;
    font-family: "Roboto";
    font-weight: $font-weight-bold;
  }
}
.bonos-performance-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
  gap: 30px;
  .datos-operacion-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.5rem;
    .element-data .field {
      color: $color-gray;
      font-size: $roboto-12-medium-font-size;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
    .element-data .value {
      color: $color-text-black;
      font-size: $roboto-12-medium-font-size;
      font-family: "Roboto";
      font-weight: $font-weight-bold;
    }
  }
  .datos-emision-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    .element-data .field {
      color: $color-gray;
      font-size: $roboto-12-medium-font-size;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
    .element-data .value {
      color: $color-text-black;
      font-size: $roboto-12-medium-font-size;
      font-family: "Roboto";
      font-weight: $font-weight-bold;
    }
  }
}