@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.input-text-user-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: start;
  width: 100%;
  text-align: center;
  label {
    text-align: start;
    width: 100%;
    font-size: $body-sm-font-size;
    margin: 0px 0px 5px;
    color: $color-label;
    font-weight: $font-weight-sm
  }
  .input-text-user-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: $color-label;
    border-radius: 6px;
    width: 100%;
    padding: 5px 15px 5px 15px;
    border: 1px solid $color-label;
    margin-top: 1px;
    border-radius: 100px;
    &.input {
      border: 1px solid $color-label;
    }
    input {
      width: 100%;
      font-size: $body-sm-font-size;
      border-style: none;
      outline: none;
      background: transparent;
    }
  }
  &::placeholder {
    font-family: 'Roboto';
    font-size: $h5-font-size;
    background-color: transparent;
    color: $color-text-black;;
  }
}
.input-text-user-container-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  width: 30%;
  height: 100%;
  .input-text-user-profile-edit-icon {
    cursor: pointer;
  }
}
