// Importando tus variables de colores y fuentes
@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.option-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $color-primary;
}

.control {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.control_indicator {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid $color-gray;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  border-color: $color-primary;
}

.control input:checked ~ .control_indicator {
  border-color: $color-primary !important;
  background-color: transparent !important;
}
.control_indicator:after {
  content: '' !important;
  position: absolute !important;
  display: none !important;
}
.control input:checked ~ .control_indicator:after {
  display: block !important;
  left: 4px !important;
  top: 4px !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background-color: $color-primary !important;
}
.option-text {
  font-weight: $font-weight-sm;
  font-size: $msg-font-size;
  color: $color-primary;
  font-family: 'Roboto';
}
