@import '../../../../index.scss';
@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.instruments-tab {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem 1rem;
  background-color: $color-background;
  .base-modal {
    background-color: #4d4d4d64;
  }

  .base-modal-content {
    position: relative;
    width: 50%;
    min-width: none;
    height: auto;
    padding: 0;
    background-color: #ffffff;
  }
  .instruments-left-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    .container-options-radio {
      position: relative;
      width: fit-content;
      height: auto;
      padding: 0.25rem 0.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem 1rem;
      border-radius: 5px;
      border: none;
      background-color: transparent;
      font-weight: $font-weight-sm;
      font-size: $msg-font-size;
      color: $color-primary;
      font-family: 'Roboto';
      margin: 0;
      padding: 0;
    }
    .container-option-radio-desabled {
      position: relative;
      width: fit-content;
      height: auto;
      padding: 0.25rem 0.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      background-color: transparent;
      font-weight: $font-weight-sm;
      font-size: $msg-font-size;
      color: $color-primary;
      font-family: 'Roboto';
      margin: 0;
      padding: 0;
      .img-radio-term-operate.checked {
        background: transparent;
      }
    }
  }
}

/*-start-------- TICKER DETAIL CARD ----------*/

.instrument-rigth-container {
  position: relative;
  width: 40%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
  z-index: 1;
}

.market-time {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px 25px 5px 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #52b5b11a;
  color: $color-success;
  border-radius: 100px;
  border: 1px solid $color-success;
  font-family: 'Roboto';
}

.market-time > span {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  color: $color-success;
  text-align: center;
}
.market-time-text {
  font-family: 'Roboto';
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.ticker-detail-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 2.8rem 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  box-shadow: 0px 2px 8px 0px #00000026;
  background-color: $white;
  border-radius: 20px;
  gap: 10px;
  margin-top: 18px;
  overflow-y: scroll;
  .ant-tabs {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
  }

  .ant-tabs-top {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
  }

  .ant-tabs-content {
    width: auto;
    margin: auto;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
}
.ant-tabs-tab {
  padding: 5px 15px 5px 15px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
  text-shadow: none !important;
  padding: 5px 15px 5px 15px !important;
  border-radius: 100px !important;
  transition: all 0.3s ease-in-out !important;
}
/* Hide the tab operations button */
.ant-tabs-nav-operations {
  display: none !important;
}

/* Hide the ink bar */
.ant-tabs-ink-bar {
  display: none !important;
}

.ant-tabs-tab:hover {
  color: $color-primary !important;
}
.ticker-detail-container
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  gap: 40px !important;
}
.ant-tabs-tab {
  border-bottom: 2px solid transparent !important;
  transition: border-color 0.3s ease-in-out !important;
}
.ant-tabs-nav {
  &::before {
    border-bottom: transparent !important;
  }
}
.ant-tabs-nav-wrap {
  &::before {
    display: none !important;
  }
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: none;
}
/* Override the position and animation of the ink bar */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: auto !important; /* Reset any bottom positioning */
  background-color: transparent !important; /* Make it transparent */
  height: 0 !important; /* Set the height to 0 to effectively hide it */
  pointer-events: none !important; /* Ensure it doesn't interfere with clicks */
}

/* Cancel out the animation */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: none !important; /* Remove the transition */
}

.ant-tabs-top > .ant-tabs-nav::before {
  display: none !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  display: none !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 auto !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: transparent !important;
  transition: border-color 0.3s ease-in-out !important;
}
.ant-tabs-ink-bar {
  background-color: transparent !important;
  transition: border-color 0.3s ease-in-out !important;
}
.ticker-detail-container
  > .ant-tabs-content
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-content-holder {
  position: relative;
  width: auto;
  height: auto;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.ant-tabs-content-holder {
  width: 100%;
}

.tabs-alignment-center {
  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }

  .ant-tabs-tab-btn {
    font-size: 18px;
    color: #acacac;
  }
}

.tabs-alignment-left {
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #3a3a3a;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-tab-btn {
    font-size: 18px;
    color: #acacac;
  }
}

.ticker-detail-body {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 15px;
}

.ticker-detail-type-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  color: #737dff;
}

.ticker-detail-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.325rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  font-family: 'Roboto';
  font-size: $h5-font-size;
  font-weight: $font-weight-medium;
  color: $color-text-black;
}

.ticker-detail-title-text {
  font-size: 20px;
}

/* Experimental Control over Ticket Chart*/
.ticker-detail-chart {
  position: relative;
  width: 90%;
  height: auto;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.ticker-detail-chart > canvas {
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0;
}

/* ---CARD DETAIL LIST INFO--- */

.ticker-detail-list {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  .ticker-detail-list-small-title {
    font-size: $msg-font-size;
    font-weight: $font-weight-sm;
    color: $color-text-black;
    font-family: 'Roboto';
  }
}

.ticker-detail-ul-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}

.ticker-detail-ul {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  color: #263238;
  overflow-y: scroll;
}

.ticker-detail-ul > li {
  width: 100%;
}

.ticker-detail-ul > .ticker-detail-list-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  font-family: 'Roboto';
  font-size: $x-medium-font-size;
  font-weight: $font-weight-medium;
  color: $color-text-black;
}

.ticker-prices-container {
  display: flex;
  justify-content: space-around;
  .ticker-detail-price {
    padding: 5px 25px 5px 25px;
    width: 50%;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: 'Roboto';
    margin: 5px;
    border-radius: 100px;
    text-align: center;
    &.ticker-buy-price {
      background-color: #52b5b11a;
      border: 1px solid $color-success;
      color: $color-success;
    }
    &.ticker-sell-price {
      background-color: #f5222d1a;
      border: 1px solid $color-error;
      color: $color-error;
    }
  }
}

.ticker-detail-list-item {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  &:first-child {
    border-top: 1px solid #0000000f;
    border-bottom: 1px solid #0000000f;
  }
  &:not(:first-child) {
    border-bottom: 1px solid #0000000f;
  }
}

.ticker-detail-list-item-p {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 1rem 0.5rem;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  color: $color-gray;
  font-family: 'Roboto';
}

.ticker-detail-list-item-p-value {
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-bold;
  font-family: 'Roboto';
  color: $color-text-black;
}

.ticker-detail-list-item-value {
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-bold;
  font-family: 'Roboto';
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  color: $color-text-black;
}

.ticker-detail-button-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  border: 0;
  border-radius: 0;
  button {
    position: relative;
    height: auto;
    padding: 5px 15px 5px 15px;
    margin: 0;
    background-color: $color-background;
    color: $color-primary;
    font-family: 'Roboto';
    font-size: $msg-font-size;
    font-weight: $font-weight-sm;
    border: 0;
    border-radius: 20px;
    gap: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: $color-background;
      color: $color-primary;
    }
  }
}
.ticker-detail-container > .ant-tabs > .ant-tabs-nav {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 0 0.5rem 0;
  color: $color-primary;
  font-family: 'Roboto';
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-semi-bold;
}

.ticker-detail-container
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 0;
}

.ticker-detail-container
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  padding: 0;
}

.ticker-detail-container > .ant-tabs > .ant-tabs-tab {
  padding: 0 !important;
}

.ticker-detail-container > .ant-tabs > .ant-tabs-tab-active {
  color: #737dff;
  padding: 0 !important;
}

.ticker-detail-sublist-item-title {
  position: relative;
  width: auto;
  height: auto;
  padding: 0%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.ticker-detail-sublist-item-p-title {
  margin: 0px;
  padding: 2px 0px 2px 0px;
  font-weight: bolder;
}

.ticker-detail-sublist-item {
  border-top-color: #969696;
  border-top-style: solid;
  border-top-width: 1px;
}

.ticker-detail-no-record-message {
  position: relative;
  width: auto;
  height: auto;
  padding: 0%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.ticker-detail-fact-sheet {
  padding: 0.5rem 0;
}

.fact-sheet-link {
  font-size: 0.8rem;
  font-weight: bold;
  color: #0056ff;
  background-color: #aec9f5;
  border: 1px solid #0056ff;
  border-radius: 0.5rem;
  padding: 0.375rem 0.5rem;
}

.fact-sheet-link:hover {
  color: #ffffff;
  background-color: #0056ff;
}

/*-end-------- TICKER DETAIL CARD ----------*/

/*-start------ INSTRUMENTS TABLE ------*/

.container-instrument-table {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: $white;
  padding: 20px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px #00000026;
  .ant-table-container {
    .ant-table-header {
      border-radius: 20px 20px 0px 0px;
      background-color: $color-primary;
      padding: 12px 16px 12px 16px;
      table {
        table-layout: auto !important;
        .ant-table-thead {
          th {
            background-color: transparent;
            border-bottom: none;
            color: $color-background;
            font-size: $roboto-12-medium-font-size;
            font-weight: $font-weight-sm;
            text-transform: uppercase;
            text-align: center;
            font-family: 'Roboto';
            white-space: nowrap;
          }
          tr {
            .ant-table-cell-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-primary;
    border-color: $color-primary;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inne {
    border-color: $color-primary;
  }
  .ant-table-tbody {
    text-align: center;
    height: 38vh !important;
  }

  .row-selected:hover {
    background-color: inherit;
  }
}

/*control the table-main-container height*/

.container-instrument-table > .ant-table-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}

.container-instrument-table > .ant-table-tbody {
  height: 100%;
}
.ant-table-tbody {
  height: 100%;
  tr {
    border-width: 0px 1px 0px 1px;
    border-style: solid;
    border-color: $color-gray;
    td {
      font-weight: $font-weight-medium;
      font-size: $roboto-12-medium-font-size;
      color: $color-text-black;
      font-family: 'Roboto';
    }
  }
}
.container-instrument-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  height: 50%;
}

.container-instrument-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container {
  height: 100%;
}

.container-instrument-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-body {
  height: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: #f0f0f0;
}

.ticker-title-selected {
  display: flex;
  background-color: red;
  height: 100%;
}

/* Header filter */

.instrument-table-header-selections {
  position: relative;
  width: auto;
  height: auto;
  padding: 1.5rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  .instrument-table-header-title {
    font-family: 'Roboto';
    font-weight: $font-weight-sm;
    font-size: $msg-font-size;
    color: $color-secondary;
  }
  .input-text-clients-wallet-container {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 15px 4px 15px;
    background-color: transparent;
    color: $color-gray;
    border: 1px solid $color-gray;
    border-radius: 62px;
    input {
      padding: 0;
      margin: 0;
      &::placeholder {
        padding: 0;
        margin: 0;
      }
    }
    .img-input-text {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      vertical-align: middle;
    }
  }
  .intrument-table-header-select {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    background-color: $color-background;
    cursor: pointer;
    .select-container-clients-wallet {
      position: relative;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      color: $color-primary;
      border: 0;
      font-weight: $font-weight-sm;
      font-size: $msg-font-size;
      background: transparent;
      white-space: nowrap;
      cursor: pointer;
      font-family: 'Roboto';
      span {
        position: relative;
        width: auto;
        height: auto;
        padding: 0;
        display: block;
        font-size: 0.8rem;
        font-weight: $font-weight-medium;
      }
    }
  }
}

.acciones-active {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.325rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  font-family: 'Roboto';
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-bold;
  cursor: pointer;
  color: $color-primary;
  background-color: $color-background;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  line-height: 1rem;
}

.acciones-active:hover {
  color: $white;
  background-color: $color-secondary;
  border-color: $color-secondary;
}

/*-end------ INSTRUMENTS TABLE ------*/

/*-start------ COLORED PIE CHART ITEMS SCUARE -------*/

.colored-type-scuare {
  width: 15px;
  height: 15px;
  border: 0;
  border-radius: 100px;
}

/*-end------ COLORED PIE CHART ITEMS SCUARE -------*/

/*-start-------- COMPARE MODAL ----------*/

.fund-compare-modal {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}

.compare-modal-body {
  position: relative;
  width: auto;
  height: auto;
  padding: 1rem;
}

.compare-chart-export-button {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #2d2e8f;
  color: #ffffff;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: normal;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;

}

.compare-chart-export-button:hover {
  background-color: #737dff;
}

.compare-chart-export-button > .export-icon {
  position: relative;
  width: 1rem;
  height: 1rem;
}
.compare-chart-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
  .compare-chart-left {
    position: relative;
    width: 40%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem 2rem;
    .compared-ticker-item-card {
      width: 100%;
      height: 15px;
      padding: 25px 16px 25px 16px;
      border: 1px solid $color-background;
      border-radius: 20px;
      background-color: #0056ff10;
      box-shadow: 0px 2px 8px 0px #00000026;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .item-card-bottom-section {
        width: 100%;
        color: $color-text-black;
        font-weight: $font-weight-sm;
        font-size: $msg-font-size;
        font-family: 'Roboto';
        white-space: nowrap;
      }
      .ticker-price-percentage-up {
        color: $color-accent;
        font-size: $h5-font-size;
        font-weight: $font-weight-bold;
        font-family: 'Roboto';
      }
    }

    .compare-chart-rigth {
      width: 60%;
      height: 100%;
      margin: 0;
      padding: 0.5rem;
      border: 1px solid #c4c4c4;
      border-radius: 0.25rem;
      background-color: #c4c4c420;
      box-shadow: 0px 2px 6px #00000029;
      canvas {
        padding: 0;
      }
    }
  }
}

.item-card-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticker-price-percentage-down {
  color: #e80451;
  font-size: 1.25rem;
  font-weight: 500;
}

/*-end---------- COMPARE MODAL ----------*/

/*-------------- Fund Table Animation --------------*/

.scale-up-hor-left {
  animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-hor-left {
  0% {
    transform: scaleX(0.7);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
  }
}

.scale-down-hor-left {
  animation: scale-down-hor-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scale-down-hor-left {
  0% {
    transform: scaleX(1.3);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
  }
}

/*-------------- Fund Table Animation --------------*/

/*correction for vertical-line-separator inside table-header*/
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
  display: none;
}

.ticker-detail-list-data-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}
