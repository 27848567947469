.operate-table-header-container {
  color: #ffffff;
  font-size: 10px;
  background-color: #737dff;
  height: 22px;
  line-height: 20px;
  text-align: center;
}
.operate-table-header-container-first {
  color: #ffffff;
  font-size: 10px;
  background-color: #737dff;
  height: 22px;
  line-height: 20px;
  text-align: left;
}

.operate-table-header-container-name {
  text-align: left;
  padding: 0% 0% 0% 0.8%;
  width: 23vw;
}

.operate-table-header-container-quantity {
  width: 122px;
  margin: 0% 7px 0% 0%;
  text-align: center;
}

.operate-table-header-container-price {
  width: 122px;
  margin: 0% 7px 0% 0%;
  text-align: center;
}

.operate-table-header-container-limit {
  width: 122px;
  margin: 0% 7px 0% 0%;
  text-align: center;
}
