@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.portfolio-card {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  padding: 20px;
  background-color: $white;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px #00000026;
  .portfolio-card-header {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .portfolio-header-title {
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      .subtitle {
        padding: 0;
        font-family: 'Roboto';
        font-weight: $font-weight-medium;
        font-size: $h3-font-size;
        color: $color-text-black;
      }
      img {
        position: relative;
        margin: auto;
        padding: 0;
        width: 35px;
      }
    }
  }
}

.portfolio-header-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.label-select {
  height: 19px;
  width: 120px;
  color: #423994;
  font-size: 14px;
}

.portfolio-card {
  .portfolio-content {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    .portfolio-content-tenencia {
      position: relative;
      width: 40%;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }
    .statics {
      position: relative;
      padding: 20px 20px 20px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-background;
      gap: 60px;
      border-radius: 20px;
      .doughnut-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        canvas {
          position: relative;
          width: 70% !important;
          height: auto !important;
          margin: auto !important;
          padding: 0 !important;
        }
        .checks {
          position: relative;
          width: 50%;
          height: auto;
          padding: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          gap: 1rem 0;
          .checks-title {
            position: relative;
            width: auto;
            height: auto;
            padding: 0;
            margin: 0 !important;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
            justify-self: flex-start;
            gap: 0;
            img {
              position: relative;
              width: 0.875rem;
              height: auto;
              padding: 0;
              border-radius: 0.125rem;
              vertical-align: middle;
            }
            .check-reference-text {
              position: relative;
              width: auto;
              height: auto;
              padding: 0;
              color: #4d4d4d;
              font-family: 'Roboto', Arial, Helvetica, sans-serif;
              font-size: 1rem;
              font-weight: normal;
              height: 50%;
            }
          }
        }
      }
    }
    .portfolio-content-tenencia {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 0;
      color: #4d4d4d;
      .title-tooltip-wrapper {
        .title-tooltip {
          color: $color-secondary;
          font-size: $msg-font-size;
          font-weight: $font-weight-bold;
        }
      }
      .portfolio-content-tenencia-value-coins {
        position: relative;
        width: auto;
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }
    }
  }
}

.content-porcentual {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 600;
}

.portfolio-results {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
  margin-top: 15px;
  .card--footer--portfolio {
    position: relative;
    width: 100%;
    border-radius: 20px;
    border: 1px solid $color-light-gray;
    box-shadow: 0px 2px 8px 0px #00000026;
    background: $white;
    .card-profile-description {
      padding: 15px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    &.portfolio-fee-section {
      .portfolio-fee-info-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .card-profile {
      .card-profile-description {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: $msg-font-size;
        font-weight: $font-weight-medium;
        text-align: start;
      }
    }
  }
}

.portfolio-results {
  .card--footer--portfolio {
    .card-profile {
      .card-profile-amount {
        position: relative;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;
        margin: 5px;
        .profile-amount-values {
          position: relative;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between !important;
          align-items: center;
          font-size: $footnote-description-font-size;
          font-weight: $font-weight-medium;
          color: $color-text-black;
        }
        .equivalency-container {
          position: relative;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between !important;
          align-items: center;
        }
        .equivalency-container-alternative {
          position: relative;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
        }
        .card-profile-value-equivalency {
          position: relative;
          width: auto;
          height: auto;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          gap: 0;
        }
        .card-profile-usd-equivalency {
          position: relative;
          width: auto;
          height: auto;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}

.portfolio-fee-info-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  gap: 10px;
  margin: 3px;
  .portfolio-fee-total-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: $footnote-description-font-size;
    color: $color-text-black;
    font-weight: $font-weight-medium;
  }
  .portfolio-fee-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-size: $footnote-description-font-size;
    color: $color-primary;
    font-weight: $font-weight-medium;
    width: 100%;
    height: auto;
    position: relative;
    margin: 5px 0px 5px 0px;
    .portfolio-fee-link-text {
      font-size: $footnote-description-font-size;
      color: $color-primary;
      font-weight: $font-weight-medium;
      text-decoration: none;
      margin: 0;
      padding: 0;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.title-tooltip-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.tooltip-wrapper {
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  p {
    img {
      position: relative;
      width: 14px;
      height: auto;
      padding: 0;
      margin: 0;
      vertical-align: middle;
      top: -1px;
    }
  }
}
