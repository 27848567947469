@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.selectProfileCoin-wrapper {
  position: relative;
  width: 50px;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  border-radius: 0.375rem;
  background-color: transparent;
  .selectProfileCoin-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 4px 0px 4px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 0;
    color: $color-secondary;
    font-family: 'Roboto';
    cursor: pointer;
    outline: none;
    background-color: $white;
    .element-wrapper{
      font-weight: $font-weight-semi-bold;
      font-size: $body-sm-font-size;
      font-family: "Roboto";
      color: $color-secondary;
    }
  }
  .selectProfileCoin-options {
    position: absolute;
    width: 5vw;
    height: auto;
    padding: 0.25rem;
    display: none;
    top: 1.75rem;
    left: 0;
    color: $color-secondary;
    border-radius: 5px;
    background-color: $white;
    border-radius: 2px 2px 20px 20px;
    box-shadow: 0px 9px 28px 8px #0000000d;
    box-shadow: 0px 6px 16px 0px #00000014;
    box-shadow: 0px 3px 6px -4px #0000001f;
    transition: all 0.3s;
    gap: 8px;
    &.open-selectProfileCoin {
      display: block;
      color: $color-secondary;
      background-color: $white;
      
    }
    .option-selectProfileCoin {
      position: relative;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: $color-secondary;
      font-family: 'Roboto';
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-semi-bold;
      outline: none;
      padding: 5px 10px;
      &:hover {
        color: $color-secondary;
      }
      &.selected {
        background-color: $color-background;
      }
    }
  }
}
