$bg-sidebar: #01043b;

.main-container {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  gap: 0;
  height: 100%;
  width: 100%;

  /* display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: 100%;
  grid-template-areas: "sidebar dashboard"; */
  
}
.second-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 0;
  margin: 0;
  padding: 0;
}
.dashboard {
  grid-area: dashboard;
}

/* ************************************ */
.blur-view-container {
  position: absolute;
  left: 0;
  top: 0;

  opacity: 0.5;
  display: none;
  z-index: 300;
  transition: all 1.5s;
}

.blur-view-container.openProtectorBlur {
  display: block;
  position: fixed;
  background-color: rgba(0, 17, 255, 0.5);
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.first-container {
  background: $bg-sidebar;
}