.operate-table-edit-group-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}

.operate-table-edit-group-wrapper {
  display: flex;
  flex-direction: row;
  background: #e8ebfb;
  padding: 10px 5px;
  margin: auto;
}

.operate-table-edit-group-text {
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 12px;
  color: #2d2e8f;
}

.operate-table-edit-group-input-list-wrapper {
  display: flex;
  flex-direction: row;
}

.operate-table-edit-group-price-container {
  position: relative;
  padding: 2px;
}

.operate-table-custom-input-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 118px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #8e8f98;
  text-align: end;
  font-family: "Roboto" !important;
  font-weight: 600;
}

.operate-table-custom-input-nothing {
  width: 124px;
  height: 25px;
}
