:root {
  --modal-backdrop: rgba(77, 77, 77, 0.25);
  --title: #040560;
}

.base-modal {
  position: fixed; 
  z-index: 1; 
  padding-top: 5%; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: var(--modal-backdrop); 
}

.base-modal-content {
  background-color: white;
  margin: auto;
  padding: 30px;
  width: 40%;
  min-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 19%);
}

/* .modal-close-container {
  display: flex;
  justify-content: flex-end;
} */

.modal-close-icon {
  width: 17px;
  height: 17px;
  cursor: pointer;
  opacity: .8;
}

.modal-close-icon:hover {
  opacity: 1;
}

.modal-title {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #263238;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: 600;
  /* font-family: "Segoe UI Semibold";
  font-size: 26px;
  color: var(--title);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px; */
}

/*refactoring for modal base*/
/* .base-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  padding: 0;
  overflow: auto;
  z-index: 1;
  background-color: var(--modal-backdrop);
}

.base-modal-content {
  position: relative;
  width: 40%;
  min-width: 500px;
  height: auto;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 20%);
}

.base-modal-top {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  background-color: aquamarine;
}

.base-modal-close-button-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  background-color: blueviolet;
}

.modal-title {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  background-color: blue;
  color: white;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: 500;
}

.modal-close-icon {
  width: 24px;
  height: auto;
  vertical-align: middle;
  background-color: antiquewhite;
  cursor: pointer;
} */

/* .modal-close-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  border-bottom: 1px solid #C4C4C4;
} */