/*CEDEARs instrument table settings only*/
.operate-table-view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60vh;
  padding: 0.5% 1% 0% 1%;
  align-items: center;
  background: white;
  overflow-x: hidden;
}

@media (max-width: 1366px) {
  .operate-table-view {
    height: 25vh;
  }
}

.operate-table-view.disabled-table-view {
  pointer-events: none;
}

.operate-table-view::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background: transparent;
}

.operate-table-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  box-shadow: inset 0 0 3px #687ec7;
}

.operate-table-view:hover::-webkit-scrollbar {
  background: transparent;
}

.operate-table-empty-list-message-container {
  margin: auto;
}

.operate-table-empty-list-message-wrapper {
  position: relative;
  text-align: center;
}

.operate-table-empty-list-message-image-container {
  margin-bottom: 1.5%;
}

.operate-table-empty-list-message-text-title {
  font-family: "Roboto" !important;
  font-size: 16px;
  color: #67698b;
  margin-bottom: 1.5%;
}

.operate-table-empty-list-message-text-description {
  width: 360px;
  font-family: "Roboto" !important;
  font-size: 12px;
  color: #67698b;
  margin-bottom: 1.5%;
}

.data-element-operate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border-radius: 5px;
  overflow: visible;
}

.table-row-grey {
  background-color: #ededed !important;
  pointer-events: none;
}
.table-row-grey label {
  pointer-events: all;
}

.data-element-operate-icon-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  margin: 0% 1.5% 0% 1%;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.data-element-operate-margin-check {
  margin: 0% 1.5%;
  width: 20px;
}

.data-element-operate .icon-check.checked {
  background: #737dff;
}

.data-element-operate-name {
  font-family: "Roboto Bold";
  font-size: 12px;
  color: #2d2e8f;
}

.data-element-operate-mount {
  font-family: "Roboto" !important;
  font-weight: normal;
  font-size: 12px;
  color: #afb2c6;
}

.data-element-operate-quantity-price {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #67698b;
  font-size: 14px;
  font-family: "Roboto" !important;
  font-weight: 700;
  width: 130px;
  border-style: none;
}

.data-element-operate-fake-input {
  width: 118px;
  height: 25px;
}

.data-element-operate-quantity-limit {
  color: #67698b;
  font-size: 14px;
  font-family: "Roboto" !important;
  font-weight: 700;
  width: 100px;
  text-align: center;
  border-bottom-color: #a4a7be;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

// .data-element-operate-icon {
//   width: 16px;
//   height: 16px;
// }

.data-element-operate-icon-edit {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.data-element-operate-circle {
  position: relative;
  display: flex;
  width: 75%;
  height: 75%;
  border-radius: inherit;
  align-self: center;
}

.data-element-operate-icon-swap {
  position: relative;
  display: flex;
  width: 5%;
  height: 47%;
  margin: 3.6% 0px 0px 5px;
  box-shadow: 0px 1px 6px #0055ff29;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  place-content: center;
}

.operate-table-row-checked {
  display: flex;
  align-items: center;
  height: 57px;
  width: min-content;

  .data-element-operate-custom-input-general-container {
    margin: 0 3px;
  }
}

.data-element-operate-fixed-amount {
  display: flex;
  justify-content: center;
  width: 124px;
  height: 29px;
  align-items: center;
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 11px;
  color: #afb2c6;
}

.operate-table-toggle-container {
  position: relative;
  text-align: center;
  margin: 0px 30px;
  padding: 3.5% 0% 0%;
}

.toggle-button-container {
  border-radius: 20px;
  background: #4a1f94;
  border: 1px solid #4a1f94;
  outline: none;
  width: 66px;
  height: 18px;
  font-family: "Roboto" !important;
  font-size: 10px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.toggle-button-container.light-button {
  background: #4a9d97;
  border: 1px solid #4a9d97;
}
