@import "../../../../scss/colors.scss";

.operate-confirm-procedure-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  border: 2px solid $mid-blue;
  height: 562px;

  .operate-confirm-procedure-important-screen-wrapper {
    position: relative;
    width: 97.6%;
    height: 100%;
    padding: 0% 1.2% 0% 1.2%;

    .operate-confirm-procedure-container-title {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 1.5% 0% 1.5% 0%;
      font-family: "Roboto" !important;
      font-size: 15px;
      color: $color-primary;

      .operate-confirm-procedure-title {
        margin: 0% 0% 0% 1.5%;
      }
      img{
        cursor: pointer;
      }
    }

    .operate-confirm-procedure-container-data {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: auto;

      .operate-confirm-procedure-loading {
        position: relative;
        top: 155px;
      }

      .operate-confirm-procedure-table-container {
        position: relative;
        width: 100%;
        height: 100%;

        .operate-confirm-procedure-container-data-header {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          height: 65px;
          width: 100%;
          border-radius: 8px;
          background: $mid-blue;
        }

        .operate-confirm-procedure-container-data-comitentes-header {
          display: grid;
          grid-template-columns: 32% 15% 15% 15% 15%;
          width: 100%;
          background: #f6f6fa;
          padding: 3px 0px;
          margin: 2.2% 0% 1% 0%;
          font-family: "Roboto" !important;
          font-size: 12px;
          color: #a9a9bd;
          border-radius: 20px;

          div {
            text-align: center;
          }
        }

        .data-founds-header {
          grid-template-columns: 32% 30% 30%;
        }

        .operate-confirm-procedure-container-footer-data {
          display: flex;
          flex-direction: row;
          line-height: 30px;
          font-family: "Roboto" !important;
          font-size: 12px;
          font-weight: bold;
          color: $mid-blue;
          text-align: center;
          padding: 0 0 1% 0;

          .operate-row-specie {
            position: relative;
            width: 32%;
          }

          .operate-row-data {
            position: relative;
            width: 15%;
          }

          .operate-row-founds {
            position: relative;
            width: 30%;
          }
        }

        .operate-confirm-procedure-container-data-comitentes-data {
          height: 225px;
          overflow: auto;

          .operate-confirm-procedure-container-data-comitentes-data-element {
            display: flex;
            flex-direction: row;
            border-bottom-color: rgba(199, 185, 185, 0.5);
            border-bottom-style: solid;
            border-bottom-width: 0.5px;
            font-family: "Roboto" !important;
            font-size: 12px;
            font-weight: bold;
            color: $mid-blue;
            text-align: center;
            height: 60px;
            align-items: center;

            .operate-confirm-procedure-comitente-info {
              position: relative;
              display: flex;
              flex-direction: column;
              width: 32%;

              .operate-confirm-procedure-comitente-name {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
              }

              .operate-confirm-procedure-comitente-extra {
                position: relative;
                display: flex;
                flex-direction: row;
                align-self: center;
                align-items: center;
                height: 15px;

                .operate-confirm-procedure-comitente-count {
                  position: relative;
                  display: flex;
                  font-family: "Roboto" !important;
                  font-weight: 600;
                  font-size: 9px;
                  margin: 0 15px 0 0;
                  padding: 10px 0px 0px;
                }

                .operate-confirm-procedure-comitente-value {
                  position: relative;
                  display: flex;
                  font-family: "Roboto" !important;
                  font-weight: 600;
                  font-size: 9px;
                  padding: 10px 0px 0px;
                }
              }
            }

            .operate-row-founds {
              position: relative;
              width: 30%;
            }

            .operate-row-data {
              position: relative;
              width: 15%;
            }

            .operate-confirm-procedure-container-data-comitentes-data-element-actions {
              position: relative;
              display: flex;
              width: 8%;
              justify-content: flex-end;

              .operate-confirm-procedure-container-data-comitentes-trash {
                position: relative;
                width: 15px;
                height: 15px;
                cursor: pointer;
                right: 25%;
              }
            }
          }

          .operate-confirm-procedure-data-error {
            background: #ffe4ed;

            .operate-confirm-procedure-comitente-info {
              position: relative;
              display: flex;
              flex-direction: column;
              width: 32%;

              .operate-confirm-procedure-comitente-name {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
              }

              .operate-confirm-procedure-comitente-extra {
                position: relative;
                display: flex;
                flex-direction: row;
                align-self: center;
                align-items: center;
                height: 15px;

                .operate-confirm-procedure-comitente-count {
                  position: relative;
                  display: flex;
                  font-family: "Roboto" !important;
                  font-weight: 600;
                  font-size: 9px;
                  margin: 0 15px 0 0;
                }

                .operate-confirm-procedure-comitente-value {
                  position: relative;
                  display: flex;
                  font-family: "Roboto" !important;
                  font-weight: 600;
                  font-size: 9px;
                }
              }
            }

            .operate-confirm-procedure-container-data-comitentes-data-element-actions {
              position: relative;
              display: flex;
              width: 8%;
              justify-content: flex-end;

              .operate-confirm-procedure-container-data-comitentes-trash {
                position: relative;
                width: 15px;
                height: 15px;
                cursor: pointer;
                right: 25%;
              }
            }
          }
        }
      }
    }
  }

  .operate-confirm-procedure {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 65px;
    bottom: -1px;
    align-items: center;
    border-radius: 0 0 9px 9px;
    background: $mid-blue;
    border: 1px solid $mid-blue;
    left: -1px;

    .operate-confirm-alert-message-container {
      position: absolute;
      left: 1%;
      height: 65%;
      width: fit-content;
      background: #01043b;
      border-radius: 10px;

      .operate-confirm-alert-message-wrapper {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0px 20px 0px 20px;

        .operate-confirm-alert-message-icon-container {
          margin: 0 20px 0 0;

          .operate-confirm-alert-message-icon {
            position: relative;
            width: 24px;
            height: 24px;
          }
        }

        .operate-confirm-alert-message-text-container {
          position: relative;
          height: 100%;
          width: 240px;

          .operate-confirm-alert-message-text {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            font-family: "Roboto" !important;
            font-weight: normal;
            font-size: 12px;
            color: white;
            align-items: center;
          }
        }
      }
    }

    .operate-confirm-procedure-cancel {
      font-family: "Roboto" !important;
      font-size: 13px;
      color: #f4f6fd;
      text-decoration: underline;
      cursor: pointer;
    }

    .operate-confirm-procedure-finish-ok {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 220px;
      height: 37px;
      border-radius: 8px;
      opacity: 0.4;
      color: #678cff;
      font-size: 13px;
      font-family: "Roboto" !important;
      font-weight: bold;
      background: #ffffff 0% 0% no-repeat padding-box;
      cursor: default;
      margin: 0% 3% 0% 2%;
    }

    .finish-ok-active {
      opacity: 1;
      color: #423994;
      cursor: pointer;
    }
  }
}
