@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.filters {

  .table-tickets .ant-table-thead > tr > th{
    height: 85px !important;
  }
  .ant-tabs-nav {
    position: inherit !important;
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    margin-bottom: 0 !important;
    background: $white;
  }
  .ant-tabs-tab + .ant-tabs-tab{
    margin: 0 !important;
  }
  .ant-tabs-tab {
    padding: 0;
  }
  .ant-tabs-tab .ant-tabs-tab-btn{
    text-transform: uppercase;
  }
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #a4a7be !important;

    .ant-select-selection-item {
      font-size: 13px;
      line-height: 26px;
    }
  }

  .ant-tabs-tab-btn {
    font-size: $msg-font-size;
    font-weight: $font-weight-sm;
    color: $color-text-black;
    font-family: "Roboto";
  }

  .ant-picker {
    border-radius: 20px  !important;
    border: none !important;
    background-color: $color-background !important;
    font-size: $msg-font-size !important; 
    font-weight: $font-weight-sm !important;
    font-family: "Roboto" !important;
    color: $color-primary !important;
    vertical-align: middle !important;
    text-align: center !important;
    gap: 10px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: $color-background !important;
  }
  .ant-select{
    color: $color-primary !important;
  }
  /* Aumentando la especificidad */


}

.ant-select-item {
  .ant-select-item-option-content {
    font-size: 13px !important;
  }
}
.table-tickets .ant-table-thead > tr > th {
  font-family: "Roboto";
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  padding: 8px !important;
  border-radius: 0px !important;
  background-color: $color-primary;
  color: $color-background;
  text-align: center;
  vertical-align: middle;
}

.table-tickets {
  .ant-table-tbody > tr > td {
    font-family: "Roboto";
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm; 
    padding: 8px !important;
    text-align: center;
    color: $color-text-black; 
    border-bottom: 1px solid $color-background;
  }
  
  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid $color-background; 
  }
  
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid $color-background;
  }
  
}
