.overview-container {
    width: 400px;
    margin: 0 20px;
}

.overview-title {
    margin: 15px 5px;
    padding: 7px 30px;
    color: #A4A7BE;
    font-size: 18px;
    border-bottom: 1px solid #a4a7be66;
}

.row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;
}

.overview-data-color {
    height: 13px;
    width: 13px;
    background-color: #A4A7BE;
}

.overview-data-color.total {
    background-color: transparent;
}

.overview-data-key {
    font-family: "Roboto" !important;
    font-weight: normal;
    color: #A4A7BE;
    font-size: 15px;
    align-items: center;
    span {
        margin: 3px;
        margin-right: 10px;
    }
}

.overview-data-key.total {
    color: #5C9AFE;
}

.overview-data-value {
    font-family: "Roboto" !important;
    font-weight: normal;
    color: #67698B;
    font-size: 15px;
}

.overview-data-value.total {
    font-family: "Roboto" !important;
    font-weight: 600;
    color: #5C9AFE;
}

.overview-hr {
    border-color: #a4a7be33;
}