@import "../../scss/colors.scss";

.alternative-modal {
  position: fixed;
  z-index: 2;
  padding-top: 5%;
  padding-bottom: 5%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--modal-backdrop);
  .alternative-modal-content {
    background-color: $white;
    margin: auto;
    padding: 20px;
    width: 40%;
    min-width: 500px;
    border-radius: 20px;
    box-shadow: 0px 2px 8px 0px #00000026;
    .modal-close-icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      opacity: 0.75;
      &:hover {
        opacity: 1;
      }
    }
  }
  .modal-close-container {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0;
  }
  
}

