.fund-compare-modal > .alternative-modal > .alternative-modal-content {
    width: 75%;
    height: auto;
}

.fund-compare-modal > .alternative-modal > .alternative-modal-content > .compare-modal-body {
    padding: 0;
}

.fund-compare-modal > .alternative-modal > .alternative-modal-content > .modal-close-container {
    padding-bottom: 1rem;
}

.fund-compare-modal > .alternative-modal > .alternative-modal-content > .modal-close-container .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.fund-compare-modal > .alternative-modal > .alternative-modal-content > .compare-modal-body > .full-period-picker {
    padding: 0 0 1rem 0;
}

.fund-compare-modal > .alternative-modal > .alternative-modal-content > .compare-modal-body > .full-period-picker > .period-picker-left > .ant-picker {
    color: #717171;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    background: #ffffff;
    border: 1px solid #C4C4C4;
    border-radius: 0.25rem;
    transition: all 0s;
    outline: 0;
}