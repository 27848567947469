@import "../../../../../scss/colors.scss";
@import "../../../../../scss/fonts.scss";

.wallet-card-pie-container-statics {
  padding: 0 30px 0 0;
  position: relative;
}

.wallet-cartera-list {
  .list {
    padding: 10px 10px 0 0px;
    float: right;
    width: 70%;
    border-bottom: 1px solid #0000000f;
    overflow-y: scroll;
    .element {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .description {
        font-family: "Roboto";
        font-size: 10px;
        font-weight: $font-weight-medium;
        color: $color-text-black;
        width: 80%;
      }
      .percentage  {
        font-family: "Roboto";
        font-size: 10px;
        font-weight: $font-weight-sm;
        color: $color-text-black;
        text-align: right;
        width: 50%;
      }
      .point-icon {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        border: 5px solid;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
  .line {
    height: 0.5px;
    width: 100%;
    background: #cacde6;
  }

  .total {
    padding: 18px 0;
    float: right;
    width: 70%;
  }

  .title {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: $font-weight-bold;
    color: $color-text-black;
    margin: 0;
    float: left;
  }

  .value {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: $font-weight-bold;
    color: $color-primary;
    float: right;
  }
}

.msj-no-tenencia {
  font-family: "Roboto";
  font-size: 14px;
  color: $color-primary;
  font-weight: $font-weight-bold;
}
