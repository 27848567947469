@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.component-information-modal-general-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: none;
  box-shadow: 0px 2px 8px 0px #00000026;
}

.show-modal-information {
  display: block
}

.hide-modal-information {
  display: none
}

.component-information-modal-container {
  position: relative;
  width: 465px;
  height: 392px;
  background: $white;
  margin: 0 auto;
  top: calc(40vh - 150px);
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
}

.component-information-modal-wrapper {
  position: relative;
  padding: 4% 4.5% 4% 4.5%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.component-information-modal-x-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.component-information-modal-close-container {
  
}

.component-information-modal-close-icon {
  cursor: pointer;
}

.component-information-modal-x {
  color: #e80854;
}

.component-information-modal-title-container {
  text-align: center;
  padding: 1.5% 0% 0% 0%;
}

.component-information-modal-title-container-fund {
  text-align: center;
}

.component-information-modal-title {
  font-family: "Roboto" !important !important;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #040560;
  opacity: 1;
}

.component-information-modal-title-fund {
  color: $color-text-black;
  font-size: $h5-font-size;
  font-family: "Roboto";
  font-weight: $font-weight-sm;
}

.component-information-modal-warning-container {

}

.component-information-modal-warning-icon {
  height: 75px;
  width: 75px;
}

.component-information-modal-description-container {
  text-align: center;
  width: 270px;
  margin: auto;
  padding: 5% 0% 7% 0%;
}

.component-information-modal-description-container-fund {
  text-align: center;
  padding: 5% 0% 0% 0%;
}
  
.component-information-modal-description {
  font-family: "Roboto" !important !important;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2D2E8F;
  opacity: 1;
}

.component-information-modal-description-fund {
  text-align: center;
  font-size: $msg-font-size;
  font-weight: $font-weight-sm;
  color: #ABABAB;
  font-family: "Roboto";
}
  
.component-information-modal-byma-description-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #F4F6FD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
}

.component-information-modal-byma-description-container-fund {
  text-align: center;
  padding: 5% 0% 0% 0%;
}

.component-information-modal-byma-description {
  font: normal normal 600 14px/22px "Roboto";
  letter-spacing: 0px;
  color: #2D2E8F;
  opacity: 1;
}

.component-information-modal-byma-description-fund {
  font: normal normal medium 16px/25px "Roboto";
  letter-spacing: 0px;
  color: #717171;
  opacity: 1;
}

.component-information-modal-time-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #E80451 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
}

.component-information-modal-time-container-fund {
  text-align: center;
  padding: 2.5% 0% 0% 0%;
}

.component-information-modal-calendar-container {
  position: relative;
  display: flex;
  padding: 0% 4.5% 0% 0%;
}
  
.component-information-modal-calendar-icon {
  
}
  
.component-information-modal-time {
  text-align: center;
  font: normal normal bold 14px/32px "Roboto";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0% 2.5% 0% 0%;
}
  
.component-information-modal-prop-time {
  text-align: center;
  font: normal normal normal 12px/16px "Roboto";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.component-information-modal-prop-time-fund {
  text-align: center;
  color: $color-primary;
  font-family: "Roboto";
  font-weight: $font-weight-sm;
}
  
.component-information-modal-confirmation-container {
  padding: 12% 0% 0% 0%;
}

.component-information-modal-confirmation-container-fund {
  text-align: center;
  padding: 7.5% 0% 0% 0%;
}
  
.component-information-modal-confirmation-description {
  position: relative;
  display: flex;
  width: 210px;
  height: 43px;
  margin: 0 auto;
  background: #423994 0% 0% no-repeat padding-box;
  border-radius: 22px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  font-family: "Roboto" !important !important;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.component-information-modal-confirmation-description-fund {
  position: relative;
  display: flex;
  width: 262px;
  height: 40px;
  margin: 0 auto;
  background: $color-primary;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 5px 25px 5px 25px;
  border: 1px solid $color-primary;
  box-shadow: 0px 2px 0px 0px #0000000B;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  color: $white;
  cursor: pointer;
  font-family: "Roboto";
}