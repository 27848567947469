@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.market-price-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem 4rem;
  .market-price-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    .market-price-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: $title-modal-font-size;
      font-weight: $font-weight-medium;
      color: $color-secondary;
      font-family: 'Roboto';
      margin: 0px 0px 10px 0px;
      span {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
      }
    }
  }
  .market-index-price {
    .container-list {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .mep-quotation-item-container {
        padding: 10px 15px 10px 15px;
        gap: 5px;
        border-radius: 20px;
        width: 180px;
        height: 95px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        background-color: $color-secondary;
        .mep-quotation-item-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;
          align-items: center;
          position: relative;
          width: 100%;
          height: auto;
          gap: 10px;
          .mep-quotation-text {
            padding: 0 0 0 0.5rem;
            .mep-quotation-symbol {
              font-weight: $font-weight-medium;
              font-size: $msg-font-size;
              font-family: 'Roboto';
              color: $white;
            }
            .mep-quotation-description {
              font-weight: $font-weight-sm;
              font-size: $footnote-description-font-size;
              font-family: 'Roboto';
              color: $white;
            }
          }
          .mep-quotation-icon {
            border-radius: 100px;
          }
        }
      }

      .mep-quotation-item-price-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        gap: 40px;
        .mep-quotation-info-container {
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 0;

          .mep-quotation-subtitle {
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            font-size: $xsmall-font-size;
            color: $white;
            font-family: 'Roboto';
            font-weight: $font-weight-medium;
          }
          .mep-quotation-item-price {
            position: relative;
            width: auto;
            height: auto;
            padding: 0;
            margin: 0;
            color: $white;
            font-size: $roboto-12-medium-font-size;
            font-family: 'Roboto';
            font-weight: $font-weight-sm;
          }
        }
      }
    }
  }
  .market-coin-price {
    width: 100%;
    display: flex;
    flex-direction: column;
    .market-price-title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      .market-price-title-gray {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: $title-modal-font-size;
        font-weight: $font-weight-medium;
        color: $color-secondary;
        font-family: 'Roboto';
        margin: 0px 0px 10px 0px;
        span {
          font-size: $msg-font-size;
          font-weight: $font-weight-sm;
        }
      }
    }
  }

  .quotation-container {
    display: flex;
    flex-direction: row;
    gap: 18px;
    .container-list {
      box-shadow: 0px 2px 8px 0px #00000026;
      padding: 5px 10px 5px 10px;
      border-radius: 20px;
      width: 180px;
      height: 95px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      .quotation-item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        .quotation-item-title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          width: 100%;
          height: auto;
          gap: 10px;
          .quotation-text {
            padding: 0 0 0 0.5rem;
            .quotation-symbol {
              font-weight: $font-weight-medium;
              font-size: $msg-font-size;
              font-family: 'Roboto';
              color: $color-secondary;
            }
            .quotation-description {
              font-weight: $font-weight-sm;
              font-size: 10px;
              font-family: 'Roboto';
              color: $color-secondary;
            }
          }
          .quotation-icon-container {
            display: flex;
            align-items: center;
            height: 24px;
            width: 24px;
            position: relative;
            margin-left: 10px;
            .quotation-icon {
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 100%;
              height: 25px;
              width: 25px;
              object-fit: cover;
            }
            .quotation-icon + .quotation-icon {
              top: 5px;
              left: -5px;
            }

          }
        }
        .quotation-item-price-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0px;
          .quotation-item-price {
            font-weight: $font-weight-medium;
            font-size: $x-medium-font-size;
            font-family: 'Roboto';
            color: $color-secondary;
            padding: 0;
            margin-top: 5px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
