:root {
  --active: #52B5B1;
  --hover: #d6d6d6;
}

.sidebar-container {
  padding: 5px 5px;
  width: 60px;
  grid-area: sidebar;
}

.sidebar-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.sidebar-container.expanded {
  width: 275px;
  z-index: 1;
}

.sidebar-logo {
  position: relative;
  width: 100%;
  top: 10px;
}

.header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.circle-partner-letter {
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 50%;
  background: #B1E9E6;
  color: var(--active);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
}

.side-bar-logo-image {
  width: 32px;
  height: 32px;
}

.header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  left: 5px;
  height: 100%;
  width: 210px;
  color: var(--hover);
  font-size: 16px;
}

.sidebar-items {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  top: 18px;
  a {
    margin-top: 22px;
  }
}

.link-router-dom {
  text-decoration: none;
}

.item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 48px;
  width: 100%;
  color: var(--hover);
  font-size: 16px;
}

.item-icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item-icon-image {
  margin-bottom: 5px;
  width: 18px;
  height: 18px;
}

.item-icon-text {
  margin-top: 6px;
  font-family: "Roboto";
  font-size: 9px;
  font-weight: 500;
}

.no-selectionable {
  user-select: none;
}

.selected {
  color: var(--active);
}

.selected3 {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  color: var(--active);
}

.selected3 > img {
  width: 5px;
}

.item-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  top: -27px;
  left: 3px;
  height: 30px;
  width: 210px;
  font-size: 16px;
  color: var(--hover);
}

.item-description.selected2 {
  color: var(--active);
}

.mask-triangle {
  position: absolute;
  z-index: 600;
  top: -10px;
  left: 85px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-left: 30px solid #01043b;
  border-bottom: 30px solid transparent;
}

.mask-border {
  position: absolute;
  z-index: 7;
  top: -18px;
  left: 15px;
  height: 45px;
  width: 250px;
  border: 1px solid var(--active);
  border-radius: 5px;
}

.sidebar-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 320px;
  width: 100%;
}
