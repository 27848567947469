.amount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}

.amount-footer {
    width: 40%;
    text-align: center
}

.hr-underline {
    border-color: #A4A7BE;
    opacity: .4;
}

.help-text {
    color: #67698B;
    font-size: 14px;
    font-weight: 600;
}

.f-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.currency-symbol-xl {
    font-size: 54px;
    font-family: "Roboto" !important;
    color: #67698B;
    margin: 0 10px;
}

.input-number-xl {
    display: block;
    width: 100%;
    font-size: 54px;
    font-family: "Roboto" !important;
    font-weight: 500;
    color: #67698B;
    border: 0px;
    box-sizing: border-box;
}

.buttons-footer-container {
    display: grid;
    width: 40%;
    text-align: center;
}

.skip-upload-button {
    margin: 15px 0;
    text-decoration: underline;
    color: #737DFF;
    font-size: 15px;
}


/* Remove arrows from number inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}