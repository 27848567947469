@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.card-history-chart-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  .market-history-chart-period {
    position: relative;
    width: 100%;
    height: auto;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    p {
      font-size: $roboto-12-medium-font-size;
      font-family: 'Roboto';
      font-weight: $font-weight-sm;
      color: $color-text-black;
      margin: 0;
      padding: 0;
    }
  }
  > canvas {
    width: 100% !important;
  }
}
