@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.tab-bar-general-container {
  position: relative;
  width: 100%;
  height: 5%;
  padding: 30px 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  .tab-bar-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    .tab-bar-options-list-container {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;
      .tab-bar-option-container {
        position: relative;
        padding: 5px 50px 5px 50px;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
        border-radius: 100px;
        border: 1px solid $color-primary;
        &.text-selected {
          transition-duration: 900ms;
          color: $white;
          background-color: $color-primary;
          border: 1px solid $color-primary;
        }
        &:not(.text-selected) {
          transition-duration: 900ms;
          background-color: $color-background;
          color: $color-primary;
          border: 1px solid $color-primary;
        }
        .tab-bar-option-text {
          font-family: 'Roboto';
          font-size: $roboto-12-medium-font-size;
          font-weight: $font-weight-sm;
          cursor: pointer;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
