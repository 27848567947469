.time-period-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0 1rem;
}

.time-period-title {
  position: relative;
  width: auto;
  height: auto;
  color: #2d2e8f;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: bold;
}

.select-team-period {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3px;
  padding: 7px 11px 7px 11px;
  background-color: white;
  box-shadow: 0px 2px 3px #0000000d;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  font-size: 11px;
  align-items: center;
  cursor: pointer;
}
.select-team-period img {
  width: 10px;
  transition: all 0.3s;
  margin: 5px;
}
.container-team-options {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 0.25rem;
  display: none;
  top: 65px;
  right: 0;
  bottom: auto;
  left: auto;
  color: #423994;
  border: 1px solid #423994;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  z-index: 100000;
}
.team-option {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #423994;
  font-size: 0.8rem;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}

.team-option:hover {
  color: #ffffff;
  background-color: #423994;
}

.arrow-up {
  transform: rotate(-180deg);
}

.display-drop-down {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.25rem 0.25rem;
}
.select-team-border {
  border: 1px solid #423994;
}

/* Select Period Buttons & Datepicker Container(s) */
/* .select-period-components-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 1rem;
}

.inactive-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #717171;
  background-color: #ffffff;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  cursor: pointer;
}

.inactive-period-button:hover {
  color: #423994;
  background-color: #D6D8FA;
}

.selected-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #ffffff;
  background-color: #423994;
  border: 1px solid #423994;
  border-radius: 5px;
  cursor: pointer;
}

.selected-period-button:hover {
  color: #423994;
  background-color: #D6D8FA;
}

.disabled-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #00000029;
  background-color: #C4C4C4;
  border: 1px solid #00000029;
  border-radius: 5px;
  cursor: pointer;
} */

/* Keyframing Animations for Micro-Interactions */
.slide-bottom {
	-webkit-animation: slide-bottom 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
	        animation: slide-bottom 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
}

 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-15);
            transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
