.team-row-fee-main-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
}

.team-dashboard-title-container {
  font-family: "Roboto" !important;
  font-size: 24px;
  font-weight: bold;
  color: #737dff;
  text-align: left;
  padding: 18px 0;
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.team-partners-fee-general-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 20px #0115571c;
  border-radius: 5px;
}

.team-partners-fee-sub-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem 1rem;
}

.team-partners-fee-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  color: #2d2e8f;
  text-align: left;
  font-family: "Roboto" !important;
  font-size: 1.5rem;
  font-weight: bold;

  &::before {
    position: absolute;
    width: 5px;
    height: 2.25rem;
    top: 0.5rem;
    right: auto;
    bottom: auto;
    left: 0;
    content: "";
    background: #737dff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000a;
    border-radius: 3px;
  }
}

.team-partners-fee-chart-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 1rem 1rem;
  flex-wrap: nowrap;
}

.select-period-buttons-container{
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
}

.special-fee-button-size {
  padding: 0.5rem 1rem;
}