@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.container-full-sent-successfully{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000000;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color: $color-background;
}
.sent-successfully{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 400px;
    background-color: $color-background;
    border-radius: 15px;
    padding: 48px 70px;
    margin: 32px 0;
    box-shadow: 0px 3px 6px #00000029;
}
.logo-check{
    width: 104px;
    margin-bottom: 50px;
}
.title-sent-successfully{
    font-size: $large-font-size;
    font-family: "Roboto", Arial, Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: $color-secondary;
    text-align: center;
}
.text-sent-successfully{
    font-size: 17px;
    font-family: "Roboto", Arial, Arial, Helvetica, sans-serif;
    color: $color-gray;
    text-align: center;
}
