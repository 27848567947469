:root {
    --card-bg: #FFFFFF;
    --blue: #737DFF;
    --grey: #A4A7BE;
    --active-blue: #3B86FF;
    --idle-grey: #E9E9F0;
}

.tabs-card-container {
    background: var(--card-bg);
    border-radius: 10px;
    padding: 0px 20px;
}

.tabs-header {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    border-bottom: 1px solid var(--idle-grey);
    opacity: .8;
}

.tab-button {
    padding: 15px;
    margin-top: 10px;
    font-size: 14px;
    font-family: 'Roboto';
    text-transform: uppercase;
    text-align: center;
    color: var(--grey);
}

.tab-button.active {
    color: var(--blue);
    border-bottom: 2px solid var(--active-blue);
}

.tabs-card-body {
    padding: 20px 0px;
}

.empty-view-container {
    color: var(--grey);
}