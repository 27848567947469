@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

/* Default Component */
.title {
  font-size: $extra-large-font-size;
  margin: 0;
  padding: 0;
  font-weight: $font-weight-medium;
  font-family: 'Roboto';
}

.subtitle {
  font-size: 16px;
  margin: 0;
  font-family: 'Roboto';
  font-weight: $font-weight-medium;
}

.paragraph {
  font-size: 14px;
  margin: 0 0;
  margin: 3px;
}

.text {
  font-size: $h5-font-size;
  margin: 0 0;
}

/* Set Color */
.main {
  color: $color-primary;
}
.dark{
  color: $color-text-black;
}
.text {
  color: $dark-gray;

}

.link {
  color: #5c9afe;
}

.danger {
  color: #ff4141;
}

.success {
  color: #52B5B1;
}

.light {
  color: $white;
  font-size: 20px;
}

.no-underline {
  text-decoration: none;
}

.purple {
  color: $dark-blue;
}
