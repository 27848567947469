@import "src/scss/reset.scss";
@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

::-webkit-scrollbar {
  width: 4px !important;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.balance-card-container {
  position: relative;
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  align-self: stretch;
  .button-possession-download {
    position: relative;
    width: auto;
    height: auto;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: $white;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    span {
      font-family: "Roboto";
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-medium;
      color: $white;
      text-align: center;
      text-transform: uppercase;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
}

.wallet-mini-card-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1rem;
  background: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  &.saldos {
    .header {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0 0 1rem 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      color: $color-text-black;
      font-family: "Roboto";
      font-weight: $font-weight-medium;
      font-size: $h5-font-size;
      gap: 10px;
    }
    .item {
      border-top: 1px solid #0000000f;
      padding: 20px 0;
      text-align: right;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:last-child {
        border-bottom: 1px solid #0000000f;
      }
      .field {
        color: $color-text-black;
        margin: 0px 20px 0px 0px;
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
      }

      .value {
        color: $color-text-black;
        margin: 0px 20px 0px 0px;
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
      }
    }
  }
  .disclaimer-mep{
    font-size: 10px;
    font-weight: $font-weight-medium;
    color: $color-gray;
    font-family: "Roboto";
    margin-top: 5px;
  }
}
