@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.wallet-dashboard-container-left {
  position: relative;
  width: 30%;
  height: 92vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  gap: 15px;
  z-index: 1;
  .table-container {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: scroll;
    .title-wallet {
      position: relative;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      color: $color-text-black;
      font-family: "Roboto";
      font-size: $x-medium-font-size;
      font-weight: $font-weight-medium;
    }
  }
  .table-container {
    .table {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .element {
        position: relative;
        width: 97%;
        height: auto;
        margin: 0 0 1rem 0;
        padding: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 1rem 1rem;
        background-color: $white;
        box-shadow: 0px 2px 8px 0px #00000026;
        border: 1px solid $color-background;
        border-radius: 20px;
        cursor: pointer;
        font-family: "Roboto";
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .item-center {
          flex-grow: 1;
          .name {
            color: $color-text-black;
            font-family: "Roboto";
            font-weight: $font-weight-sm;
            font-size: $roboto-12-medium-font-size;
            margin-right: auto;
          }
          .number {
            width: 100%;
            font-family: "Roboto";
            font-weight: $font-weight-sm;
            font-size: $roboto-12-medium-font-size;
            margin-right: auto;
            color: #00000040;
          }
          .item {
            font-size: 14px;
            margin-bottom: 15px;

            .text {
              width: max-content;
              font-family: "Roboto";
              font-size: $roboto-12-medium-font-size;
              font-weight: $font-weight-sm;
              color: $color-text-black;
            }
          }
         
        }
        &.element-selected {
          border: 1px solid $color-primary;
          box-shadow: 0px 2px 8px 0px #00000026;
        }
        &.active{
          border: 1px solid $color-primary !important;
        }
      }
      .element-selected {
        border: 1px solid $color-primary;
      }
      
      .elementSelected {
        position: relative;
        width: 97%;
        height: auto;
        margin: 0 0 1rem 0;
        padding: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 1rem 1rem;
        background-color: $white;
        box-shadow: 0px 2px 8px 0px #00000026;
        border: 1px solid #7381F7;
        border-radius: 20px;
        cursor: pointer;
        font-family: "Roboto";
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .item-center {
          flex-grow: 1;
          .name {
            color: $color-text-black;
            font-family: "Roboto";
            font-weight: $font-weight-sm;
            font-size: $roboto-12-medium-font-size;
            margin-right: auto;
          }
          .number {
            width: 100%;
            font-family: "Roboto";
            font-weight: $font-weight-sm;
            font-size: $roboto-12-medium-font-size;
            margin-right: auto;
            color: #00000040;
          }
          .item {
            font-size: 14px;
            margin-bottom: 15px;

            .text {
              width: max-content;
              font-family: "Roboto";
              font-size: $roboto-12-medium-font-size;
              font-weight: $font-weight-sm;
              color: $color-text-black;
            }
          }
         
        }
        &.element-selected {
          border: 1px solid $color-primary;
          box-shadow: 0px 2px 8px 0px #00000026;
        }
        &.active{
          border: 1px solid $color-primary !important;
        }
      }

      &::-webkit-scrollbar {
        background: #ffffff;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #e6e6e6;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        box-shadow: inset 0 0 6px #dfdbdb;
      }
      .element-disabled {
        display: flex;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        margin: 5px 0 10px;
        padding: 24px 26px;
        border-radius: 2px;
        box-sizing: border-box;
        cursor: wait;
        opacity: 0.3;
        .item-center {
          flex-grow: 1;
          color: #67698b;
          font-size: 14px;
          .name {
            color: #263238;
            font-size: 14px;
            margin-bottom: 5px;
            font-family: "Roboto" !important;
            font-weight: 500;
            width: 95%;
            margin-right: auto;
          }
        }
      }
     
    }
  }

  .btn-create-group .description {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #737dff;
    opacity: 1;
  }
}

.wallet-dashboard-container-left-off-canvas {
  position: relative;
  display: none;
}

.show-hide-menu-button {
  position: absolute;
  width: auto;
  height: auto;
  padding: 1rem 0;
  top: 0;
  right: auto;
  bottom: auto;
  left: 27%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  z-index: 2;
}

.show-hide-menu-button-relocate {
  position: absolute;
  width: auto;
  height: auto;
  padding: 1rem;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  z-index: 2;
}

.menu-trigger-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
}

/* Animation & Transitions */

.slide-in-left {
  -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.3s ease-in(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.3s ease-in(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
