@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.clientsWallet-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: 10px 10px 20px #0000000f;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  background-color: $white;
  .clientsWallet-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    .clientsWallet-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0;
      img {
        width: 35px;
        margin: 0px 15px 0px 0px;
      }
      h2 {
        font-size: $h3-font-size;
      }
    }

    .clientsWallet-header-selections {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
    }
  }
  .clientsWallet-header-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 1rem;
    color: $white;
    font-size: 14px;
    background-color: $color-primary;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 0px;
    .clientsWallet-header-content-item-detalle {
      text-align: start;
      margin-left: 16px;
      .item-details-titles {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        color: $white;
        font-family: 'Roboto';
        margin: 0;
        padding: 0;
      }
    }
    .clientsWallet-header-content-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .item-details-titles {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        color: $white;
        font-family: 'Roboto';
        margin: 0;
        padding: 0;
      }
      .no-button {
        background: none;
        border: none;
        outline: none;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
  .space-text-tootlip-home {
    height: 0;
    width: 0;
    margin: 0px 5px;
    padding: 0;
  }
  .margin-tooltip {
    margin-left: 0.5rem;
  }

  .clientsWallet-content {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    z-index: 30;
    margin: 10px 0px;
    &::-webkit-scrollbar {
      background: #ffffff;
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #f4f6fd;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 6px #d2daf4;
    }

    &:hover::-webkit-scrollbar {
      background: #f4f6fd;
    }
  }
}
