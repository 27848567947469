/* Basic Adjustments */
* {
  box-sizing: border-box;
  font-family: "Roboto" !important;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  //font-family: "Roboto" !important;
  letter-spacing: 0.01em;
  line-height: 1.25rem;
  color: #000000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.market-view-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
  background-color: #f4f6fd;
}

.market-container {
  /* padding: 20px 30px;
  position: relative;
  align-items: flex-start;
  justify-items: flex-start;
  height: 90%;
  overflow: auto; */
}

.market-container::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background: transparent;
}

.market-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  box-shadow: inset 0 0 3px #687ec7;
}

.market-container:hover::-webkit-scrollbar {
  background: transparent;
}

/* ************************************************************ */

figure {
  margin: 0;
  padding: 0;
}

/* iframe {
  display: flex !important;
} */

// iframe {
//   display: none !important;
// }

/* ************************************************************ */
/* .market-container-card-list-element-more-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10%;
  width: 90%;
  cursor: pointer;
} */

.item-market-separator {
  position: relative;
  width: 100%;
  height: 1.5rem;
  display: block;
}

/*taking control of Trading-View iframe and above containers*/
.container-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem 0;
}

/* .container-list > div {
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
} */

/* iframe body .tv-embed-widget-wrapper .tv-embed-widget-wrapper__body{
  background-color: rebeccapurple !important;
  background: green !important;
  padding: 0.5rem !important;
  border: 0 !important;
} */

/* .container-list > div > iframe > html .theme-light body .tv-embed-widget-wrapper .tv-embed-widget-wrapper__body .js-embed-widget-body .tv-mini-symbol-overview__link .tv-mini-symbol-overview__ticker {
  background: red !important;
  border: 0 !important;
}

.vertical-market-separator {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.325rem 0;
}*/