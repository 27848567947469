@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.movement-container {
  min-height: 44px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  height: auto;
  gap: 1%;
  padding: 10px 15px 10px 0px;
  border-radius: 5px;
  background-color: $color-background;
  .movement-item-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    font-size: $roboto-12-medium-font-size;
    color: $color-text-black;
    font-family: "Roboto";
    font-weight: $font-weight-sm;
    height: auto;
    .dot {
        width: 7px;
        height: 7px;
        margin: 0px 10px;
      }      
    .movement-type-container {
      .movement-type-text {
        text-align: center;
        margin: 0;
        padding: 0;
        font-weight: $font-weight-medium;
        font-size: $roboto-12-medium-font-size;
        color: $color-text-black;
        font-family: "Roboto";
      }
    }
  }
  .movement-date-container {
    .movement-date-text {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: $roboto-12-medium-font-size;
      color: $color-text-black;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
  }
  .movement-customer-name-container {
    .movement-customer-name-text {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: $roboto-12-medium-font-size;
      color: $color-text-black;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
  }
  .movement-detail-container {
    .movement-detail-text {
      text-align: center;
      text-wrap: nowrap;
      margin: 0;
      padding: 0;
      font-size: $roboto-12-medium-font-size;
      color: $color-text-black;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
  }
  .movement-amount {
    .movement-amount-text {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: $roboto-12-medium-font-size;
      color: $color-text-black;
      font-family: "Roboto";
      font-weight: $font-weight-bold;
      &.positive {
        color: $color-success;
      }
      &.negative {
        color: $color-error;
      }
    }
  }
}

.movement-img {
  height: 18px;
  cursor: pointer;
}

