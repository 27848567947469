@import '../../../../../scss/colors.scss';
@import '../../../../../scss/fonts.scss';

.data-element-operate-custom-input-general-container {
  .data-element-operate-custom-input-wrapper {
    .data-element-operate-custom-input {
      width: 100%;
      border-radius: 20px;
      border: 1px solid $color-gray;
      padding: 5px 15px 5px 15px;
      gap: 4px;
      background-color: transparent;
      color: $color-gray;
      text-align: right;
      font-family: 'Roboto';
      font-weight: $font-weight-sm;
      font-size: $msg-font-size;
      outline: none;
      &:focus-visible {
        color: $color-text-black !important;
      }
    }
  }
}

.data-element-operate-custom-input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid $color-gray;
  padding: 5px 15px 5px 15px;
  gap: 4px;
  background-color: transparent;
  color: $color-gray;
  text-align: right;
  font-family: 'Roboto';
  font-weight: $font-weight-sm;
  font-size: $msg-font-size;
  outline: none;
  &:focus-visible {
    color: $color-text-black !important;
  }
}
