@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.operate-dashboard-container-main-card {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  background: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  padding: 20px;
  .operate-sub-items-buy-sell-view {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    .operate-main-card-view-general-container {
      position: relative;
      width: 100%;
      height: auto;
      padding: 1rem 1rem 0 1rem;
      .operate-sub-items-buy-sell-view-name-group {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0 0 1rem 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        .operate-select-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .operate-select-info-text {
            font-size: $h5-font-size;
            font-weight: $font-weight-sm;
            font-family: "Roboto";
            color: $color-secondary;
            margin: 0;
            padding: 0;
          }
        }
        .operate-select-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 19px;
          width: 100%;
          .operate-select-instrument-container {
            position: relative;
            width: 250px;
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            gap: 0;
            .title-instrument-operate {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .title-instrument-operate-text {
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                padding: 0 0 0.175rem 0;
                margin: 0;
                font-style: normal;
                &.blue-text {
                  color: $color-primary;
                }
                &.gray-text {
                  color: $color-gray;
                }
              }
            }
            .select-container-clients-wallet-active-option {
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: $color-background;
              border: 1px solid $color-primary;
              .select-container-clients-wallet {
                width: 100%;
                .select-container-clients-wallet-title {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-primary;
                  padding-right: 15px;
                }
              }
            }
            .select-container-clients-wallet-disable-option {
              width: 100%;
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
                linear-gradient(0deg, #ababab, #ababab);
              border: 1px solid $color-gray;
              .select-container-clients-wallet {
                width: 100%;
                .select-container-clients-wallet-title {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-gray;
                }
              }
            }
          }
          .operate-select-type-container {
            position: relative;
            width: 250px;
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            gap: 0;
            .title-type-operate {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .title-type-operate-text {
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                padding: 0 0 0.175rem 0;
                margin: 0;
                &.gray-text {
                  color: $color-gray;
                }
                &.blue-text {
                  color: $color-primary;
                }
              }
            }
            .select-container-clients-wallet-active-option {
              width: 100%;
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: $color-background;
              border: 1px solid $color-primary;
              .select-container-clients-wallet {
                width: 100%;
                .select-container-clients-wallet-title {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-primary;
                }
              }
            }
            .select-container-clients-wallet-disable-option {
              width: 100%;
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
                linear-gradient(0deg, #ababab, #ababab);
              border: 1px solid $color-gray;
              pointer-events: none;
              .select-container-clients-wallet {
                width: 100%;
                .select-container-clients-wallet-title {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-gray;
                }
              }
            }
          }
          .operate-select-specie-container {
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            gap: 0;
            .title-specie-operate {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .title-specie-operate-text {
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                padding: 0 0 0.175rem 0;
                margin: 0;
                &.blue-text {
                  color: $color-primary;
                }
                &.gray-text {
                  color: $color-gray;
                }
              }
            }
            .select-search-container-disabled {
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
                linear-gradient(0deg, #ababab, #ababab);
              border: 1px solid $color-gray;
              width: 400px;
              display: flex;
              justify-content: space-between;

              .select-search-container-title {
                &::placeholder {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-gray;
                  font-style: normal;
                }
                padding-right: 15px;
                .select-search-container-options {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-gray;
                  font-style: normal;
                }
              }
              input {
                background: transparent;
                border: none;
                outline: none;
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                color: $color-gray;
              }
            }
            .select-search-container-active {
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              background: $color-background;
              border: 1px solid $color-primary;
              width: 400px;
              display: flex;
              justify-content: space-between;

              .select-search-container-title {
                &::placeholder {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-primary;
                  font-style: normal;
                }
                padding-right: 15px;
                .select-search-container-options {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                  color: $color-primary;
                  font-style: normal;
                }
              }
              input {
                background: transparent;
                border: none;
                outline: none;
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                color: $color-primary;
              }
            }
            .select-search-container {
              padding: 5px 15px 5px 15px;
              gap: 5px;
              border-radius: 20px;
              > input {
                font-size: $msg-font-size;
                font-weight: $font-weight-sm;
                font-family: "Roboto";
                padding: 0;
                margin: 0;
                font-style: normal;
                &::placeholder {
                  font-size: $msg-font-size;
                  font-weight: $font-weight-sm;
                  font-family: "Roboto";
                }
              }
            }
          }
        }
      }
    }
  }
  .operate-dashboard-filter-tabs-gray {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    .operate-sub-items-container {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;
      .sub-item {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        text-align: center;
        font-family: "Roboto";
        padding: 5px 15px 5px 15px;
        gap: 10px;
        color: $color-gray;
        cursor: pointer;
        &.selected-sub-item {
          border-radius: 100px;
          border: 1px solid $color-gray;
        }
      }
    }
  }
  .operate-dashboard-filter-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    .operate-sub-items-container {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;
      .sub-item {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        text-align: center;
        font-family: "Roboto";
        padding: 5px 15px 5px 15px;
        gap: 10px;
        color: $color-primary;
        cursor: pointer;
        &.selected-sub-item {
          border-radius: 100px;
          border: 1px solid $color-primary;
        }
      }
    }
  }
}

/* *********************************************** */

.operate-sub-items-buy-sell-view-low {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  .liquidity-term-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 0;
    .title-operate-term {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .title-operate-term-text {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        padding: 0 0 0.175rem 0;
        margin: 0;
        &.blue-text {
          color: $color-primary;
        }
        &.gray-text {
          color: $color-gray;
        }
      }
    }
    .operate-main-card-view-radio-buttons-wrapper {
      .operate-main-card-view-radios-container {
        .operate-main-card-view-radio-element {
          padding: 0;
          margin: 0;
          .operate-main-card-view-icon-container {
            .control {
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
            }
            .control input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }
            .control_indicator {
              height: 16px;
              width: 16px;
              border-radius: 50%;
              &.active{
                border: 1px solid $color-primary;
              }
              &.inactive{
                border: 1px solid $color-gray;
              }
            }
            .control:hover input ~ .control_indicator.inactive,
            .control input:focus ~ .control_indicator.inactive {
              border-color: $color-gray;
            }
            .control:hover input ~ .control_indicator.active,
            .control input:focus ~ .control_indicator.active {
              border-color: $color-primary;
            }
            .control input:checked ~ .control_indicator.inactive {
              border-color: $color-gray !important;
              background-color: transparent !important;
            }
            .control input:checked ~ .control_indicator.active {
              border-color: $color-primary !important;
              background-color: transparent !important;
            }
            .control_indicator:after {
              content: "" !important;
              position: absolute !important;
              display: none !important;
            }
            .control input:checked ~ .control_indicator.inactive:after {
              display: block !important;
              left: 4px !important;
              top: 4px !important;
              width: 8px !important;
              height: 8px !important;
              border-radius: 50% !important;
              background-color: $color-gray !important;
            }
            .control input:checked ~ .control_indicator.active:after {
              display: block !important;
              left: 4px !important;
              top: 4px !important;
              width: 8px !important;
              height: 8px !important;
              border-radius: 50% !important;
              background-color: $color-primary !important;
            }
            .option-text {
              font-weight: $font-weight-sm;
              font-size: $msg-font-size;
              color: $color-primary;
              font-family: "Roboto";
            }
            .operate-main-card-view-radio-text {
              font-size: $msg-font-size;
              font-weight: $font-weight-sm;
              font-family: "Roboto";
              white-space: nowrap;
              &.active{
                color: $color-primary;
                white-space: nowrap;
              }
              &.inactive{
                color: $color-gray;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    .ant-table-container {
      border: none;
      border-radius: 0;
      margin-top: 20px;
      .ant-table table {
        border-radius: 0;
      }
      .ant-table-thead > tr > th {
        background-color: transparent !important;
        border: none !important;
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        color: $color-gray;
        font-family: "Roboto";
        background-color: transparent;
        padding: 0;
        margin: 0;
        text-align: start;
        .operate-table-header-container {
          color: transparent !important;
          background-color: transparent !important;
          font-size: $msg-font-size !important;
          font-weight: $font-weight-sm !important;
          font-family: "Roboto" !important;
          padding: 0 !important;
          margin-bottom: 0 !important;
          text-align: left;
          
          &.blue-text {
            color: $color-primary !important;
          }
          &.gray-text {
            color: $color-gray !important;
          }
        }
      }
      .ant-table-thead > tr:first-child th:last-child::before {
        display: none !important;
      }
      .ant-table-tbody > tr > td {
        padding-right: 15px !important;
        border-top: 0 !important;
      }
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background-color: transparent !important;
      }
      .ant-table-tbody > tr:last-of-type > td {
        border-bottom: 0 !important;
      }
      .ant-table-tbody > tr > td:last-child {
        border-right: 0 !important;
      }
      .ant-table-tbody > tr:first-of-type > td {
        border-top: 0 !important;
      }
      .ant-table-tbody > tr > td:first-child {
        border-left: 0 !important;
      }
      .ant-table-tbody .operate-container-price-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .operate-container-price-limit {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 5px;
          .operate-main-card-view-icon-container {
            .control {
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
            }
            .control input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }
            .control_indicator {
              height: 16px;
              width: 16px;
              border-radius: 50%;
              &.active{
                border: 1px solid $color-primary;
              }
              &.inactive{
                border: 1px solid $color-gray;
              }
            }
            .control:hover input ~ .control_indicator.inactive,
            .control input:focus ~ .control_indicator.inactive {
              border-color: $color-gray;
            }
            .control:hover input ~ .control_indicator.active,
            .control input:focus ~ .control_indicator.active {
              border-color: $color-primary;
            }
            .control input:checked ~ .control_indicator.inactive {
              border-color: $color-gray !important;
              background-color: transparent !important;
            }
            .control input:checked ~ .control_indicator.active {
              border-color: $color-primary !important;
              background-color: transparent !important;
            }
            .control_indicator:after {
              content: "" !important;
              position: absolute !important;
              display: none !important;
            }
            .control input:checked ~ .control_indicator.inactive:after {
              display: block !important;
              left: 4px !important;
              top: 4px !important;
              width: 8px !important;
              height: 8px !important;
              border-radius: 50% !important;
              background-color: $color-gray !important;
            }
            .control input:checked ~ .control_indicator.active:after {
              display: block !important;
              left: 4px !important;
              top: 4px !important;
              width: 8px !important;
              height: 8px !important;
              border-radius: 50% !important;
              background-color: $color-primary !important;
            }
            .option-text {
              font-weight: $font-weight-sm;
              font-size: $msg-font-size;
              color: $color-primary;
              font-family: "Roboto";
            }
            .operate-main-card-view-radio-text {
              font-size: $msg-font-size;
              font-weight: $font-weight-sm;
              font-family: "Roboto";
              white-space: nowrap;
              &.active{
                color: $color-primary;
                white-space: nowrap;
              }
              &.inactive{
                color: $color-gray;
                white-space: nowrap;
              }
            }
          }
        }
      }
      .ant-table-summary > tr > td {
        border-bottom: 0;
      }
      .ant-table-summary .ant-table-cell {
        padding: 10px 0 0 0 !important;
      }
    }
  }
}
