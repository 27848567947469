@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';
.container-form-problem-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 23px;
  font-family: 'Roboto Regular', sans-serif;
  img {
    margin-bottom: 8px;
    width: 31px;
  }
  .description-login {
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-regular;
    color: $color-text-black;
  }
  .click-form-login {
    text-align: center;
    color: $color-primary;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-regular;
    cursor: pointer;
  }
}
.legals-link {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 0.938rem;
  text-align: center;
  opacity: 0.75;
  cursor: pointer;
  p {
    color: $color-primary;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-regular;
  }
}
