@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.terms-and-conditions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .footer-container {
    padding: 5px 25px 5px 25px;
    border: 1px solid $color-primary;
    border-radius: 20px;
    background: transparent;
    text-align: center;
    .btn-view-tc {
      cursor: pointer;
      .btn-biew-tc-text {
        font-family: "Roboto";
        font-weight: $font-weight-sm;
        font-size: $roboto-12-medium-font-size;
        color: $color-primary;
        padding: 0;
        margin: 0;
      }
    }
  }
  .component-disclaimer-modal-general-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50px;
    left: 2%;
    z-index: 9999;
    box-shadow: 0px 2px 8px 0px #00000026;
    .component-disclaimer-modal-container{
        width: 85%;
        border-radius: 20px;
        padding: 20px;
        top: calc(40vh - 270px);
        .component-disclaimer-modal-wrapper{
          padding: 0;
          .component-disclaimer-modal-title-container{
            padding: 0;
            .component-disclaimer-modal-title{
              font-family: "Roboto";
              font-weight: $font-weight-medium;
              font-size: $title-modal-font-size;
              color: $color-secondary;
            }
          }
          .component-disclaimer-modal-description-container{
            padding: 2% 1%;
            .component-disclaimer-modal-description{
              font-size: $h5-font-size;
              font-weight: $font-weight-medium;
              color: $color-secondary;
              font-family: "Roboto";
            }
          }
        }
    }
  }

}

.hide-modal-disclaimer {
  display: none;
}


