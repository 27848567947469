.modal-general-container {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(167, 167, 167, 0.5);
    display: none;
}

.open {
    display: block;

    .modal-container {
        box-sizing: border-box;
        position: relative;
        width: 465px;
        height: 392px;
        padding: 15px;
        background: #ffffff;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 25%);

        .close-icon-container {
            position: relative;
            display: flex;
            justify-content: flex-end;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;
        }
        
        .content  {
            margin: auto;
            text-align: center;

            .warning-icon {
                width: 164px;
                padding: 30px 0px 20px;
            }

            .modal-text { 
                p {
                    font-family: "Roboto" !important;
                    font-size: 16px;
                    font-weight: bold;
                    color: #040560;
                    margin: 10px 0px 20px;
                    line-height: 1.3em;
                    text-align: center;
                }
            }
        
            .modal-button-style {
                background-color: #423994;
                font-family: "Roboto" !important;
                font-weight: 600;
                font-size: 14px;
                color: white;
                cursor: pointer;
                width: 150px;
                height: 43px;
                border: none;
                border-radius: 30px;
            }
        }
    }
}

.open {
    display: block;
    border: none;

    .modal-detail-container {
        box-sizing: border-box;
        background-color: #ffffff;
        padding: 20px;
        position: absolute;
        top: 2vh;
        left: 15vw;
        width: 60vw;
        height: 77vh;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 25%);
        border: none;
        z-index: 2;

        .warning-icon-container {
            .warning-icon-detail {
                width: 60px;
                margin: auto;
                display: flex;
                justify-content: center;
            }
        }

        .modal-text { 
            p {
                font-family: "Roboto" !important;
                font-size: 16px;
                font-weight: bold;
                color: #040560;
                margin: 10px 0px 20px;
                line-height: 1.3em;
                text-align: center;
            }
        }

        .operate-confirm-procedure-container-data-header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            height: 65px;
            width: 100%;
            border-radius: 8px;
            background: #737dff;
        }

        .close-icon-container {
            position: relative;
            display: flex;
            justify-content: flex-end;
        }

        .close-icon {
            width: 17px;
            height: 17px;
            cursor: pointer;
            opacity: .8;
        }

        .close-icon:hover {
            opacity: 1;
          } 

        .orders-detail-container {
            width: 100%;
            margin: auto;
            overflow-y: auto;
            overflow-x: hidden;
    
            .orders-detail-container-header {
                font-family: "Roboto" !important;
                font-size: 12px;
                color: #a9a9bd;
                background: #f6f6fa;
                border-radius: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 0px 5px;
                padding: 5px 0px;
    
                h5 {
                    width: 100%;
                }
            }

            .orders-detail-container-body {
                font-family: "Roboto" !important;
                font-weight: 600;
                overflow-y: auto;
                height: 17vh;
                margin: 0px 0px 20px;
    
                .orders-detail-container-row {
                    display: grid;
                    grid-template-columns: 17% 17% 17% 17% 17% 17%;
                    align-items: center;
                    background-color: #ffbcd3;
                    color: #67676d;
                    border: 1px solid #ffffff;
                    padding: 20px;
                    
                    p {
                        font-size: 11px;
                        margin: 0px;
                        text-align: center;
                    }
                }

                .processed {
                    display: grid;
                    grid-template-columns: 17% 17% 17% 17% 17% 17%;
                    align-items: center;
                    background-color: #ffffff;
                    color: #a5a5a5;
                    border-bottom: 1px solid #a5a5a5;
                    
                    p {
                        font-size: 11px;
                        margin: 0px;
                        text-align: center;
                    }
                }
            }
        }

        .button-wrapper {
            margin: auto;
            text-align: center;
        }
    }
}

    