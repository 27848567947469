/* Basic Adjustments */
* {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: "Roboto" !important;
  letter-spacing: 0.01em;
  line-height: 1.25rem;
  background-color: #f4f6fd;
  color: #000000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.render-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.team-dashboard-container {
  position: relative;
  width: 100%;
  height: 100%; /*only because it's inside another general-container*/
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem 1rem;
  overflow-y: scroll;
}