@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.fee-view-container {
  width: 100%;
  padding: 0px 10px;
  .fee-header-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    .container-options-title {
      p {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        color: $color-primary;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
    .fee-filter-btn-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      .ant-radio-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .ant-radio-wrapper {
          span {
            font-size: $msg-font-size !important;
            font-weight: $font-weight-sm !important;
            font-family: "Roboto" !important;
            color: $color-primary !important;
          }
        }
      }
    }
  }
}
.ant-select-arrow {
  color: $color-primary !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 5px 15px 5px 15px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
  border-right-width: 0px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: $white !important;
  border: 1px solid $white !important;
  border-radius: 20px !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  color: $color-primary !important;
}
.fee-filter-btn-group button {
  margin-right: 10px;
}

.export-btn-container {
  button {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 5px 25px 5px 25px;
    background-color: $color-accent;
    border: 1px solid $color-accent;
    border-radius: 100px;
    color: $white;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
    &:hover {
      background-color: $color-accent;
      border: 1px solid $color-accent;
      color: $white;
    }
    .export-fee-icon {
      position: relative;
      width: auto;
      height: auto;
      padding: 0;
      text-align: center;

      img {
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }
  }
  .ant-btn-primary:active {
    color: $white !important;
    border-color: transparent !important;
    background-color: $color-accent;
  }
  .ant-btn-primary:focus {
    color: $white !important;
    border-color: transparent !important;
    background-color: $color-accent;
  }
}
.fee-pagination-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0px;
}
.wallet-dashboard-container-fee {
  padding: 1rem;
  background: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
}

.wallet-dashboard-container-fee-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding: 20px 25px;
}

.wallet-dashboard-container-fee-title {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #9e9dab;
  font-size: 14px;
  font-family: "Roboto";
  align-items: flex-start;
  width: 35%;
}

.wallet-dashboard-container-fee-mount {
  font-size: 23px;
  font-weight: bold;
  color: #4d4f5c;
  margin-right: initial;
}

.wallet-dashboard-container-fee-header-selections {
  display: flex;
  flex-direction: row;
  width: 183px;
  place-content: space-between;
  margin-left: auto;
}

.wallet-dashboard-container-fee-header-selections .button-period {
  font-size: 12px;
  font-family: "Roboto";
  letter-spacing: 0px;
  width: 85px;
  height: 30px;
  border-radius: 3.5px;
  background-color: #ffffff;
  border: 1px solid #d7dae2;
  color: #636470;
  cursor: pointer;
}

.wallet-dashboard-container-fee-header-selections .btn-selected {
  background-color: #3b86ff;
  border: none;
  outline: none;
  color: #ffffff;
}

.wallet-dashboard-container-fee-header-selections .btn-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.wallet-dashboard-container-fee-content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 81%;
}

.button-period:focus {
  background-color: #3b86ff;
  border: none;
  outline: none;
  color: #ffffff;
}

.fee-detail {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  padding: 0;
  margin: auto;
}

.fee-detail-container-top {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 1rem;
  place-content: space-between;
  align-items: center;
}

.fee-detail-title {
  font-size: $h5-font-size;
  color: $color-text-black;
  font-weight: $font-weight-medium;
  font-family: "Roboto";
}

.fee-detail-header {
  display: grid;
  grid-template-columns: 8% 15% 6% 11% 10% 11% 7% 8% 11% 3%;
  grid-gap: 1%;
  width: 100%;
  font-size: 10px;
  color: #a4a7be;
  text-transform: uppercase;
  text-align: center;
  background-color: #f4f6fd;
  border-radius: 10px;
  padding: 5px 0;
  margin: 0 0 3px;
  box-sizing: border-box;
  align-items: center;
}

.fee-detail{
  padding: 10px;
}
.fee-detail .table-detail-fee .ant-table-thead > tr > th {
  background: $color-primary !important;
  color: $color-background !important;
  font-family: "Roboto" !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
  font-weight: $font-weight-sm;
}
.ant-table-container table > thead > tr:first-child th:first-child{
  border-top-left-radius: 20px !important;
}
.ant-table-container table > thead > tr:last-child th:last-child{
  border-top-right-radius: 20px !important;

}

.ant-table-thead > tr > th.ant-table-cell {
  text-align: center; 
  vertical-align: middle; 
}
.fee-detail .table-detail-fee .ant-table-content .ant-table-tbody > tr > td{
  font-size: $roboto-12-medium-font-size !important;
  font-weight: $font-weight-sm !important;
  font-family: "Roboto" !important;
  color: $color-text-black !important;
}


.ant-table-tbody > tr > td {
  border-bottom: 1px solid $color-background !important;
}

.ant-table-tbody > tr > td:first-child {
  border-left: 1px solid $color-background !important;
}

.ant-table-tbody > tr:first-of-type > td {
  border-top: 1px solid $color-background !important;
}

.ant-table-tbody > tr > td:last-child {
  border-right: 1px solid $color-background !important;
}

.ant-table-tbody > tr > td {
  border-right: none !important;
}

.ant-table-tbody > tr:last-of-type > td {
  border-bottom: 1px solid $color-background !important;
}


.fee-detail .table-detail-fee .ant-table-cell {
  text-align: center !important;
}
.fee-detail .table-detail-fee td:first-child {
  width: 72px;
}
.fee-detail .table-detail-fee .ant-table-content .ant-table-tbody > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.total-fee-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #9e9dab;
}

.total-fee-container-cartera {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Roboto";
  font-size: $h5-font-size;
  color: $color-text-black;
  font-weight: $font-weight-medium;
  .total-fee-result-title {
    width: auto;
    height: auto;
    padding: 0.375rem 0;
    font-family: "Roboto";
    font-size: $large-font-size;
    color: $color-text-black;
    font-weight: $font-weight-medium;
  }
}
.fee-filter-btn-group.ant-btn.ant-btn-lg.ant-btn-primary.inactive-period-button-cartera {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 1rem;
  color: #717171;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  cursor: pointer;
}

.ant-btn.ant-btn-lg.ant-btn-primary.inactive-period-button-cartera:hover {
  color: #423994;
  background-color: #d6d8fa;
}

.ant-btn.ant-btn-lg.ant-btn-primary.selected-period-button-cartera {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 1rem;
  color: #ffffff;
  background-color: #0056ff;
  border: 1px solid #0056ff;
  border-radius: 5px;
  cursor: pointer;
}

.ant-btn.ant-btn-lg.ant-btn-primary.selected-period-button-cartera:hover {
  color: #423994;
  background-color: #d6d8fa;
}

.ant-btn.ant-btn-lg.ant-btn-primary.disabled-period-button-cartera {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 1rem;
  color: #00000029;
  background-color: #c4c4c4;
  border: 1px solid #00000029;
  border-radius: 5px;
  cursor: pointer;
}
