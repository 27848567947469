@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.wallet-dashboard-container-right {
  position: relative;
  width: 70%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
}

.wallet-dashboard-container-right-full-size {
  position: relative;
  width: 95%;
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  overflow-y: scroll;
}
.wallet-dashboard-container-right-full-size
  > .wallet-dashboard-container-right-cards
  > .wallet-card-pie-container
  > .wallet-card-pie-container-statics
  > .statistics-graph {
  left: -10px;
}
/* ************************************************ */
.wallet-dashboard-container-right-header {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  .wallet-dashboard-container-right-header-title {
    position: relative;
    width: 30%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    color: $color-secondary;
    font-size: $x-medium-font-size;
    font-weight: $font-weight-medium;
    font-family: "Roboto";
    text-transform: uppercase;
    p {
      color: $color-secondary;
      font-size: $x-medium-font-size;
      font-weight: $font-weight-medium;
      font-family: "Roboto";
      text-transform: uppercase;
      margin-bottom: 0;
    }
    span {
      color: $color-secondary;
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
      text-transform: uppercase;
    }
  }
}

/* ************************************************ */
.wallet-dashboard-container-right-header-data {
  background: $white;
  height: inherit;
  width: 70%;
  position: relative;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  padding: 15px;
  .data-client {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-around;
    padding: 10px;
    gap: 5px;
    .phone-email-container {
      border-right: 1px solid #0000000f;
      .client-phone {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        color: $color-text-black;
        font-family: "Roboto";
        margin-bottom: 0;
      }
      .client-cuit {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        color: $color-text-black;
        font-family: "Roboto";
        margin-bottom: 0;
      }
    }
    .birthdate-email-container {
      .client-email {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        margin-bottom: 0;
      }
      .client-birthdate {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        color: $color-text-black;
        font-family: "Roboto";
        margin-bottom: 0;
      }
    }
  }
}

.wallet-dashboard-container-right-header-data-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-left: 30px;
  position: relative;
}

.wallet-dashboard-container-right-header-data.left,
.wallet-dashboard-container-right-header-data.center,
.wallet-dashboard-container-right-header-data.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  color: #a4a7be;
}

.wallet-dashboard-container-right-header-data-left > div,
.wallet-dashboard-container-right-header-data-center > div,
.wallet-dashboard-container-right-header-data-right > div {
  font-size: 12px;
}

.wallet-dashboard-container-right-header-data-left-icon {
  position: absolute;
  left: -20px;
  bottom: 52px;
}

.wallet-dashboard-container-right-header-data-right-email {
  color: #5c9afe;
  text-decoration: underline;
}

.wallet-dashboard-container-right-cards {
  /* display: grid;
  grid-template-columns: 70% 29%;
  grid-gap: 10px;
  margin-bottom: 18px; */
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
}

.wallet-dashboard-container-right-containers-desplegables {
  position: relative;
  width: 100%;
  height: auto;
}

.wallet-dashboard-container-right-desplegables {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: $white;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px #00000026;
  transition: all 0.3s;
}

.wallet-dashboard-container-right-desplegables-header {
  display: grid;
  grid-template-columns: 70px 500px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 760px;
  height: 70px;
}

.wallet-dashboard-container-right-desplegables-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wallet-dashboard-container-right-desplegables-header-title {
  font-size: $h5-font-size;
  font-weight: $font-weight-medium;
  color: $color-text-black;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.wallet-dashboard-container-right-desplegables-list {
  display: none;
}

.wallet-dashboard-container-right-desplegables-list.visible-table {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 1rem 0 0;
  background-color: $white;
  display: block;
}

/* ********************************************************************** */
.wallet-dashboard-container-right-desplegables-list-element {
  &.visible-line {
    border-bottom: 1px solid #0000000f;
  }
}

.wallet-dashboard-container-right-desplegables-list-element .wd-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  line-height: 45px;

  .wInst-el-iconAmp {
    color: $color-gray;
    margin: 0;
    cursor: pointer;

    img {
      position: relative;
      top: -6px;
    }

    .disabled {
      padding: 0 16px;
      font-size: 37px;
    }
  }

  .wInst-el-title {
    font-size: $msg-font-size;
    font-weight: $font-weight-medium;
    color: $color-text-black;
    font-family: "Roboto";
    width: 62%;
  }

  .wInst-el-invActual {
    font-size: $msg-font-size;
    font-weight: $font-weight-medium;
    color: $color-text-black;
    font-family: "Roboto";
    width: 35%;
    text-align: right;
  }
}

.wallet-dashboard-container-right-desplegables-list-element .wd-element > div {
  font-size: 14px;
  height: inherit;
}

.wallet-dashboard-container-right-desplegables-list-element
  .wd-element
  .wInst-el-invInicial {
  color: $color-gray;
  width: 160px;
}

.wallet-dashboard-container-right-desplegables-list-element
  .wd-element
  .wInst-el-operar {
  color: #5c9afe;
  text-decoration: underline;
  border-bottom-color: #a4a7be;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  width: 110px;
  cursor: pointer;
}

.wallet-dashboard-container-right-desplegables-list-element .wInst-el-sublist {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .reference {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 19.3%);
    grid-gap: 2.1%;
    font-family: "Roboto";
    text-align: center;
    align-items: center;
    justify-content: end;
    color: $color-background;
    text-transform: uppercase;
    background-color: $color-primary;
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
    height: 40px;
    > div {
      text-align: center;
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-medium;
      color: $color-background;
      font-family: "Roboto";
    }
  }

  .reference-partner {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 19.3%);
    grid-gap: 2.1%;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    align-items: center;
    justify-content: end;
    color: $color-background;
    background-color: $color-primary;
    opacity: 1;
    height: 40px;
    div {
      text-align: center;
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
      text-transform: uppercase;
      color: $color-background;
    }
  }

  &.visibleSubList {
    display: flex;
  }

  .sb-element {
    display: grid;
    grid-template-columns: repeat(4, 19%);
    grid-gap: 2%;
    width: 100%;
    height: auto;
    border-top: 1px solid #0000000f;
    border-right: 1px solid #0000000f;
    border-left: 1px solid #0000000f;
    height: auto;
    div {
      display: flex;
      align-items: center;
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm;
      color: $color-text-black;
      padding: 0 2vw;
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;
    }

    .sb-desc {
      font-family: "Roboto";
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm !important;
      color: $color-text-black;
      width: auto;
    }
    &:last-child {
      border-bottom: 1px solid #0000000f;
    }
  }

  .sb-element-partner {
    display: grid;
    grid-template-columns: repeat(5, 19%);
    grid-gap: 2%;
    width: 100%;
    height: auto;
    border-top: 1px solid #0000000f;
    border-right: 1px solid #0000000f;
    border-left: 1px solid #0000000f;
    div {
      display: flex;
      align-items: center;
      padding: 0 2vw;
      text-align: center;
      text-transform: uppercase;
    }

    .sb-desc {
      text-align: left !important;
      margin: 0.25rem 0 0 0;
      padding: 0.5rem;
      font-family: "Roboto";
      font-size: $roboto-12-medium-font-size !important;
      font-weight: $font-weight-sm;
      color: $color-text-black;
      width: auto;
    }
    &:last-child {
      border-bottom: 1px solid #0000000f;
    }
  }
}

.wallet-dashboard-container-right-desplegables-list-element
  > .wInst-el-sublist.visibleSubList
  > .sb-element
  > .sb-desc {
  text-align: left !important;
  margin: 0.25rem 0 0 0;
  padding: 0.5rem;
}

.wallet-dashboard-container-right-cards2 {
  width: 98%;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #0000000d;
  margin: 0px 0px 0px 20px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.wallet-dashboard-container-right-cards2-header {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
}

.wallet-dashboard-container-right-cards2-title {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #9e9dab;
  font-size: 14px;
  font-family: "Roboto" !important;
  align-items: flex-start;
  width: 35%;
}

.wallet-dashboard-container-right-cards2-mount {
  font-size: 23px;
  font-weight: bold;
  color: #4d4f5c;
  margin-right: initial;
}

.total-fee-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Roboto" !important;
  font-size: 1rem;
  color: #9e9dab;
}

.total-fee-result-title {
  width: auto;
  height: auto;
  padding: 0.375rem 0;
  font-size: 1.75rem;
  font-weight: bold;
  color: #202124;
}

.total-fee-alert-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.total-fee-alert-icon {
  position: relative;
  width: auto;
  height: auto;
  margin-right: 0.5rem;
}

.total-fee-alert-icon > img {
  width: 2rem;
  height: 2rem;
}

.total-fee-alert-text {
  font-size: 1rem;
  color: #4d5f5c;
  opacity: 0.6;
}

.wallet-dashboard-container-right-cards2-header-selections {
  display: flex;
  flex-direction: row;
  width: 183px;
  place-content: space-between;
  margin-left: auto;
}

.wallet-dashboard-container-right-cards2-header-selections .button-period {
  font-size: 12px;
  font-family: "Roboto" !important;
  letter-spacing: 0px;
  width: 85px;
  height: 30px;
  border-radius: 3.5px;
  background-color: #ffffff;
  border: 1px solid #d7dae2;
  color: #636470;
  cursor: pointer;
}

.wallet-dashboard-container-right-cards2-header-selections .btn-selected {
  background-color: #3b86ff;
  border: none;
  outline: none;
  color: #ffffff;
}

.wallet-dashboard-container-right-cards2-header-selections .btn-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.button-period:focus {
  background-color: #3b86ff;
  border: none;
  outline: none;
  color: #ffffff;
}

.wallet-dashboard-container-right-cards2-content {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
}

/* ************************************************ */
.fee-detail {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 10px;
  padding: 20px 14px;
  margin: 20px 0px 0px 20px;
}

.fee-detail-container-top {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px 5px;
  place-content: space-between;
  align-items: center;
}

.fee-detail-header {
  display: grid;
  grid-template-columns: 8% 15% 6% 11% 10% 11% 7% 8% 11% 3%;
  grid-gap: 1%;
  width: 100%;
  font-family: "Roboto";
  font-size: 10px;
  color: $color-background;
  text-transform: uppercase;
  text-align: center;
  background-color: $color-primary;
  border-radius: 10px;
  padding: 20px 20px 0px 0px;
  margin: 0 0 3px;
  box-sizing: border-box;
  align-items: center;
}

.wallet-dashboard-container-right-fee-detail-totals {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  width: 799px;
  height: 38px;
  background: #f4f6fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.8;
}

.wallet-dashboard-container-right-fee-detail-totals-title,
.wallet-dashboard-container-right-fee-detail-totals-value {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d2e8f;
  font-size: 14px;
  font-family: "Roboto" !important;
  font-weight: 700;
}

/* ************************************************ */
/* ************************************************ */
/* CON CLIENTE SELECCIONADO CUENTA CORRIENTE */

.wallet-dashboard-container-right-cards3 {
  display: grid;
  grid-template-columns: 0.6fr 2.5fr 3fr;
  width: 814px;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #fbf08e;
  border-radius: 10px;
  opacity: 1;
}

.wallet-dashboard-container-right-cards3 > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wallet-dashboard-container-right-cards3-center-salud {
  font: Regular var(--unnamed-font-size-14) / 20px
    var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-67698b);
  text-align: left;
  /* font: Regular 14px/20px Roboto; */
  letter-spacing: 0px;
  color: #67698b;
  opacity: 1;
  width: 95%;
}

.wallet-dashboard-container-right-cards3-center-inversor {
  font: Regular 13px/23px var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  /* font: Regular 13px/23px Roboto; */
  letter-spacing: 0px;
  color: #b6b4d9;
  opacity: 1;
  width: 95%;
}

.wallet-dashboard-container-right-cards3-right {
  /* font: Regular 13px/23px var(--unnamed-font-family-roboto); */
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  /* font: Regular 13px/23px Roboto; */
  letter-spacing: 0px;
  color: #b6b4d9;
  opacity: 1;
  width: 95%;
}

.wallet-dashboard-container-right-table3 {
  background: #ffffff;
  border-radius: 10px;
  padding: 14px;
}

.wallet-dashboard-container-right-table3-data {
  width: 100%;
  height: auto;
}

.wallet-dashboard-container-right-table3-data::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background: transparent;
}

.wallet-dashboard-container-right-table3-data::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  box-shadow: inset 0 0 3px #687ec7;
}

.wallet-dashboard-container-right-table3-data:hover::-webkit-scrollbar {
  background: transparent;
}

.wallet-selections-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  margin-bottom: 5px;
}

.wallet-selections-container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 2%;
}

.wallet-selections-container-title {
  text-align: left;
  font: Bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #2d2e8f;
  opacity: 1;
}

.wallet-header-data-container {
  display: grid;
  grid-template-columns: 11% 11% 11% 20% 11% 10% 10% 10%;
  grid-gap: 1%;
  width: 100%;
  font-family: "Roboto" !important;
  font-size: 10px;
  color: #a4a7be;
  text-transform: uppercase;
  text-align: center;
  background-color: #f4f6fd;
  border-radius: 10px;
  padding: 5px;
  margin: 0 0 12px;
  box-sizing: border-box;
}

.wallet-header-data-container .icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wallet-header-data-container .icon > img {
  cursor: pointer;
}

.wallet-header-data-container .date {
  width: 75px;
}

.wallet-header-data-container .client {
  width: 200px;
}

.wallet-header-data-container .account {
  width: 110px;
}

.wallet-header-data-container .tLiquida {
  width: 140px;
}

.wallet-header-data-container .detail {
  width: 150px;
}

.wallet-header-data-container .currency {
  width: 110px;
}

.wallet-header-data-container .amount {
  width: 110px;
  color: #a4a7be;
}

.wallet-header-data-container .state {
  width: 130px;
}

.download-csv {
  position: absolute;
  right: -20px;
  top: 7px;
  margin: 0px 10px;
}

.show-modal {
  display: block;
}

.hide-modal {
  display: none;
}

.ticket-preview-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000001a;
  .ticket-preview-container {
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    max-width: 940px;
    max-height: 80vh;
    background: $white;
    box-shadow: 0px 2px 8px 0px #00000026;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ticket-preview-wrapper {
  position: relative;
  padding: 25px 0;

  .btn-close {
    text-align: right;
    margin-bottom: 22px;
  }
}

.ticket-preview {
  .text-button {
    background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
      width: 100%;
      border-radius: 100px;
      border: 1px solid $color-primary;
      padding: 5px 25px 5px 25px;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
      font-size: $roboto-12-medium-font-size;
      text-align: center;
      color: $color-primary;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
      }
      &:active {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
        border-color: transparent;
      }
      &:focus {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
        border-color: transparent;
      }
  }
  .download-button {
    cursor: pointer;
    background-color: $color-accent;
    color: $white;
    width: 100%;
    border-radius: 100px;
    border: 1px solid $color-accent;
    padding: 5px 25px 5px 25px;
    font-family: "Roboto";
    font-weight: $font-weight-sm;
    font-size: $roboto-12-medium-font-size;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $color-accent;
      color: $white;
    }
    &:active {
      background-color: $color-accent;
      color: $white;
      border-color: transparent;
    }
    &:focus {
      background-color: $color-accent;
      color: $white;
      border-color: transparent;
    }
  }
  .download-pdf {
    cursor: pointer;
    background-color: $color-primary;
    color: $white;
    width: 100%;
    border-radius: 100px;
    border: 1px solid $color-primary;
    padding: 5px 25px 5px 25px;
    font-family: "Roboto";
    font-weight: $font-weight-sm;
    font-size: $roboto-12-medium-font-size;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $color-primary;
      color: $white;
    }
    &:active {
      background-color: $color-primary;
      color: $white;
      border-color: transparent;
    }
    &:focus {
      background-color: $color-accent;
      color: $white;
      border-color: transparent;
    }
  }
}
.icon-arrow-angle-expand {
  cursor: pointer;
  color: $color-primary !important;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  margin: 0;
  padding: 1rem;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item-active {
  border-color: $color-primary !important;
}
.ant-pagination-item {
  border-radius: 30px !important;
  border: 1px solid $color-gray;
}
.ant-pagination-item a {
  color: $color-primary !important;
}
.ant-pagination-item:hover {
  color: $color-primary !important;
  border-color: $color-primary !important;
}
.ant-pagination-item-active a {
  color: $color-primary !important;
  border-color: $color-primary !important;
}

.ant-pagination-item-active:hover {
  border-color: $color-primary !important;
}

.ant-pagination-item-active a:hover {
  color: $color-primary !important;
}
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 30px !important;
  border: 1px solid $color-gray;
}
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 30px !important;
  border: 1px solid $color-gray;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: $color-primary !important;
}
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $color-primary !important;
  border-color: $color-primary !important;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: $color-gray !important;
}
.fee-detail .table-detail-fee .ant-table-content .ant-table-tbody > tr > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.8rem !important;
}

.wallet-dashboard-container-right .filters,
.wallet-dashboard-container-right-full-size .filters {
  position: relative;
  width: 100%;
  height: auto;
  background-color: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  padding: 20px;
}

.vertical-separator {
  position: relative;
  width: 100%;
  height: 1rem;
  padding: 0;
}
.ant-picker-range .ant-picker-clear{
  right: 5px !important;
  background-color: #F4F6Fc !important;
}
  .download-pdf {
    background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
      width: 100%;
      border-radius: 100px;
      border: 1px solid $color-primary;
      padding: 5px 25px 5px 25px;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
      font-size: $roboto-12-medium-font-size;
      text-align: center;
      color: $color-primary;
      &:hover {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
      }
      &:active {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
        border-color: transparent;
      }
      &:focus {
         background: linear-gradient(0deg, #d6d9f7, #d6d9f7),
      linear-gradient(0deg, #ebebfd, #ebebfd);
        color: $color-primary;
        border-color: transparent;
      }
  }
  .download-excel {
    cursor: pointer;
    background-color: $color-accent;
    color: $white;
    width: 100%;
    border-radius: 100px;
    border: 1px solid $color-accent;
    padding: 5px 25px 5px 25px;
    font-family: "Roboto";
    font-weight: $font-weight-sm;
    font-size: $roboto-12-medium-font-size;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $color-accent;
      color: $white;
    }
    &:active {
      background-color: $color-accent;
      color: $white;
      border-color: transparent;
    }
    &:focus {
      background-color: $color-accent;
      color: $white;
      border-color: transparent;
    }
  }
