.component-time-out-modal-general-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 57.5px;
  z-index: 5;
  background-color: rgba(2, 29, 135, 0.19);
}
  
.show-modal-time-out {
  display: block
}
  
.hide-modal-time-out {
  display: none
}
  
.component-time-out-modal-container {
  position: relative;
  width: 465px;
  height: 392px;
  background: white;
  margin: 0 auto;
  top: calc(40vh - 150px);
  box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 25%);
}
  
.component-time-out-modal-wrapper {
  position: relative;
  padding: 4% 4.5% 4% 4.5%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}
  
.component-time-out-modal-x-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.component-time-out-modal-close-icon {
  width: 17px;
  height: 17px;
  cursor: pointer;
  opacity: .8;
}

.component-time-out-modal-close-icon:hover {
  opacity: 1;
} 

.component-time-out-modal-title-container {
  text-align: center;
  padding: 1.5% 0% 0% 0%;
}

.component-time-out-modal-title {
  font-family: "Roboto" !important;
  font-size: 26px;
  font-weight: bold;
  color: #040560;
}

.component-time-out-modal-description-container {
  text-align: center;
  width: 270px;
  margin: auto;
  padding: 5% 0% 7% 0%;
}

.component-time-out-modal-description {
  font-family: "Roboto" !important;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2D2E8F;
  opacity: 1;
}

.component-time-out-modal-byma-description-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #F4F6FD 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
}

.component-time-out-modal-byma-description {
  font: normal normal 600 14px/22px Segoe UI;
  letter-spacing: 0px;
  color: #2D2E8F;
  opacity: 1;
}

.component-time-out-modal-time-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #e7ca04 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
}

.component-time-out-modal-calendar-container {
  position: relative;
  display: flex;
  padding: 0% 4.5% 0% 0%;
}

.component-time-out-modal-calendar-icon {

}

.component-time-out-modal-time {
  text-align: center;
  font: normal normal bold 14px/32px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0% 2.5% 0% 0%;
}

.component-time-out-modal-prop-time {
  text-align: center;
  font: normal normal normal 12px/16px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.component-time-out-modal-confirmation-container {
  padding: 12% 0% 0% 0%;
}

.component-time-out-modal-confirmation-description {
  position: relative;
  display: flex;
  width: 210px;
  height: 43px;
  margin: 0 auto;
  background: #423994;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 14px;
  color: white;
  cursor: pointer;
}