@import "../../../../../scss/colors.scss";
@import "../../../../../scss/fonts.scss";

.custom--search--clean--container-cartera {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $color-gray;
  height: 33px;
  background-color: $white;
  color: $color-gray;
  border-radius: 62px;
  padding: 5px 15px 5px 15px;
  &.no-empty {
    border: 1px solid $color-primary;
    background-color: transparent !important;
  }
  .custom--search--clean--input {
    border: none;
    width: 80%;
    outline: none;
    background-color: transparent !important;
    font-size: $msg-font-size;
    color: #00000040;
    font-weight: $font-weight-medium;
    font-family: "Roboto";
    &::placeholder {
      font-size: $msg-font-size;
      color: #00000040;
      font-weight: $font-weight-medium;
      font-family: "Roboto";
    }
  }
  .custom--search--clean--icon {
    text-align: center;
    img {
      position: relative;
      cursor: pointer;
    }
  }
  .input--small {
    width: 100px;
  }

  .input--medium {
    width: 200px;
  }

  .input--large--100 {
    width: 250px;
  }
}
