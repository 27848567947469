/* * {
  font-family: "Roboto" !important !important;
} */

.treasury-container {
  margin: 25px 50px 70px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding-bottom: 100px;
}

.treasury-overview-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0px;
  font-family: "Roboto" !important;
}

.tabs-card-container > .tabs-header > .tab-button {
  font-family: "Roboto" !important;
}

.tabs-card-container > .tabs-header > .tab-button:hover {
  cursor: pointer;
}

.treasury-buttons-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding-left: 10px;
  font-family: "Roboto" !important;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}