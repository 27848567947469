.bi-content {
  position: relative;
  width: 100%;
  height: 175px;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 6%);
  border-radius: 5px;

  .bi-title {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: block;
    color: #2d2e8f;
    font-family: "Roboto" !important;
    font-size: 1rem;
    font-weight: bold;
  }
}

  /* .bi-items-content {
    width: 100%;
    height: 30%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    .data-item {
      width: 30%;
      height: auto;
      border: 1px solid #54d8ff;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 5px;
      text-align: center;
      box-shadow: 3px 3px 10px #01155729;

      h4 {
        font-family: "Roboto Regular";
        font-size: 12px;
        color: #a4a7be;
        margin-bottom: 5px;
      }

      span {
        font-family: "Roboto Bold";
        font-size: 24px;
        color: #737dff;

        &.green {
          color: #0ab8b2;
        }
      }
    }
  }
} */

.bi-items-content {
  position: relative;
  width: 100%;
  height: 100%; /*this is for mantain the equal height under child elements*/
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;
}

.bi-item {
  position: relative;
  width: 33%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem 1rem;
  border-right: 2px solid #ACACAC;
}

.bi-item:last-child {
  border-right: 0;
}

.bi-item-title {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #ACACAC;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  font-weight: bold;
}

.bi-item-value {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #4D4D4D;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: bold;
}