$black: #101010;
$white: #ffffff;
$light-gray: #a4a7be;
$light-gray-2: #a6adb4;
$mid-gray: #67698b;
$dark-gray: #4d4f5c;
$light-gray-blue: #d9daf5;
$light-gray-blue-2: #d5d9f0;
$light-blue: #aec9f5;
$mid-blue: #737dff;
$dark-blue: #423994;
$darkest-blue: #01043b;
$bright-green: #11d4cd;
$green: #0ab8b2;
$red: #e80451;


$color-primary: #7381F7;
$color-primary-light: #D6D9F7;
$color-secondary-light: #EBEBFD;
$color-secondary: #3F3D8F;
$color-accent: #52B5B1;
$color-background: #F4F6Fc;
$color-text: #000000;
$color-text-black: #252525;
$color-gray: #AAAAAA;
$color-light-gray: #F0F0F0;
$color-error: #F5222D;
$color-label: #ABABAB;
$color-success: #52B5B1;
$color-success-lime: #52C41A;