@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.operate-detail-especie2-container {
  position: relative;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  overflow-y: scroll;
  background-color: $white;
  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .option {
      text-align: center;
      cursor: pointer;
      padding-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      .option-text {
        font-size: $roboto-12-medium-font-size;
        font-family: "Roboto";
        font-weight: $font-weight-sm;
        color: $color-text-black;
        padding: 0;
        margin: 0;
      }
      &.selected {
        z-index: 1;
        border-radius: 100px;
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }
  .operate-detail-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem;
    .title {
      .title-text {
        font-size: $h5-font-size;
        font-weight: $font-weight-medium;
        font-family: "Roboto";
        color: $color-text-black;
        margin: 0;
        padding: 0;
      }
    }
    .subtitle {
      .subtitle-text {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        color: $color-text-black;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.operate-detail-especie2-container.active-detail-card {
  display: block;
}

/* ****************************************************** */
.operate-detail-especie2-container .detail-especie2-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 75%;
}

.operate-detail-especie2-container .title {
  color: $color-text-black;
  font-family: "Roboto";
  font-weight: $font-weight-medium;
  font-size: $h5-font-size;
}
.operate-detail-especie2-container .subtitle {
  color: $color-text-black;
  font-family: "Roboto";
  font-weight: $font-weight-sm;
  font-size: $msg-font-size;
}

.operate-detail-especie2-container .detail-especie2-info .statics {
  width: 100%;
  text-align: center;
  letter-spacing: 0px;
  color: #101010;
  opacity: 1;
}

.operate-detail-especie2-container .detail-especie2-info .statics > img {
  width: 100%;
}

.operate-detail-especie2-container .detail-especie2-info .statics .months {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 10px;
}

/* ****************************************************** */

.operate-detail-especie2-container .detail-especie2-info .mini-gray-card2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.operate-detail-especie2-container
  .detail-especie2-info
  .mini-gray-card2
  > div {
  height: 72px;
  width: 134px;
  background: #f0f3fa;
  border-radius: 10px;
  opacity: 1;
  margin-right: 15px;
}

.mini-gray-card2 .result-mini-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.mini-gray-card2 .result-mini-card .title {
  text-align: center;
  font-family: "Roboto" !important;
  font-weight: normal;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.8;
  font-size: 14px;
}

.mini-gray-card2 .result-mini-card .value {
  text-align: center;
  font-size: 26px;
  font-family: "Roboto" !important;
  letter-spacing: 0px;
  color: #737dff;
  opacity: 0.8;
}

.detail-especie2-info .data {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.detail-especie2-info .data .element-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
}

.detail-especie2-info .data .element-data .field {
  color: $color-gray;
  font-size: $roboto-12-medium-font-size;
  font-family: "Roboto";
  font-weight: $font-weight-sm;
}

.detail-especie2-info .data .element-data .value {
  color: $color-text-black;
  font-size: $roboto-12-medium-font-size;
  font-family: "Roboto";
  font-weight: $font-weight-bold;
}

.detail-especie2-info .horizontal-data {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}

.detail-especie2-info .horizontal-data div {
  width: 41.75%;
  padding: 0 10px;
}

.operate-detail-data-color-element {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
}

.operate-detail-data-first {
  border-radius: 20px;
  padding: 5px 25px 5px 25px;
  background: rgba(82, 181, 177, 0.1);
  border: 1px solid $color-accent;
  width: 200px;
  .data-color-element-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .data-venta-title, .data-venta-value{
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm;
      color: $color-accent;
      font-family: "Roboto";
  
    }
  }
}

.operate-detail-data-second {
  border-radius: 20px;
  padding: 5px 25px 5px 25px;
  background: rgba(255, 77, 79, 0.1);
  border: 1px solid $color-error;
  width: 200px;
  .data-color-element-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .data-venta-title, .data-venta-value{
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm;
      color: $color-error;
      font-family: "Roboto";
  
    }
  }
}




.btn-operate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.operate-detail-especie2-container > .container {
  width: 100% !important;
}
