@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

:root {
  --primary: #4628ff;
  --blue: #01043b;
  --aqua: #423894;
  --default: #0056ff;
  --active: #52B5B1;
  --hover: #d6d6d6;
  --white: #ffffff;
}

/* *************************************** */

.btn {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: $white;
  border-radius: 25px;
  cursor: pointer;
  font-size: $roboto-12-medium-font-size;
  outline: none;
  margin: 0px 10px;
}

.btn.btn-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

/* STYLE PRIMARY BUTTONS */
.btn--primary {
  background: $color-primary;
  color: $color-background;
  border: none;
  padding: 10px 0px !important;
}

.btn--primary--angost {
  background: var(--aqua);
  color: var(--white);
  border: 1px solid var(--aqua);
  padding: 8px 25px;
}

.btn--primary:hover {
  background: $color-primary;
  color: $color-background;
}

.btn--primary:active {
  background: $color-primary;
  color: $color-background;
}

.btn--primary--angost:active {
  background: #00fff6;
  color: var(--aqua);
}

.btn--medium {
  width: 180px;
}

.btn--small {
  font-size: 12px;
}

/* STYLE SECONDARY BUTTONS */
.btn--secondary {
  background: var(--white);
  color: var(--primary);
  border: 2px solid var(--primary);
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn--secondary:hover {
  background: var(--white);
  color: #01043b;
  border: 2px solid #01043b;
}

.btn--secondary:active {
  background: var(--active);
  color: var(--aqua);
  border: 2px solid var(--primary);
}

/* STYLE THIRD BUTTONS */
.btn--third--solid {
  background: #423994;
  color: white;
  border: 1px solid #423994;
  padding: 8px 12px;
  font-size: 11px;
}

.btn--third--solid:hover {
  background: #01043b;
  border: 1px solid #01043b;
}

.btn--third--solid:active {
  background: #00fff6;
  color: #423994;
  border: 1px solid #00fff6;
}

.btn--third--outline {
  background: white;
  color: #423994;
  border: 1px solid #423994;
  padding: 8px 12px;
  font-size: 11px;
}

.btn--third--outline:hover {
  background: white;
  color: #01043b;
  border: 1px solid #01043b;
}

.btn--third--outline:active {
  background: #00fff6;
  color: #423994;
  border: 1px solid #423994;
}
