@import "../../../scss/colors.scss";
@import "../../../scss/fonts.scss";

.full-period-picker {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  gap: 15px;
  .period-picker-left {
    background-color: $color-background;
    padding: 5px 20px 5px 25px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    font-family: "Roboto";
  }
  .period-picker-right {
    display: flex;
    font-size: $msg-font-size;
    font-weight: $font-weight-medium;
    font-family: "Roboto";
    color: $color-primary;
    background-color: $color-background;
    border-radius: 20px;
    gap: 10px;
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      border-radius: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      min-width: 120px;
      text-align: center;
      .ant-select-selection-search {
        font-size: $msg-font-size;
        font-weight: $font-weight-sm;
        color: $color-primary;
        font-family: "Roboto";
        > input {
          font-size: $msg-font-size;
          font-weight: $font-weight-sm;
          color: $color-primary;
          font-family: "Roboto";
        }
      }
    }
    .ant-select-arrow {
      color: $color-primary;
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
    }
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
  border-right-width: 0px !important;
  border: none !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: $color-primary !important;
}
.fund-compare-modal
  > .alternative-modal
  > .alternative-modal-content
  > .compare-modal-body
  > .full-period-picker
  > .period-picker-left
  > .ant-picker {
  color: $color-primary !important;
  font-size: $msg-font-size;
  font-weight: $font-weight-sm;
  font-family: "Roboto";
  border: none;
  background-color: transparent;
  gap: 25px;
  &:hover {
    border: none;
  }
}
.ant-picker {
  border: none !important;
}
.ant-picker-input > input {
  color: $color-primary !important;
  font-size: $msg-font-size;
  font-weight: $font-weight-sm;
  font-family: "Roboto";
  border: none;
  background-color: transparent;
  &::placeholder {
    color: $color-primary !important;
    font-size: $msg-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
  }
}
.ant-picker-input > input:hover {
  border: none !important;
  border-right-width: 0 !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border: none !important;
  box-shadow: none !important;
  outline: 0;
}
.ant-picker-focused {
  box-shadow: none !important;
}
.ant-picker-active-bar {
  display: none;
}
.ant-picker:hover,
.ant-picker-focused {
  border: none !important;
}
.ant-picker-range-separator > .ant-picker-separator {
  color: $color-primary !important;
  font-size: $msg-font-size;
}
.ant-picker-suffix {
  color: $color-primary !important;
  font-size: $msg-font-size;
}

.ant-select-dropdown {
  background-color: $white !important;
  padding: 5px 12px 5px 12px !important;
  box-shadow: 0px 9px 28px 8px #0000000d !important;
  box-shadow: 0px 6px 16px 0px #00000014 !important;
  box-shadow: 0px 3px 6px -4px #0000001f !important;
  border-radius: 2px 2px 20px 20px !important;
}
.ant-select-item-option-content {
  color: $color-primary !important;
  font-weight: $font-weight-sm !important;
  font-size: $msg-font-size !important;
  font-family: "Roboto" !important;
  white-space: nowrap !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $color-primary !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $color-primary !important;
  font-weight: $font-weight-sm !important;
  background-color: $color-background !important;
  font-size: $msg-font-size !important;
  font-family: "Roboto" !important;
  padding: 5px 12px 5px 12px !important;
}
.ant-picker-panel-container {
  border-radius: 20px !important;
  box-shadow: 0px 2px 8px 0px #00000026 !important;
}
.ant-picker-header {
  color: $color-primary !important;
  font-family: "Roboto" !important;
  font-weight: $font-weight-sm;
  font-size: $msg-font-size;
  button {
    span {
      color: $color-primary !important;
      font-family: "Roboto" !important;
      font-weight: $font-weight-medium;
      font-size: $msg-font-size;
    }
  }
}
.ant-picker-content {
  thead {
    tr {
      th {
        color: $color-text-black !important;
        font-family: "Roboto" !important;
        font-weight: $font-weight-medium !important;
        font-size: $msg-font-size !important;
      }
    }
  }

  tbody {
    tr {
      .ant-picker-cell-in-view {
        color: $color-primary !important;
        font-family: "Roboto" !important;
        font-weight: $font-weight-medium !important;
        font-size: $msg-font-size !important;

        &.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-radius: 20px !important;
          padding: 1px !important;
          border: 1px solid $color-primary !important;
        }
        &.ant-picker-cell-today .ant-picker-cell-inner {
          color: $white !important;
          background-color: $color-primary !important;
          border-radius: 20px !important;
          border: 1px solid $color-primary !important;
        }

        &.ant-picker-cell-selected .ant-picker-cell-inner,
        &.ant-picker-cell-range-start .ant-picker-cell-inner,
        &.ant-picker-cell-range-end .ant-picker-cell-inner {
          background-color: $color-primary !important;
          border-radius: 20px !important;
          padding: 1px !important;
          color: $white !important;
        }

        &.ant-picker-cell-in-range::before {
          background: $color-background !important;
        }

        &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
        &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
          background-color: $color-background !important;
        }

        &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
          .ant-picker-cell-inner {
          border-radius: 20px 0 0 20px !important;
        }

        &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
          .ant-picker-cell-inner {
          border-radius: 0 20px 20px 0 !important;
        }
      }
    }
  }
}
