@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

:root {
  --green: #0ab8b2;
  --red: #e80451;
  --bright-blue: #737dff;
  --hover1: #e7ebf8;
  --default: #0056ff;
  --default: #423994;
  --white: #fff;
  --black1: #67698b;
}

.card--footer--portfolio {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  &.card--footer--portfolio--green {
    .card-profile-description {
      background-color: $color-success;
      color: $white;
    }
  }

  &.card--footer--portfolio--blue {
    .card-profile-description {
      background-color: $color-primary;
      color: $white;
    }
  }
  &.portfolio-fee-section {
    .card-profile-description {
      background-color: $color-light-gray;
      font-size: $msg-font-size;
      font-weight: $font-weight-medium;
      color: $color-text-black;
    }
  }
  // &.card--footer--portfolio--blue {
  //   background: var(--bright-blue);
  //   color: var(--white);
  // }

  // &.card--footer--portfolio--hover {
  //   background: var(--hover1);
  //   color: var(--black1);
  // }
}
.card-profile-amount {
  .profile-amount-values,
  .equivalency-container {
    font-size: $footnote-description-font-size;
    font-weight: $font-weight-medium;
    color: $color-text-black;

    .tooltip-wrapper {
      margin: 0 0 0 5px;
    }
    .card-profile-usd-equivalency {
      font-size: $footnote-description-font-size;
      color: $color-text-black;
      font-weight: $font-weight-medium;
    }
  }
}
.avoid-view {
  color: transparent;
  background: transparent;
}

.card-profile-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  left: 10px;
  font-size: 12px;
}

.card-profile-link-url {
  padding-bottom: 2px;
  text-decoration: underline;
  color: rgb(39, 45, 128);
  margin: 0px 10px;
}
