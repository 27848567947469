/* .button-team-top-container {
  position: relative;
  display: inline-flex;
  width: 260px;
  height: 68px;
  background: white;
  align-items: center;
  font-family: "Roboto";
  font-weight: bold;
  border: 2px solid #737dff !important;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 12px;
}

.button-team-top-img-container {
  position: relative;
  display: flex;
}

.button-team-top-img {
  width: 45px;
  margin: 0 19px 0 19px;
  cursor: default;
}

.button-team-top-title{
  text-align: start;
} */

.button-report-bill {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
  color: #423894;
  background-color: #ffffff;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: normal;
}

.button-report-bill:hover {
  cursor: pointer;
  background-color: #D6D8FA;
}