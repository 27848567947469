@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.no-statics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  .no-movements {
    font-weight: $font-weight-sm;
    font-size: $h5-font-size;
    text-align: center;
    color: $color-text-black;
    margin: 0 auto;
    padding: 0;
    font-family: "Roboto";
  }
  img {
    width: 80px;
    height: 80px;
  }
}
