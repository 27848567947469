@import "../../../../index.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.detail-fund-especie-container {
  position: relative;
  width: 100%;
  padding: 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow-y: scroll;
  box-shadow: 0px 2px 8px 0px #00000026;
  background-color: $white;
  .tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    .option {
      text-align: center;
      cursor: pointer;
      padding: 5px 15px 5px 15px;
      .option-text {
        color: $color-text-black;
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        padding: 0;
        margin: 0;
      }
      &.selected {
        color: $color-primary;
        border: 1px solid #7381f7;
        border-radius: 100px;
      }
    }
  }
  .detail-fund-especie-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .detail-fund-especie-info-first,
    .detail-fund-especie-info-second {
      width: 100%;
    }
    .detail-fund-especie-content-wallet {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .especie {
    text-align: left;

    letter-spacing: 0px;
    color: var(--mid-gray);
    opacity: 1;
    width: 100%;
    .especie-ticker {
      padding: 15px 0px 0px;
      font-family: "Roboto";
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-medium;
      color: $color-text-black;
    }
    .especie-data {
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      padding: 0px 0px 15px;
      color: $color-text-black;
      font-family: "Roboto";
      .especie-data-percentage-positive {
        color: var(--green);
      }
      .especie-data-percentage-negative {
        color: var(--red);
      }
    }
    .especie-data-price,
    .especie-data-percentage-normal {
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      color: $color-text-black;
      font-family: "Roboto";
    }
  }

  .statics {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0.6rem/1.5rem;
    letter-spacing: 0px;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 95%;
      padding-right: 8px;
    }

    .months {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      font-size: 10px;
      padding: 10px 10px 0px 0px;
    }
  }

  .data-cotization,
  .data-cotization-rendimientos {
    width: 100%;
    padding: 15px 0px;

    h3 {
      padding: 15px 0px 15px;
    }

    .data-cotization-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      height: 25px;
      .description {
        color: $color-gray;
        font-family: "Roboto";
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
      }

      .value {
        color: $color-text-black;
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-bold;
        font-family: "Roboto";
      }
    }

    .apply-border-bottom {
      border-bottom-color: #0000000f;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      height: 30px;
    }
  }

  .portfolio {
    width: 100%;

    .portfolio-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 97%;
      height: 30px;
      border-bottom: 1px solid #0000000f;
      .point-color {
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }

      .description {
        font-size: $roboto-12-medium-font-size;
        font-weight: $font-weight-sm;
        font-family: "Roboto";
        width: 100%;
        padding-left: 10px;
        color: $color-text-black;
      }

      .percentage {
        font-size: $roboto-12-medium-font-size;
        font-family: "Roboto";
        color: $color-text-black;
        font-weight: $font-weight-bold;
      }
    }
  }

  .especie-data-text {
    padding: 20px 0px;
    text-align: left;
    width: 100%;
    .especie-text {
      padding: 0.5rem 0;
      color: $color-text-black;
      font-size: $roboto-12-medium-font-size;
      font-family: "Roboto";
      font-weight: $font-weight-sm;
    }
    .especie-detail-subtitle {
      font-size: $msg-font-size;
      font-weight: $font-weight-medium;
      font-family: "Roboto";
      color: $color-text-black;
      border-bottom: 0;
      padding: 0;
      .especie-detail-subtitle-text {
        font-size: $msg-font-size;
        font-weight: $font-weight-medium;
        font-family: "Roboto";
        color: $color-text-black;
        padding: 0;
        margin: 0;
      }
    }
  }

  h3 {
    color: #4d4f5c;
    font-size: 14px;
    padding: 15px 0px 0px;
  }

  .detail-fund-expecie-line-header {
    position: absolute;
    padding-left: 5px;
    top: 36px;
    left: 10px;
    height: 0.5px;
    width: 90%;
    color: var(--light-gray-blue);
    background: var(--light-gray-blue);
  }
}

.statics > canvas {
  position: relative;
  width: 200px !important;
  height: 200px !important;
  padding: 0;
  margin: auto;
}
