@import "../../../../../scss/colors.scss";
@import "../../../../../scss/fonts.scss";

.wallet-card-pie-container {
  min-height: 310px;
  position: relative;
  width: 100%;
  height: auto;
  background: $white;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 20px;
  .wallet-card-pie-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    border-bottom: 1px solid #0000000f;
    margin: 0 30px 0 20px;
    .wallet-card-pie-container-header-title {
      font-family: "Roboto";
      padding: 18px 0 12px;
      color: $color-text-black;
      font-size: $h5-font-size;
      font-weight: $font-weight-medium;
    }
    .wallet-card-pie-container-types {
      padding: 24px 0 10px 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.wallet-card-pie-container-types-option {
  display: flex;
  align-items: center;
  justify-content: center;
  .wallet-card-pie-container-types-option-text{
    font-family: "Roboto";
    color: $color-text-black;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    cursor: pointer;
    text-align: center;
    padding: 5px 15px 5px 15px;
    background-color: transparent;
    &.selected {
      border: 1px solid #7381F7;
      border-radius: 100px;
      color: $color-primary;
    }
  }
}

.wallet-no-holding-container {
  display: flex;
  margin: 10px 0px 0px 20px;
  width: calc(100% - 20px);
  height: 9em;

  img {
    cursor: default;
    align-self: center;
  }
}

.wallet-empty-doughnut-container {
  width: 28%;
}

.wallet-empty-doughnut-img {
  width: 84%;
}

.wallet-warning-message-container {
  display: flex;
  width: 72%;
  text-align: left;
  font-size: 15px;
  font-family: Roboto Regular;
  font-weight: normal;
  letter-spacing: 0px;
  color: #919191;
  opacity: 1;
  align-self: flex-end;
  justify-content: center;
}

.wallet-warning-message-img {
  width: 7%;
  height: 14%;
  margin-right: 12px;
}
