:root {
    --default-btn: #737DFF;
    --white: #ffffff;
    --dark-text: #26263F;
}

.base-button {
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Roboto Bold";
    padding: 15px 30px 15px 5px;
    border-radius: 10px;
    margin: 5px;
    h1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        max-width: 120px;
    }
    img {
        width: 45px;
        height: auto;
        object-fit: none;
        margin: 0 19px 0 19px;
    }
}

.normal-button {
    background: var(--default-btn);
    color: var(--white);
    border: 1px solid var(--white);
}

.outline-button {
    background: var(--white);
    color: var(--dark-text);
    border: 2px solid var(--default-btn);
}

