.component-fee-chart-general-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  outline: none;
  font-family: "Roboto" !important;
  font-size: 0.8em;
  font-weight: normal;
}

.component-fee-chart-month-select-container {
  position: relative;
  width: 25%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  /* box-shadow: 0px 3px 6px #00000029; */
}

.component-fee-chart-month-selection-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem;
}

.component-fee-chart-month-arrow-container {
  position: relative;
  width: 1.5rem;
  height: auto;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
  cursor: pointer;
}

.component-fee-chart-month-arrow-container > img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.component-fee-chart-month-option-list-container {
  position: absolute;
  width: 25%;
  height: 50%;
  padding: 0.25rem;
  top: 2.375rem;
  right: auto;
  bottom: auto;
  left: 0;
  border: 1px solid #d7dae2;
  border-radius: 5px;
  overflow-y: auto;
}

.hide-dropdown {
  display: none;
}

.component-fee-chart-month-option-list {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.25rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  /* box-shadow: 0px 3px 6px #00000029; */
}

.component-fee-chart-month-item {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem 0.75rem;
  display: block;
  background-color: #ffffff;
  color: #737dff;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  font-weight: normal;
  cursor: pointer;
  border-radius: 5px;
}

.component-fee-chart-month-item:hover {
  background-color: #2d2e8f;
  color: #ffffff;
}