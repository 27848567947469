@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.performance {
  h3 {
    padding: 10px 0;
  }
}

.especie-detail-subtitle,
.bonos-detail-subtitle {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  font-size: $msg-font-size;
  font-weight: $font-weight-medium;
  font-family: "Roboto";
  color: $color-gray;
  border-bottom: 1px solid #0000000f;
}
