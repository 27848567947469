@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

:root {
  --strip-wallet: #f6f6f6;
  --date: #707070;
  --card-bg: #ffffff;
  --blue: #737dff;
  --grey: #a4a7be;
  --active-blue: #3b86ff;
  --idle-grey: #e9e9f0;
}

.checking-account {
  width: 100%;

  .checking-account-tabs-card-container {
    margin: 0px 10px;
    border-radius: 20px;
    background: $white;
    box-shadow: 0px 2px 8px 0px #00000026;
  }

  .checking-account-tabs-header {
    display: flex;
    flex-direction: row;
    padding: 25px 15px 5px 15px;
    .custom-filters {
      background-color: $color-background !important;
      border-radius: 20px !important;
      padding-right: 25px;
      .ant-picker {
        background-color: $color-background !important;
        border-radius: 20px !important;
        .ant-picker-input > input {
          font-size: $msg-font-size !important;
          font-weight: $font-weight-sm !important;
          font-family: "Roboto" !important;
          color: $color-primary !important;
        }
      }
    }
  }

  .checking-account-tab-button {
    padding: 15px;
    margin-top: 10px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: var(--grey);
  }

  .checking-account-tab-button.active {
    color: var(--blue);
    border-bottom: 2px solid var(--active-blue);
  }

  .checking-account-tabs-card-body {
    padding: 20px;
    .ant-pagination-item-active {
      a {
        color: $white !important;
      }
    }
    .ant-table-footer {
      button {
        span {
          color: $color-primary !important;
          font-size: $msg-font-size !important;
          font-weight: $font-weight-sm !important;
          font-family: "Roboto";
          svg {
            fill: $color-primary !important;
            color: $color-primary !important;
          }
        }
      }
    }
  }

  .filter-dropdown {
    position: absolute;
    top: 100%;
    left: -10px;
    margin-top: 14px;
    z-index: 1;
    background: #fff;
    border: 1px solid #5c9afe;
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1);
    ul {
      width: 150px;
      overflow: hidden;
    }
    li {
      font-size: 14px;
      padding: 15px 20px;
      color: #a4a7be;
      text-align: left;
    }
    li:nth-child(even) {
      border-top: 1px solid #4d4d4d26;
      border-bottom: 1px solid #4d4d4d26;
    }
    li:last-child {
      border-bottom: 0px;
    }
  }

  .form-control-input {
    color: $color-primary !important;
    .css-1s2u09g-control {
      border-radius: 20px;
      outline: none;
      padding: 5px 15px;
      background-color: $color-background;
      border: none;
      height: 35px;
      margin: 0px 5px;
      min-width: 140px;
      font-size: $msg-font-size !important;
      font-weight: $font-weight-sm !important;
      font-family: 'Roboto';
      color: $color-primary !important;
      position: relative;
      align-content: center;
    }
    .css-1pahdxg-control {
      border-radius: 20px;
      outline: none;
      padding: 5px 15px;
      background-color: $color-background;
      border: none;
      height: 35px;
      margin: 0px 5px;
      min-width: 140px;
      font-size: $msg-font-size !important;
      font-weight: $font-weight-sm !important;
      font-family: 'Roboto';
      color: $color-primary !important;
      position: relative;
      align-content: center;
    }
    .css-1insrsq-control {
      border-radius: 20px;
      outline: none;
      padding: 5px 15px;
      background-color: $color-background;
      border: none;
      height: 35px;
      margin: 0px 5px;
      min-width: 140px;
      font-size: $msg-font-size !important;
      font-weight: $font-weight-sm !important;
      font-family: 'Roboto';
      color: $color-primary !important;
      position: relative;
      align-content: center;
    }
    .css-qc6sy-singleValue {
      color: $color-primary !important;
    }
    .css-lr9fot-singleValue{
      color: $color-primary !important;
    }
    .css-tlfecz-indicatorContainer {
      color: $color-primary !important;
    }
    .css-109onse-indicatorSeparator{
      width: 0;
    }
    .css-1okebmr-indicatorSeparator {
      width: 0;
    }
    .css-1gtu0rj-indicatorContainer {
      color: $color-primary !important;
    }
    .css-1gtu0rj-indicatorContainer:hover {
      color: $color-primary !important;
    }
    .css-tj5bde-Svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
      width: 25px; 
      height: 25px;
    }
    .css-26l3qy-menu {
      width: 151px;
      height: auto;
      border-radius: 2px 2px 20px 20px;
      padding: 4px 0px 4px 0px;
      background-color: #FFFFFF;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
                  0px 6px 16px 0px rgba(0, 0, 0, 0.08),
                  0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      display: flex;
      justify-content: center;  /* Alinear elementos en el centro verticalmente */
    }
    .css-1pndypt-Input {
      color: $color-primary !important;
    }
  }
  
  .hearder-filter-text {
    align-items: center;
    display: flex;
    padding: 0px 10px;
    font-size: 1.1em;
  }

  .header-right-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  .export-btn-container {
    margin: 0px 0px 20px -15px;
  }

  .export-btn-container button {
    background: $color-accent;
    border: 1px solid $color-accent;
    border-radius: 20px;
    color: $white;
    padding: 5px 25px 5px 25px;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
    text-transform: uppercase;
  }
}

.wallet-ca-export-btn {
  cursor: pointer;
  padding: 0px 5px;
}

.react-datepicker-wrapper {
  width: auto;
}

.table-ca .ant-table {
  background: transparent;
}

.table-ca .ant-table-thead > tr > th {
  font-family: "Roboto Regular";
  font-size: $h5-font-size;
  padding: 8px !important;
  border-radius: 0px !important;
  background-color: $color-primary;
  color: $white;
  text-align: center;
  vertical-align: middle;
}

.table-ca .ant-table-tbody > tr.ant-table-row > td {
  font-family: "Roboto";
  padding: 8px !important;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  color: $color-text-black;
  border-bottom: 1px solid $color-gray;
  text-align: center;
}

.table-ca .ant-table-tbody > tr:nth-child(even) {
  background-color: $white;
}

.table-ca .ant-table-tbody > tr:nth-child(even) > td:last-child {
  background: $white;
}

.table-ca .ant-pagination-item-active {
  background-color: $color-primary;
  border-color: $color-primary;
  color: $white;
}

.table-ca .ant-pagination-item-active a {
  color: $white;
}

.table-ca {
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#333, 0.2);
    border: 3px solid transparent;
    border-radius: 4px;
  }
}

.table-ca .ant-table-footer {
  background: transparent;
  padding: 10px 0px 0px 0px;
}
.custom-filters .ant-picker-range {
  width: 280px;
  border-radius: 8px;
  padding: 5px;
  min-height: 35px;
}

.custom-filters .ant-picker-input > input {
  text-align: center;
}

.checking-account button.ant-btn.ant-btn-link {
  font-size: $msg-font-size;
  font-weight: $font-weight-sm;
  font-family: "Roboto";
  color: $color-secondary;
}

.checking-account button.ant-btn.ant-btn-link span:hover {
  text-decoration: underline !important;
}

.loader-container-ca {
  background: #ffffff;
  padding: 100px;
  display: flex;
  justify-content: center;

  .loader {
    margin: 0px;
    position: inherit;
  }
}

.ca-autocomplete-select {
  width: 350px;
  height: 35px;
  z-index: 3;
  background-color: transparent;
  border-radius: 62px;
  .css-jv7z19-control {
    border: 1px solid $color-gray;
    border-radius: 62px;
    .css-14el2xx-placeholder {
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
      color: $color-gray;
    }
  }
  .css-1hb7zxy-IndicatorsContainer > div > span > svg {
    fill: $color-gray !important;
    color: $color-gray !important;
  }
  .css-x023bs-control {
    border: 1px solid $color-primary !important;
    box-shadow: 0 0 0 1px $color-primary !important;
    border-radius: 20px !important;
  }
}
