:root {
    --primary: #4628ff;
    --blue: #01043b;
    --aqua: #423894;
    --default: #0056ff;
    --active: #52B5B1;
    --hover: #d6d6d6;
    --white: #ffffff;
  }
  
  /* *************************************** */
  
  .new-btn {
    font-family: "Segoe UI Semibold";
    font-weight: bold;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }
  
  .new-btn.btn-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  
  /* STYLE PRIMARY BUTTONS */
  .new-btn-primary {
    background: var(--aqua) !important;
    color: var(--white) !important;
    border: 1px solid var(--aqua) !important;
    padding: 10px 0px !important;
  }
  
  .new-btn-primary:hover {
    background: #01043b;
    color: white;
  }