/* Basic Adjustments */
* {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.team-dashboard-container-left {
  position: relative;
  width: 30%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  box-shadow: 0px 15px 15px 0px rgb(66 57 148 / 5%),
    0px 0px 25px 0px rgb(66 57 148 / 5%);
  z-index: 1;
}

.team-dashboard-container-left-off-canvas {
  position: relative;
  display: none;
}

.team-dashboard-container-left .header {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #040560;
  font-family: "Roboto" !important;
  font-weight: 700;
  font-size: 26px;
  width: 360px;
}

.team-dashboard-container-left .header .referCode {
  color: #737dff;
  font-family: "Roboto" !important;
  font-size: 16px;
}

.team-dashboard-container-left .header .image {
  color: #040560;
  font-family: "Roboto" !important;
  font-weight: 700;
  font-size: 26px;
}

.team-dashboard-container-left .componente-raro {
  font-family: "Roboto" !important;
  font-size: 16px;
  color: #737dff;
}

.team-dashboard-container-left > .table-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: auto 1rem;

  .title-team {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    color: #2d2e8f;
    font-family: "Roboto" !important;
    font-size: 1.625rem;
    font-weight: bold;
  }

  .title-search {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    color: #737dff;
    font-family: "Roboto" !important;
    font-size: 1rem;
    font-weight: bold;
  }

  h3 {
    color: #4d4f5c;
    font-family: "Roboto" !important;
    font-size: 14px;
    //margin-bottom: 10px;
    font-weight: 500;
  }

  .message-client-not-found {
    text-align: center;
    padding: 30px 40px;

    .iconSearch {
      //margin-top: 30px;

      img {
        width: 35px;
      }
    }

    .message-title {
      font-size: 20px;
      //margin-bottom: 15px;
      //margin: 10px 0 18px;
    }

    p {
      color: #67698b;
      font-size: 13px;
    }
  }

  .message-consult-us {
    text-align: center;
    position: relative;
    top: 100px;

    /* .iconQuestion {
      margin-bottom: 8px;
    } */

    p {
      font-family: "Roboto" !important;
      font-size: 12px;
      font-weight: 600;
      color: #67698b;
    }
  }
}

.team-dashboard-container-left > .table-container > .table {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  justify-content: center;
  gap: 0.5rem 0.5rem;
}

.table-container {
  .title {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    color: #67698b;
    font-family: "Roboto" !important;
    font-size: 1.625rem;
    font-weight: normal;
    //  margin: 45px 0 18px;
  }

  .selections {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    //margin: 0px 0px 27px;
  }

  .custom--search--clean--container {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5ff;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
  }

  .custom--search--clean--input {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .custom--search--clean--container > .custom--search--clean--input {
    position: relative;
    width: 100%;
    height: 1.5rem;
    margin: 0 0 0.125rem 0;
    padding: 0;
  }

  .custom--search--clean--container .custom--search--clean--input::placeholder {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
    font-size: 0.8rem;
    vertical-align: middle;
  }

  .custom--search--clean--container > .custom--search--clean--iconSearch {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .custom--search--clean--container > .custom--search--clean--iconSearch > img {
    width: 1rem;
    height: auto;
    vertical-align: middle;
    top: auto;
    margin-right: 0.5rem;
  }

  .table {
    &::-webkit-scrollbar {
      background: #ffffff;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #e6e6e6;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 6px #dfdbdb;
    }
  }
}

.team-dashboard-container-left > .table-container > .table > li {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: block;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.313rem;
  //margin: 5px 0 10px;

  /* &.sub {
     margin: 0;
   box-shadow: none;
     border-radius: inherit;
     background: transparent;

     .item-partner {
       padding: 5px 35px 15px;

       .view-more {
        width: 24px;
         height: 24px;
         text-align: center;
         border: 0;
         border-radius: 5px;
         box-shadow: 0 1px 6px rgb(0 85 255 / 16%);
         background: #fff;
       }
     }

     .team-clients {
       display: flex;

       .clients {
         margin-left: 10px;
       }
     }
  } */

  .extend-tree {
    overflow-y: visible;
  }
  .team-dashboard-container-left > .table-container > .table > li .extend-tree {
    overflow-y: visible !important;
  }

  .team-list {
    //margin: 5px 0 12px;
    width: 100%;
  }

  .item-partner {
    color: #67698b;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    &.director {
      padding: 0 0.625rem;
      //margin: 0 10px;
    }

    .view-more {
      position: relative;
      width: 24px;
      height: 24px;
      padding: 0.25rem;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      border: 1px solid #040560;
      border-radius: 5px;
      box-shadow: 0 1px 6px rgb(0 85 255 / 16%);
      background: #fff;

      img {
        width: auto;
        height: auto;
        vertical-align: middle;
      }
    }

    .view-more:hover {
      background-color: #d6d8fa;
    }

    .display {
      font-size: 9px;
      padding: 2px 8px 2px 0;
    }

    .team-rol {
      position: relative;
      width: 5%;
      height: 5%;
      padding: 0;
      color: #ffffff;
      text-align: center;
      font-family: "Roboto" !important;
      font-size: 0.8rem;
      border-radius: 5px;

      &.blue {
        background-color: #2d2e8f;
      }
    }

    .light-blue {
      background-color: #737dff;
    }

    // .name {
    //   font-family: "Roboto Regular";
    //   font-size: 13px;
    //   color: #67698b;
    //   width: 50%;
    //   text-align: left;
    //   padding-left: 3%;
    //   padding-top: 2px;
    // }
    .team {
      font-family: "Roboto" !important;
      font-size: 12px;
      font-weight: bold;
      color: #2d2e8f;
      padding: 2px 0;
    }
    .clients {
      font-family: "Roboto" !important;
      font-size: 12px;
      font-weight: bold;
      color: #4d4d4d;
      padding: 0 10px 0 0;

      span {
        color: #9a9a9a;
      }
    }
    .display-team {
      width: 100%;

      .table {
        padding: 0;
        //margin: 0;

        li {
          background-color: rgba(226, 226, 251, 0.5);
          box-shadow: 0 0 0 transparent;
          border-radius: 0;
          //margin: 0;
          padding-left: 5px;

          .element {
            // background-color: rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0 transparent;
            border-radius: 0;
            //margin: 0;
          }
        }
      }
    }

    &.item-main {
      .arrow {
        position: relative;
        width: auto;
        height: auto;
        padding: 0.75rem;

        .active {
          i {
            transform: rotate(360deg);
          }
        }

        i {
          cursor: pointer;
          font-size: 0.8rem;
          display: block;
          transform: rotate(270deg);
        }
      }
      .name {
        font-family: "Roboto" !important;
        font-size: 0.8rem;
        font-weight: bold;
        color: #67698b;
        width: 50%;
        text-align: left;
        padding: 0.8rem 0;
        cursor: pointer;
      }
    }
  }

  // .dropbtn {
  //   background-color: #04aa6d;
  //   color: white;
  //   padding: 16px;
  //   font-size: 16px;
  //   border: none;
  // }

  .dropdown {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    background-color: #ffffff;
    color: #040560;
    text-align: left;
    font-family: "Roboto" !important;
    font-size: 100%;
    font-weight: bold;
    margin-right: 10px;
  }

  .dropdown-content {
    position: absolute;
    width: 200px;
    height: auto;
    padding: 0;
    display: none;
    top: 1rem;
    right: auto;
    bottom: auto;
    left: 1rem;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    z-index: 9999999999;
  }

  .dropdown-content {
    a {
      font-family: "Roboto" !important;
      font-size: 0.8rem;
      font-weight: normal;
      color: #040560;
      padding: 0.75rem;
      text-decoration: none;
      display: block;

      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .dropdown-content a:hover {
    color: #040560;
    background-color: #d6d8fa;
  }

  .dropdown:hover .dropdown-content {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #423894;
    display: block;
  }

  // .dropdown:hover .dropbtn {
  //   background-color: #3e8e41;
  // }

  // .item {
  //   color: #202124;
  //   font-size: 14px;
  //   margin-bottom: 15px;
  //   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  //   font-weight: 500;

  //   .text {
  //     width: max-content;
  //   }
  // }
}

.item-partner-separator {
  position: relative;
  width: 100%;
  height: 1.5rem;
  display: block;
}

// .arrow-wrapper {
//   background: none;
//   border: none;
//   outline: none;
//   margin: 5px;
// }

// .team-dashboard-container-left .table-container .table .element > div {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   align-content: center;
// }

// .team-dashboard-container-left .table-container .table .element .item-center {
//   flex-grow: 1;
//   color: #67698b;
//   font-size: 14px;
// }

// .team-dashboard-container-left .table-container .table .element .item {
//   color: #202124;
//   font-size: 14px;
//   margin-bottom: 15px;
//   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
//   font-weight: 500;
// }

// .team-dashboard-container-left .table-container .table .element .item .text {
//   width: max-content;
// }

// .team-dashboard-container-left
//   .table-container
//   .table
//   .element
//   .item-center
//   .name {
//   color: #202124;
//   font-size: 14px;
//   margin-bottom: 5px;
//   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
//   font-weight: 500;
//   width: 95%;
//   margin-right: auto;
// }

// .team-dashboard-container-left .table-container .table .element .number {
//   width: 100%;
//   color: #a4a7be;
// }

// .team-dashboard-container-left .btn-create-group .image {
//   cursor: pointer;
// }

// .team-dashboard-container-left .btn-create-group .description {
//   text-align: center;
//   font-size: 14px;
//   font-weight: 700;
//   color: #737dff;
//   opacity: 1;
// }

// .show-team {
//   display: block;
// }

// .hide-team {
//   display: none;
// }

.dropdown-content > a.dropdown-delete-account-special-link {
  color: #e61744 !important;
  background-color: #ffffff !important;
}

.dropdown-content > a.dropdown-delete-account-special-link:hover {
  color: #ffffff !important;
  background-color: #e61744 !important;
}

.show-hide-menu-button {
  position: absolute;
  width: auto;
  height: auto;
  padding: 1rem 0;
  top: 0;
  right: auto;
  bottom: auto;
  left: 27%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  z-index: 2;
  cursor: pointer;
}

.show-hide-menu-button-relocate {
  position: absolute;
  width: auto;
  height: auto;
  padding: 1rem;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  z-index: 2;
  cursor: pointer;
}
.menu-trigger-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.menu-trigger-button > img {
  width: 1.375rem;
  height: 1.375rem;
  vertical-align: middle;
  cursor: pointer;
}

.tree-node-item-partner {
  overflow-y: auto;
}

/* Animation & Transitions */

.slide-in-left {
  -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.3s ease-in(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.3s ease-in(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
