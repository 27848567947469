.operate-main-card-view-radio-buttons-wrapper {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
}

.operate-main-card-view-label-option-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.operate-main-card-view-label-option-container > img {
  padding: 0px 5px;
}

.operate-main-card-view-label-option-container > h4 {
  text-align: left;
  color: #b5b7ca;
  font-size: 0.8rem;
  font-family: "Roboto" !important;
}

.operate-main-card-view-radios-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;
}

.operate-main-card-view-radio-element {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem;
}

.operate-main-card-view-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-radio-term-operate {
  border-radius: 50%;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border: 2px solid #ffffff;
}

.img-radio-term-operate.checked {
  background-color: #737DFF;
  border: 3px solid #ffffff;
  //background: radial-gradient(blue, rgb(255, 255, 255), rgb(255, 255, 255));
}

.operate-main-card-view-radio-text {
  font-size: 0.875rem;
  font-family: "Roboto" !important;
  font-weight: 500;
  color: #263238;
  padding: 0 0.25rem;
}
