.arrow-invert {
  transform: rotate(180deg);
  width: 10px;
  transition: all 0.3s;
}

.arrow {
  width: 10px;
  transition: all 0.3s;
}
