@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.container-instrument-price {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  .container-options {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .container-options-title {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-weight: $font-weight-sm;
        font-size: $msg-font-size;
        color: $color-primary;
        font-family: 'Roboto';
        margin: 0;
        padding: 0;
      }
    }
  }
  .container-card-information {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
    .container-card-instrument {
      position: relative;
      width: 24%;
      height: auto;
      padding: 15px;
      border-radius: 20px;
      gap: 20px;
      background-color: $white;
      overflow-y: none;
      .container-title-card {
        margin: 10px 0px;
        .title-card {
          font-family: 'Roboto';
          font-size: $h5-font-size;
          font-weight: $font-weight-medium;
          color: $color-secondary;
          margin: 0;
          padding: 0;
        }
      }
      .container-options-card {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        height: auto;
        gap: 1rem;
        .option-card-instrument {
          font-weight: $font-weight-sm;
          font-size: $roboto-12-medium-font-size;
          text-align: center;
          color: $color-text-black;
          text-transform: uppercase;
          font-family: 'Roboto';
          cursor: pointer;
          border-radius: 100px;
          padding: 5px 15px 5px 15px;
          &.click-option-card {
            border: 1px solid $color-primary;
          }
          &.empty-option {
            visibility: hidden;
            width: 100%;
            padding: 5px 15px 5px 15px;
            height: 1.8rem;
          }
        }
        &.three-options {
          grid-template-rows: repeat(2, auto);
          .option-card-instrument:nth-child(3) {
            grid-column: 1 / span 2;
            justify-self: center;
          }
        }
      }
    }
  }
}

.container-button-information {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.container-button-information img {
  width: 10px;
  margin-right: 8px;
}

.button-information {
  text-decoration: none;
  color: #737dff;
  font-size: 12px;
}

.container-grafic {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000029;
  padding: 25px 0px 25px 0px;
}

.chart-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.chart-container canvas {
  height: 108px !important;
}

/* ticker */
.container-every-ticker {
  position: relative;
  width: auto;
  height: 720px;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.25rem 0;
  overflow-y: scroll;
  .container-ticker {
    position: relative;
    width: 97%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid $color-primary;
    background-color: transparent;
    cursor: pointer;
    &.false {
      border: none;
    }
    &.selected-ticker {
      background-color: transparent;
    }
    .container-logo-ticker {
      position: relative;
      width: 12%;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 0;
    }
    .img-custom {
      width: 2.2vw; /* ajusta el ancho según tus necesidades */
      height: auto; /* la altura se ajustará automáticamente */
      border-radius: 100%;
      border: 1px solid $color-gray;
    }
    .title-ticker {
      position: relative;
      width: 40%;
      height: 2rem;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 0;
      p {
        padding: 0 0 0 0.325rem;
        margin: 0;
        font-size: 10px;
        font-weight: $font-weight-medium;
        color: $color-text-black;
        font-family: 'Roboto';
      }
    }
    .import-ticker {
      position: relative;
      width: 30%;
      padding: 0 5px 0 0;
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-medium;
      font-family: 'Roboto';
      text-align: right;
    }
    .percentage-ticker {
      position: relative;
      width: 20%;
      padding: 2px 5px 2px 5px;
      font-size: 10px;
      border: 1px solid $color-gray;
      gap: 3px;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      border-radius: 032px;
      background-color: transparent;
      color: $color-error;
    }

    .percentage-ticker-positive {
      position: relative;
      width: 20%;
      padding: 2px 5px 2px 5px;
      border: 1px solid $color-gray;
      font-size: 10px;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      border-radius: 32px;
      background-color: transparent;
      color: $color-success-lime;
    }
  }
}
.empty-space {
  position: relative;
  width: 100%;
  height: 4.8rem;
  padding: 0;
  background-color: transparent;
}

.chart-loader .loader {
  width: 0.2em;
  height: 0.2em;
  margin: 50px auto;
}
