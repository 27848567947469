@import "../../scss/colors.scss";

.main-views-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
  margin: 0;
  padding: 1rem;
  overflow-y: scroll;
  background-color: $color-background;
}

.main-views-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
