.modal-cancel-general-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 57.5px;
  z-index: 5;
  background-color: rgba(2, 29, 135, 0.19);
}

.show-modal-cancel {
  display: block
}

.hide-modal-cancel {
  display: none
}

.modal-cancel-general-wrapper {
  position: relative;
  top: 14%;
  width: 32%;
  height: calc(340px + 20%);
  margin: 0 auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 25%);
}

.modal-cancel-loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-cancel-loading-image {
  position: relative;
  width: 100%;
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.modal-cancel-x-container {
  display: flex;
  justify-content: flex-end;
}

.modal-cancel-close-container {
  display: flex;
  margin: 6.5% 5.5% 0 0;
}

.modal-cancel-close-icon {
  width: 17px;
  height: 17px;
  cursor: pointer;
  opacity: .8;
}

.modal-cancel-close-icon:hover {
  opacity: 1;
}

.modal-cancel-general-image-section {
  position: relative;
  display: flex;
  padding-top: 7.5%;
}

.modal-cancel-image-container {
  position: relative;
  margin: 0 auto;
}

.modal-cancel-image-icon {
  position: relative;
  width: 148px;
  height: 148px;
}

.modal-cancel-title-container {
  display: flex;
  margin: 7.5% 0% 5% 0%;
}

.modal-cancel-title-text {
  margin: 0 auto;
  font-size: 26px;
  font-family: "Roboto" !important;
  font-weight: 600;
  color: #040560;
}

.modal-cancel-information-container {
  display: flex;
  text-align: center;
}

.modal-cancel-information-text {
  margin: 0 auto;
  font-size: 15px;
  font-family: "Roboto" !important;
  font-weight: 600;
  color: #2d2e8f;
  line-height: 22px;
}

.modal-cancel-button-container {
  display: flex;
  margin: 7.5% 0% 5% 0%;
}

.modal-cancel-button-box {
  display: flex;
  width: 215px;
  height: 40px;
  margin: 0 auto;
  background: #423994;
  border-radius: 25px;
  align-items: center;
  place-content: center;
  cursor: pointer;
}

.modal-cancel-button-text {
  font-size: 14px;
  font-family: "Roboto" !important;
  color: white;
}