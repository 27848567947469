@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.clientWallet-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  height: 40px;
  border-width: 0px 1px 1px 1px;
  border-color: #0000000f;
  border-style: solid;
}

.clientWallet-container.expandClient {
  height: 170px;
}

.clientWallet-items-first {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  align-items: center;
  width: 100%;
}
.clientWallet-item-fullname {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  padding: 5px 10px;
  font-size: $msg-font-size;
  color: $color-text-black;
  font-weight: $font-weight-sm;
  font-family: 'Roboto';
}

.clientWallet-item-data-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: $msg-font-size;
  color: $color-text-black;
  font-weight: $font-weight-sm;
  font-family: 'Roboto';
}

.clientWallet-item-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 5%;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgb(203, 214, 237);
  opacity: 1;
  border: 2px solid red;
}

.clientWallet-item-action > div {
  position: absolute;
  right: -222px;
  top: 145px;
  height: 230px;
  width: 220px;
  background: white;
  z-index: 455;
  opacity: 1;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid $dark-blue;
}

.option-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  color: $dark-blue;
}

.option-container.style-border {
  border-bottom-color: #67698b;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
}

.option-container:hover {
  color: white;
  background: $dark-blue;
}

.clientWallet-item-action:active {
  box-shadow: 0px 0px 0px 0px;
}

.clientWallet-items-second {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  margin: 0 auto;
  padding: 20px 30px;
  border-top: 1px solid #0000000f;
  .items-second-names {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;
    .clientWallet-item-data-2 {
      align-items: center;
      display: flex;
      text-align: start;
      width: 100%;
      font-size: $msg-font-size;
      color: $color-text-black;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
      img {
        margin: 0px 10px 0px 0px;
        width: 18px;
        height: 18px;
      }
    }
  }
  .items-second-numbers {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: 100%;
    grid-gap: 10px;
    gap: 10px;
    .clientWallet-item-data-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: start;
      width: 100%;
      font-size: $msg-font-size;
      color: $color-text-black;
      font-weight: $font-weight-sm;
      font-family: "Roboto";
     
      img {
        margin: 0px 10px 0px 0px;
        width: 18px;
        height: 18px;
      }
    }
  }
}



.items-second-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 15%;
  height: 95%;
}
.btn-home-view-wallet {
  background: $color-primary;
  color: $white;
  border: 1px solid $color-primary;
  border-radius: 100px;
  padding: 5px 25px 5px 25px;
  gap: 10px;
  font-size: $footnote-description-font-size;
  font-family: 'Roboto';
  text-align: center;
  font-weight: $font-weight-sm;
  cursor: pointer;
  transition: all 0.3s;
}
.btn-home-view-wallet:hover {
  background: $white;
  color: $color-text-black;
  border: 1px solid $color-primary;
}

.hide-icon {
  visibility: hidden;
}

.arrow-wrapper {
  background: none;
  border: none;
  outline: none;
  margin: 5px;
  cursor: pointer;
}

.loading {
  margin: auto;
  padding: 50px;
  color: $light-gray;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 10px;
  margin: 0 0 1rem 0;
}

.progress-bar li {
  flex: 2;
  position: relative;
  padding: 0 0 14px 0;
  font-size: $msg-font-size;
  color: $color-primary;
  font-weight: $font-weight-sm;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  border-bottom: 3px solid $color-light-gray;
}

.progress-bar li:first-child,
.progress-bar li:last-child {
  flex: 1;
}

.progress-bar li:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: $color-light-gray;
  border-radius: 50%;
  border: 3px solid $color-light-gray;
  position: absolute;
  left: calc(50% - 6px);
  bottom: -7px;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.progress-bar li:first-child:before {
  left: 0;
}

.progress-bar li:last-child:before {
  right: 0;
  left: auto;
}

.progress-bar span {
  transition: opacity 0.3s ease-in-out;
}

.progress-bar li:not(.is-active) span {
  opacity: 0;
}


.progress-bar .is-complete:not(:first-child):after,
.progress-bar .is-active:not(:first-child):after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: -50%;
  z-index: 2;
  border-bottom: 3px solid $color-primary;
}

.progress-bar li:last-child span {
  width: 200%;
  display: inline-block;
  position: absolute;
  left: -100%;
}

.progress-bar .is-complete:last-child:after,
.progress-bar .is-active:last-child:after {
  width: 200%;
  left: -100%;
}

.progress-bar .is-complete:before {
  background-color: $color-light-gray;
  border-color: $color-primary;
}
.progress-bar .is-active:before,
.progress-bar li:hover:before {
  background-color: $color-light-gray;
  border-color: $color-primary;
}

.progress-bar li:hover:before {
  transform: scale(1.33);
}

.progress-bar li:hover span {
  opacity: 1;
}

.progress-bar:hover li:not(:hover) span {
  opacity: 0;
}
