.container-team-notification-error{
  position: absolute;
  top: 80px;
  right: 32px;
  width: auto;
  z-index: 999;
}

.container-notification-error{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 367px;
  height: 123px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #b6b6b6;
  margin: auto;
  border-radius: 10px;
}

.container-text-error{
    width: 275px;
}

.title-notification-error{
  font-size: 16px;
  letter-spacing: 0px;
  color: #4D4D4D;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  margin-bottom: 6px;
}

.text-a-error{
    color: #717171;
    text-align: center;
    margin-top: 14px;
    font-size: 16px;
}

.container-icon-error{
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.icon-error{
  width: 37px;
}

.container-icon-close-error{
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-close{
  width: 8px;
}


