@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.recover-password-container {
  position: relative;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  margin-top: 60px;
  .recover-password-wrapper {
    position: relative;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: transparent;
    text-align: center;
    .recover-password-header-logo {
      width: 190px;
      padding: 0;
    }

    .subtitle {
      font-size: $h5-font-size;
      color: $color-primary;
      font-weight: $font-weight-medium;
      text-align: center;
      margin-top: 5vh;
      font-family: 'Roboto', sans-serif;
    }

    .recover-password-desrcription {
      position: relative;
      font-family: 'Roboto', sans-serif;
      font-weight: $font-weight-sm;
      font-size: $footnote-description-font-size;
      color: $color-text-black;
      padding: 0 3rem;
    }
    .condition-container{
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-right: 15vw;
    }
    .conditions-new-password{
      position: relative;
      font-family: 'Roboto', sans-serif;
      font-weight: $font-weight-sm;
      font-size: $footnote-description-font-size;
      color: #ABABAB;
    }
    .icon-conditions{
      color: #ABABAB;
      margin-right: 0.4vw;
    }

    .message-container {
      display: flex;
      align-items: flex-start;
      padding: 0 0 1rem 0;
      position: relative;
      .message {
        font-family: 'Roboto', sans-serif;
        font-weight: $font-weight-sm;
        font-size: $footnote-description-font-size;
        color: $color-gray;
        text-align: left;
        &.error {
          color: $color-error;
          text-align: center;
        }
      }
    }

    .recover-password-form {
      .input-password {
        padding: 6px 0 9px 0;
        border-top: 0 none;
        border-left: 0 none;
        border-right: 0 none;
      }
    }

    .recover-user-form {
      .input-text {
        position: relative;
        width: 100%;
        outline: none;
        border: 1px solid $color-gray;
        background: transparent;
        padding: 10px 15px;
        border-radius: 20px;
        font-family: 'Roboto Regular', sans-serif;
        &::placeholder {
          font-family: 'Roboto Regular', sans-serif;
          font-size: 16px;
          background-color: transparent;
          color: $color-text-black;
        }
      }
      .ant-form-item-control {
        max-width: 100%;
      }
      &.ant-form-item {
        margin-bottom: 10px;
      }
    }
    .recover-code-form {
      position: relative;

      .verification-code-input {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }
      .verification-code-input.error {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }
      .verification-input {
        font-size: 2rem;
        padding: 0.5rem;
        width: 50px;
        text-align: center;
        border: none;
        border-bottom: 3px solid #7381f7;
        transition: border-color 0.3s;
        color: #7381f7;
      }
      .verification-input.error {
        font-size: 2rem;
        padding: 0.5rem;
        width: 50px;
        text-align: center;
        border: none;
        border-bottom: 3px solid $color-error;  
        transition: border-color 0.3s;
        color: $color-error;
      }
      .verification-input:focus {
        outline: none;
        border-color: #7381f7;
      }
      .verification-input:focus.error {
        border-color: $color-error;
        outline: none;
      }
      .ant-input-group-lg .ant-input {
        padding: 0;
        font-size: 1.75rem;
        color: #717188;
      }

      .mask-enter-code {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:before {
          position: absolute;
          top: 0;
          right: -30px;
          content: '';
          width: 30px;
          height: 55px;
          background: $white;
          z-index: 1;
        }

        .enter-code {
          width: 8.2%;
          height: 55px;
          background-color: $color-background;
          border: 1px solid $color-gray;
          border-radius: 0.25rem;

          &.dash {
            border: 1px solid $color-light-gray;
          }
        }
      }

      .input-password {
        width: 110%;
        font-family: monospace;
        font-size: 1.75rem;
        letter-spacing: 23.7px;
        text-transform: uppercase;
        color: #717188;
        background-color: transparent;
        border: 0 none;
        margin-bottom: 20px;
      }

      .ant-form-item-has-error
        :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        background-color: transparent;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 10px;
      .btn-recover-submit {
        background: $color-primary;
        border-radius: 100px;
        border: none;
        color: $white;
        padding: 10px 25px;
        font-size: $roboto-12-medium-font-size;
        font-family: 'Roboto', Arial, Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        height: auto;
      }
      .btn-to-login {
        background: transparent;
        border-radius: 100px;
        border: 1px solid $color-primary;
        color: $color-primary;
        padding: 10px 25px;
        font-size: $roboto-12-medium-font-size;
        font-family: 'Roboto', Arial, Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        height: auto;
      }
    }


    .timer {
      color: $color-secondary;
      font-size: $title-modal-font-size;
      margin-top: 50px;
      font-weight: $font-weight-bold;
    }

    .help {
      padding: 33px 0 0 0;
      img {
        margin-bottom: 10px;
        width: 25px;
      }
      p {
        margin-bottom: 20px;
      }
      a {
        text-decoration: none;
        display: block;
      }
    }
  }

  .success-message {
    img {
      width: 130px;
      margin: 120px 0 70px;
    }
    h2 {
      font-size: 22px;
      color: $color-secondary;
      font-family: 'Roboto Bold';
    }
    p {
      font-size: $medium-font-size;
      color: $color-gray;
      font-family: 'Roboto Regular';
      margin-bottom: 50px;
    }
  }

  .error-message {
    h2 {
      color: $color-error;
      font-family: 'Roboto Bold';
    }
  }

  .unshow {
    display: none;
  }
  .msg-success {
    font-size: $msg-font-size;
    font-family: 'Roboto Regular';
    color: $color-secondary;
    font-weight: $font-weight-semi-bold;
    padding: 0 0 20px;
  }
  .msg-error {
    font-size: $msg-font-size;
    font-family: 'Roboto Regular';
    color: $color-error;
    font-weight: $font-weight-semi-bold;
    padding: 0 0 20px;
  }

  .ant-form-item-explain {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(245, 34, 45, 0.1);
    height: 32px;
    color: $color-error;
    border: 1px solid $color-error;
    border-radius: 100px;
    align-items: center;
    padding: 5px 25px 5px 25px;
    margin-top: 15px;
    .ant-form-item-explain-error {
      color: $color-error;
      font-size: $footnote-description-font-size;
      font-weight: $font-weight-sm;
      font-family: 'Roboto';
      text-align: center;
      margin: 0 auto;
      padding: 0;
    }
  }
}

.ant-form-item-control-input-content {
  outline: none;
  border: none;
  &:focus {
    outline: none;
    border: none;
    border-right: none;
  }
  &:hover {
    outline: none;
    border: none;
    border-right: none;
  }
  .ant-input,
  .ant-input-focused {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: #f4f6fd;

    border-right: none;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;

      border-right: none;
    }
    &:hover {
      outline: none;
      border: none;

      border-right: none;
    }
  }
}
