/* .button-team-report-container {
    position: relative;
    display: inline-flex;
    width: 260px;
    height: 68px;
    background: #737DFF;
    align-items: center;
    font-family: "Roboto";
    font-weight: bold;
    border: 2px solid #737dff;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .button-team-report-img-container {
    position: relative;
    display: flex;
  }
  
  .button-team-report-img {
    width: 33px;
    margin: 0 25px 0 25px;
    cursor: default;
  }
  .button-team-report-title{
      text-align: start;
  } */

  .button-receipt-download {
    position: relative;
    width: auto;
    height: auto;
    padding: 0.5rem 1rem;
    color: #ffffff;
    background-color: #423894;
    border: 1px solid #423894;
    border-radius: 5px;
    font-family: "Roboto" !important;
    font-size: 1rem;
    font-weight: normal;
  }
  
  .button-receipt-download:hover {
    cursor: pointer;
    color: #423894;
    background-color: #D6D8FA;
  }