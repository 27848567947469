.operate-confirm-procedure-container-data-header-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    padding: 0 10px;

    .operate-confirm-procedure-container-data-header-field-title {
      font-family: "Roboto" !important;
        font-size: 11px;
        padding: 0% 0% 3% 0%;
      }
      
      .operate-confirm-procedure-container-data-header-field-description {
        text-align: center;
        font-family: "Roboto" !important;
        font-weight: 600;
        font-size: 11px;
      }
      
      .operate-confirm-procedure-container-data-header-field-resalter {
        position: absolute;
        right: 0;
        height: 40px;
        width: 1px;
        background: rgba(250, 239, 239, 0.5);
      }
  }
