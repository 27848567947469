@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.operate-dashboard-container-footer {
  display: flex;
  align-items: center;
  color: white;
  // height: 110px;
  padding: 24px 70px;
  background: #737dff;
  border-radius: 0px 0px 8px 8px;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.operate-footer-numbers-total {
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 14px;
  float: right;
  padding: 0% 1% 0% 0%;
}

.operate-footer-total-quantity-container {
  padding: 0 6px 0 0;
}

.operate-dashboard-container-footer-confirm-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 5px 25px 5px 25px;
  gap: 10px;
  font-size: $roboto-12-medium-font-size;
  font-weight: $font-weight-sm;
  font-family: "Roboto";
  &.confirm-btn-inactived {
    color: $color-gray;
    background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
      linear-gradient(0deg, #ababab, #ababab);
    border: 1px solid $color-gray;
    cursor: not-allowed;
  }
  &.confirm-btn-actived{
    background: $color-primary;
    color: $white;
    border: 1px solid $color-primary;
    cursor: pointer
  }
}

.ant-table-summary .ant-table-cell {
  padding: 20px 3px !important;
}

.ant-table-summary {
  border-radius: 0px 0px 8px 8px;
  z-index: 0 !important;
  .operate-dashboard-container-footer-confirm-btn,
  .operate-footer-total-quantity-container,
  .operate-footer-total-quantity-price,
  .operate-footer-total-price {
    width: 120px;
  }

  .operate-footer-total-quantity-container {
    padding: 0;
  }
}
