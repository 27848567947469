.fee-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem 1.5rem;
  background-color: #01043B;
  color: #ffffff;
  font-family: "Roboto" !important;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 6px #00000029;
}

.fee-profit-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 0.8rem;
  font-weight: normal;
  opacity: 0.75;
}

.fee-profit-value {
  font-size: 1.325rem;
  font-weight: bold;
}

.fee-profit-line-separator {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}

.fee-profit-line-separator > .line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.25;
}

.fee-profit-value-small {
  font-size: 1rem;
  font-weight: bold;
}

.profit-values-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}