@import "../../../../index.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.ordenes-finalizadas {
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .ordenes-finalizadas-header {
    width: 100%;
    background: $white;
    .ordenes-finalizadas-header-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
    .ordenes-finalizadas-header-selections {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .datepickers {
        display: flex;
        flex-direction: row;
        padding: 5px 20px 5px 25px;
        background: $color-background;
        border-radius: 20px;
        margin-top: 18px;
        .ant-picker {
          background-color: transparent !important;
          gap: 15px !important;
        }
      }
      .operate-process-orders-inputs {
        margin-right: 7px;
      }

      .operate-process-orders-reload-button-container {
        cursor: pointer;

        .operate-process-orders-reload-button {
          position: relative;
          display: flex;

          .operate-process-orders-reload-icon {
            position: relative;
            width: 24px;
            height: 24px;
          }

          .operate-process-orders-reload-icon-disabled {
            cursor: not-allowed;
            opacity: 0.5;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .operate-dashboard-filter-tabs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      .operate-sub-items-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 0.5rem;
        .sub-items {
          padding: 5px 15px 5px 15px;
          font-size: $roboto-12-medium-font-size;
          font-weight: $font-weight-sm;
          text-align: center;
          color: $color-primary;
          font-family: "Roboto";
          background-color: transparent;
          gap: 10px;
          height: 32px;
          cursor: pointer;
          &.selected-sub-item {
            border-radius: 100px;
            border: 1px solid $color-primary;
          }
        }
      }
    }
  }

  .ordenes-finalizadas-table-header {
    position: relative;
    width: calc(100% - 7.25px);

    .columns {
      display: grid;
      grid-template-columns: 10% 14% 7% 8% 11% 5% 5% 8% 10% 10%;
      grid-gap: 1%;
      height: 30px;
      justify-content: center;
      box-sizing: border-box;
      margin-top: 10px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $light-gray;
        font-size: 11px;
        font-family: "Roboto" !important;
        text-align: center;
      }
    }

    .filter-header-options {
      display: none;

      .filter-header-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: $light-gray;
        font-family: "Roboto" !important;
        height: 33%;
        padding-left: 10px;
        cursor: pointer;
      }

      .filter-header-option:hover {
        color: $mid-blue;
        font-weight: 700;
      }

      .optionSelectedType {
        color: $dark-blue;
      }
    }

    .openFilterHeader {
      display: block;
      position: absolute;
      top: 40px;
      left: 5px;
      width: 220px;
      height: 140px;
      border-radius: 10px;
      border: 2px solid $dark-blue;
      background: $white;

      .filter-header-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        color: $light-gray;
        font-family: "Roboto" !important;
        height: 33%;
        padding-left: 10px;
        cursor: pointer;
      }

      .filter-header-option:hover {
        color: $mid-blue;
        font-weight: 700;
      }

      .optionSelectedType {
        color: $dark-blue;
      }
    }
  }
}

.ordenes-finalizadas-table-pendientes-element {
  width: 100%;
  color: $light-gray-2;
  font-size: 11px;
  font-family: "Roboto" !important;

  .data {
    display: grid;
    grid-template-columns: 10% 14% 7% 8% 11% 5% 5% 8% 10% 10%;
    grid-gap: 1%;
    height: 43px;
    justify-content: center;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top-color: $light-gray-2;
      border-top-style: solid;
      border-top-width: 0.5px;
      text-align: center;
    }
  }

  .data:hover {
    background: #edf0f4;
    border-radius: 5px;
  }
}

.ordenes-finalizadas-header-tabs-title {
  position: relative;
  color: $light-gray;
  width: 20%;
  font-size: 11px;
  font-family: "Roboto" !important;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  margin-top: 7px;

  .ordenes-finalizadas-header-tabs-title-resalter {
    position: absolute;
    top: 19px;
    left: 0;
    width: 100%;
    height: 2px;
    background: $mid-blue;
  }
}

.optionItemActive {
  color: $mid-blue;

  .ordenes-finalizadas-header-tabs-title-resalter {
    position: absolute;
    top: 19px;
    left: 0;
    width: 100%;
    height: 2px;
    background: $mid-blue;
  }
}

.ordenes-finalizadas-table-pendientes {
  width: 100%;
  overflow: auto;
  zoom: 93%;
}

.ordenes-finalizadas-table-pendientes .ant-table-thead > tr > th {
  font-family: "Roboto";
  font-weight: $font-weight-sm;
  font-size: $roboto-12-medium-font-size;
  padding: 8px !important;
  border-radius: 0px !important;
  background-color: $color-primary;
  color: $color-background;
  text-align: center;
}

.ordenes-finalizadas-table-pendientes .ant-table-tbody > tr > td {
  font-family: "Roboto";
  font-weight: $font-weight-sm;
  font-size: $roboto-12-medium-font-size;
  padding: 8px !important;
  color: $color-text-black;
  text-align: center;
}

.ordenes-finalizadas-table-pendientes .ant-table-pagination.ant-pagination {
  margin: 0px;
  padding: 16px;
  background: $color-primary;
  border-radius: 0px 0px 10px 10px;
}

.venta {
  color: $color-accent;
  font-weight: $font-weight-sm;
}

.compra {
  color: $color-error;
  font-weight: $font-weight-sm;
}
