@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.checks-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 5px;
  transform: translate(10px, 0px);
}

.check-title-image {
  margin-right: 10px;
  width: 10px;
}

.checks-title {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.doughnut-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 0px;
}

.check-reference-text {
  color: $color-text-black;
  font-weight: $font-weight-sm;
  font-size: $roboto-12-medium-font-size;
}
