@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import "src/scss/colors.scss";
// @import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

$font-roboto: "Roboto", sans-serif;

@font-face {
  font-family: "Roboto Regular";
  src: url("./fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Segoe UI Regular";
  src: url("./fonts/segoeui.ttf");
}
@font-face {
  font-family: "Segoe UI Bold";
  src: url("./fonts/segoeuib.ttf");
}
@font-face {
  font-family: "Segoe UI SemiBold";
  src: url("./fonts/seguisb.ttf");
}
@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("./fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("./fonts/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: "Source Sans Pro Semibold";
  src: url("./fonts/SourceSansPro-Semibold.ttf");
}
@font-face {
  font-family: "Source Sans Pro Semibold";
  src: url("./fonts/SourceSansPro-Semibold.ttf");
}

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?7i6n09");
  src: url("./fonts/icomoon.eot?7i6n09#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?7i6n09") format("truetype"),
    url("./fonts/icomoon.woff?7i6n09") format("woff"),
    url("./fonts/icomoon.svg?7i6n09#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caret-down:before {
  content: "\e90f";
}
.icon-arrow-angle-expand:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-cancel:before {
  content: "\e902";
}
.icon-caution .path1:before {
  content: "\e903";
  color: rgb(255, 180, 0);
}
.icon-caution .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(252, 244, 217);
}
.icon-caution .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(252, 244, 217);
}
.icon-checked:before {
  content: "\e906";
  color: #fff;
}
.icon-download:before {
  content: "\e907";
}
.icon-error:before {
  content: "\e908";
  color: #f04134;
}
.icon-information:before {
  content: "\e909";
}
.icon-paperclip:before {
  content: "\e90a";
}
.icon-send:before {
  content: "\e90b";
}
.icon-spinner:before {
  content: "\e90c";
}
.icon-ticket:before {
  content: "\e90d";
}
.icon-upload:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e910";
}
.icon-hamburger-dots:before {
  content: "\e918";
}
.icon-logout:before {
  content: "\e911";
}
.icon-copy:before {
  content: "\e912";
}
.icon-print:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-download-file:before {
  content: "\e915";
}
.icon-edit:before {
  content: "\e916";
}
.icon-people-group:before {
  content: "\e917";
}

body {
  margin: 0;
  overflow: hidden;
  font-family: $font-roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 auto;
}

figure {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background: $white;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: $white;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: $light-blue;
  border-radius: 10px;
}
