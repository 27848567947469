@import "./grid.scss";

.box-row {
  position: relative;
  box-sizing: border-box;
  min-height: 2rem;
  margin-bottom: 0;
  background: #007fff;
  border: 1px solid #fff;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  color: #fff;
}

pre {
  overflow-x: auto;
  padding: 1.25em;
  border: 1px solid #e6e6e6;
  border-left-width: 5px;
  margin: 1.6em 0;
  font-size: 0.875em;
  background: #fcfcfc;
  white-space: pre;
  word-wrap: normal;
}

.color-box {
  padding: 10px;
  height: 85px;
  margin: 10px 0;
  border: 1px solid #333333;
  h5 {
    font-family: "Roboto Regular";
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.colors .col-xs-2:nth-child(1) .color-box {
  background-color: #101010;
  color: #ffffff;
}
.colors .col-xs-2:nth-child(2) .color-box {
  background-color: #ffffff;
}
.colors .col-xs-2:nth-child(3) .color-box {
  background-color: #a4a7be;
}
.colors .col-xs-2:nth-child(4) .color-box {
  background-color: #a6adb4;
}
.colors .col-xs-2:nth-child(5) .color-box {
  background-color: #67698b;
}

.colors .col-xs-2:nth-child(6) .color-box {
  background-color: #4d4f5c;
}

.colors .col-xs-2:nth-child(7) .color-box {
  background-color: #d9daf5;
}

.colors .col-xs-2:nth-child(8) .color-box {
  background-color: #d5d9f0;
}

.colors .col-xs-2:nth-child(9) .color-box {
  background-color: #aec9f5;
}

.colors .col-xs-2:nth-child(10) .color-box {
  background-color: #737dff;
}

.colors .col-xs-2:nth-child(11) .color-box {
  background-color: #423994;
  color: #ffffff;
}

.colors .col-xs-2:nth-child(12) .color-box {
  background-color: #01043b;
  color: #ffffff;
}

.colors .col-xs-2:nth-child(13) .color-box {
  background-color: #11d4cd;
}

.colors .col-xs-2:nth-child(14) .color-box {
  background-color: #0ab8b2;
}

.colors .col-xs-2:nth-child(15) .color-box {
  background-color: #e80451;
}

.icon-box {
  padding: 10px;
  height: 110px;
  margin: 10px 0;
  border: 1px solid #333333;
  border-radius: 3px;
  background: #eaeaea;
  i {
    font-size: 20px;
    margin: 2px 0 10px 0;
    display: block;
    color: #423994;
  }
  h5 {
    font-family: "Roboto Regular";
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    font-family: "Roboto Regular";
    font-size: 12px;
  }
}
