@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.select-search-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  font-family: "Roboto";
  outline: none;
  input {
    position: relative;
    outline: none;
    border: none;
    width: 320px;
    height: auto;
    margin: 0;
    padding: 0.2rem;
    &::placeholder {
      position: relative;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      font-style: normal;
      font-family: "Roboto";
    }
  }
}
.select-search-container-options {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 0;
  top: 2rem;
  right: auto;
  bottom: auto;
  left: 0;
  display: none;
  border-radius: 2px 2px 20px 20px;
  background-color: $white;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  transition: all 0.3s;
  z-index: 9999;
  text-align: center;
  box-shadow: 0px 9px 28px 8px #0000000D;
  box-shadow: 0px 6px 16px 0px #00000014;
  box-shadow: 0px 3px 6px -4px #0000001F;
}

.select-search-container-options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

.select-search-container-options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  box-shadow: inset 0 0 3px $color-primary;
}

.select-search-container-options:hover::-webkit-scrollbar {
  background: transparent;
}

/* .select-search-container-options.open-clients-wallet {
  display: block;
} */

.select-search-container-options.open-clients-wallet {
  display: block;
  color: $color-primary;
  background-color: $white;
  border-radius: 2px 2px 20px 20px;
  text-align: center;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: auto;
  top: 2.3rem;
  right: auto;
  bottom: auto;
  left: 0;
}

.select-search-container-options-wrapper:hover
  > .option-search-clients-wallet
  > .option-search-clients-wallet-item {
  background-color: $color-background;
}

.option-search-clients-wallet {
  cursor: pointer;
  color: $color-primary;
  font-size: $roboto-12-medium-font-size;
  font-family: "Roboto";
  font-weight: $font-weight-sm;
  z-index: 500;
}

.option-search-clients-wallet:hover {
  color: $color-primary
}

.option-search-clients-wallet-item {
  padding: 0.5rem;
}

.arrow-container {
  width: 10px;
  cursor: pointer;
}

.arrow-invert {
  transform: rotate(180deg);
}

.select-search-container-loading {
  position: relative;
  display: flex;
  height: 36px;
  width: 345px;
  align-items: center;
}
