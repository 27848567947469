@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.wallet-tabs-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  .option {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0;
    gap: 0;
    .option-text {
      font-family: "Roboto";
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-medium;
      cursor: pointer;
      text-align: center;
      margin: 0;
      padding: 5px 25px 5px 25px;
      min-width: 147px;
      border-radius: 100px;
      border: 1px solid #7381F7;
      &.selected {
        color: $white;
        font-weight: 700;
        background-color: $color-primary;
        border: 1px solid $color-primary;
        transition-duration: 900ms;
        z-index: 5;
      }
    }
  }
  .resume {
    position: relative;
    width: 70%;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: #a4a7be;
    font-family: "Roboto" !important;
    font-size: 0.8rem;
  }
  .line {
    position: absolute;
    top: 30px;
    left: 20px;
    height: 0.5px;
    width: 100%;
    text-align: center;
    background: #cacde6;
  }
}
