@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.ant-tabs-nav-list {
  width: 100%;
  justify-content: start;
}

.ticker-detail-modal-body {
  display: flex;
  flex-direction: column;
}

.modal-ticker-title {
  margin: 0px 0px 10px 0px;
}

.ticker-detail-modal-person-type-title {
  color: #737dff;
}

.ticker-detail-modal-title {
  font-weight: 500;
}

.ticker-detail-list {
  .ticker-detail-table > tr {
    border-right: none !important;
    border-left: none !important;
  }
  .ticker-detail-table > tr > td {
    border-top: 1px solid #0000000f;
    border-bottom: 1px solid #0000000f;
    border-left: none !important;
  }
  .ticker-detail-table > tr > td:first-child {
    border-right: none !important;
    border-left: none !important;
  }
  .ticker-detail-table > tr > td:last-child {
    border-right: none !important;
    border-left: none !important;
  }

  .ticker-detail-table > tr > td > .ticker-detail-modal-text {
    padding: 0;
    margin: 0;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-sm;
    font-family: "Roboto";
    color: $color-gray;
  }
  .ticker-detail-table > tr > td > .ticker-detail-modal-bold-text {
    padding: 0;
    margin: 0;
    font-size: $roboto-12-medium-font-size;
    font-weight: $font-weight-bold;
    font-family: "Roboto";
    color: $color-text-black;
  }
}

.quotation-info {
  margin: 5px;
}

.ticker-detail-table {
  width: 100%;
  margin: 0.5rem 0;
  background-color: $color-background;
  border: 0;
}

.ticker-detail-table > tr {
  border: 0;
}

.ticker-detail-table > tr > td {
  border-left: solid;
  border-left-width: 1px;
  padding: 5px 10px 5px 10px;
}

.ticker-detail-modal-bottom {
  position: relative;
  width: auto;
  height: auto;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;

  .ticker-detail-modal-bottom-title {
    padding: 0.5rem;
    border-top: 1px solid #0000000f;
    border-bottom: 1px solid #0000000f;
    background-color: $color-background;
    p {
      font-size: $msg-font-size;
      font-weight: $font-weight-sm;
      color: $color-text-black;
      font-family: "Roboto";
    }
    .ticker-detail-modal-bold-text {
      font-family: "Roboto";
      font-size: $x-medium-font-size;
      font-weight: $font-weight-medium;
      color: #252525;
    }
  }

  .ticker-detail-modal-bottom {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem 1rem;
  }

  .ticker-detail-modal-bottom-title > p {
    margin: 0;
    display: flex;
  }
}

// Cartera

.ticker-detail-data {
  display: flex;
  justify-content: space-between;
  height: 40%;
  align-items: center;
  gap: 1rem 1rem;
}

.ticker-detail-data-left-pane {
  .ticker-detail-data-subtype > p {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  display: flex;
  width: 50%; /*testing side-by-side with*/
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 1rem 1rem;
}

.ticker-detail-data-subtype-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ticker-detail-data-subtype {
  padding: 5px;
  margin: 10px 0px 10px 0px;
  border-radius: 6px;
  border-style: solid;
  border-width: 0;
  background-color: #f1f1f1;
  width: 23%;
}

.ticker-detail-data-subtype-selected {
  background-color: #e0e0e0;
  border-color: #00043d;
}

.ticker-wallet-subtype-list-container {
  padding: 1rem;
  background-color: #f1f1f1;
  height: 250px;
  border-radius: 0.5rem;
  border: 1px solid #c4c4c4;
}

.ticker-wallet-subtype-list-container > .ticker-wallet-subtype-list {
  padding: 0 0.5rem 0 0;
  margin-bottom: 0;
  overflow-y: scroll;
  height: 100%;
}

.ticker-wallet-subtype-list-container
  > .ticker-wallet-subtype-list::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
}

.ticker-detail-subtype-list-item {
  color: $color-text-black;
  font-weight: $font-weight-medium;
  font-size: $msg-font-size;
  font-family: "Roboto";
}

//Detalle

.ticker-detail-modal-text {
  font-weight: $font-weight-medium;
  font-size: $roboto-12-medium-font-size;
  color: $color-text-black;
  font-family: "Roboto";
}

.detail-tab-info {
  overflow: auto;
  height: 90%;
  border: 0 solid #acacac;
  background-color: #f7f7fa;
  border-radius: 5px;
}

.ticker-modal-detail-row {
  border-bottom: solid 1px #acacac;
  padding: 0.5rem;
}

.ticker-modal-detail-title {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}

.adaptable-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
  margin: 0;
  background-color: #2d2e8f;
  color: #ffffff;
  font-family: "Roboto" !important;
  font-size: 1rem;
  font-weight: normal;
  border: 0;
  border-radius: 0.25rem;
  transition: none;
  /* width: 30%;
    background-color: #2D2E8F;
    color: #ffff;
    height: 40px;
    border-radius: 5px; */
}

.adaptable-button:hover {
  background-color: #737dff !important;
  color: #ffffff;
}

/*for the alternate modal "ampliar información*/
.base-modal-content .ant-tabs.ant-tabs-top {
  padding: 0.5rem 1rem !important; /*this override it's for bypass the And-Design settings inherited from main SCSS files*/
  background-color: #ffffff;
  font-family: "Roboto" !important;
}

/*move to a sorted component titles code area*/
.ticker-detail-modal-title-text {
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 1rem;
  color: #263238;
}

.item-card-middle-section > a {
  color: #0056ff;
  font-size: 0.8rem;
}

/*alternative-modal control*/
.alternative-modal > .alternative-modal-content {
  width: 60%;
  height: auto;
}

.ticker-detail-chart-cotizaciones-tab {
  padding: 1rem;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #bfbfbf;
}

.ticker-detail-chart {
  width: 50%;
  padding: 0;
  background-color: transparent;
}

.ticker-detail-chart canvas {
  width: 100%;
  height: 250px;
  padding: 1rem;
}
