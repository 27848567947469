.operate-detail-especie-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1rem 0rem;
  display: none;
}

.operate-detail-especie-container.background-white {
  background: white;
}

.operate-detail-especie-container.active-detail-card {
  display: block;
}

.container-ahora {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-results-instruments-filters-selections {
  position: relative;
  width: 100%;
  height: 600px;
  padding: 0;
}

.title-ahora {
  color: #67698b;
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto" !important;
  margin: 10px 0;
  width: 90%;
}
/* ****************************************************** */
.operate-detail-especie-container .detail-especie-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.operate-detail-especie-container .detail-especie-info .title {
  color: #67698b;
  font-family: "Roboto" !important;
  font-size: 28px;
  opacity: 1;
  width: inherit;
}

.operate-detail-especie-container .detail-especie-info .subtitle-1 {
  font-size: 18px;
  color: #a4a7be;
}

.operate-detail-especie-container .detail-especie-info .subtitle-2 {
  color: #a4a7be;
  font-size: 14px;
}

/* ****************************************************** */
/* ****************************************************** */
/* ****************************************************** */
/* ****************************************************** */
.operate-detail-especie-container .detail-especie-info .mini-gray-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.operate-detail-especie-container .detail-especie-info .mini-gray-card > div {
  height: 72px;
  width: 174px;
  background: #f0f3fa;
  border-radius: 10px;
  opacity: 1;
  margin-right: 15px;
}

.mini-gray-card .result-mini-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.mini-gray-card .result-mini-card .title {
  text-align: center;
  font: "Roboto";
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.8;
  font-size: 14px;
}

.mini-gray-card .result-mini-card .value {
  text-align: center;
  font: Bold 26px/32px Roboto;
  letter-spacing: 0px;
  color: #737dff;
  opacity: 0.8;
}

.detail-especie-info .data {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.detail-especie-info .data .element-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  font-size: 0.875rem;
}

.detail-especie-info .data .element-data .field {
  color: #a4a7be;
}

.detail-especie-info .data .element-data .value {
  color: #4d4f5c;
}

.detail-especie-info .horizontal-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 366px;
  height: 30px;
  font-size: 0.875rem;
  background: #f0f3fa;
  color: #a4a7be;
  border-radius: 0.5rem;
}

.detail-especie-info .data-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  color: black;
  font-size: 1rem;
  opacity: 0.5;
}

.detail-especie-info .data-color > div {
  width: 36%;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.detail-especie-info .data-color .data-color-element {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 23px;
}

/* ****************************************************** */

.operate-pie-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 20px 0;
  color: black;
}

.operate-my-circle-container {
  height: 180px;
  width: 180px;
  border: 1px solid rgb(242, 240, 244);
  position: relative;
  overflow: hidden;
  opacity: 1;
  z-index: 1;
  border-radius: 50%;
}

.operate-corona-interna {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  height: inherit;
  width: inherit;
  color: #423894;
  background: white;
  border-radius: 50%;

  z-index: 5000;
  height: 125px;
  width: 125px;
  left: 15%;
  bottom: 15%;
}

.corona-interna > div {
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 18px;
  font-style: italic;
}

.perc-element {
  text-align: center;
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-family: "Roboto" !important;
  padding: 0px -20px;
  position: absolute;
  height: inherit;
  width: inherit;
  opacity: 1;
  border-radius: 10%;
  transition: all 0.3s;
}

.perc-element.animate-element {
  opacity: 0.2;
}

.satelite-item {
  position: static;
  height: 1200px;
  width: 1200px;
  background: rgb(209, 27, 27);
  left: 45%;
  bottom: 45%;
  z-index: 8500;
}

.number-value-loco {
  transform: rotate(15deg);
  color: white;
  padding-left: 90px;
  padding-top: 10px;
}

.detail-cartera-list {
  height: 200px;
  width: 330px;
  padding-top: 5px;
  overflow: auto;
}

.detail-cartera-list .element {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom-color: #cecece;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
}

.detail-cartera-list .element .point-icon {
  margin-top: 5px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.detail-cartera-list .element .description {
  color: #67698b;
  font-size: 14px;
  font-family: "Roboto";
  width: 240px;
}

.detail-cartera-list .element .percentage {
  color: #2d2e8f;
  font-size: 18px;
  font-family: "Roboto" !important;
  text-align: right;
  width: 80px;
}
