.statistics-graph {
  width: 265px;
  float: left;
  position: absolute;
  left: -30px;
  top: 8px;
}

.checks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  text-align: center;
  color: #a4a7be;
  width: 60%;
  height: 50px;
  margin-left: 10px; /* REVISAR */
}

.check-title-image {
  margin-right: 10px;
}

.checks-title {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px;
}

.doughnut-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.check-reference-text {
  text-align: left;
}