.in-maintenance-container {
  position: relative;
  height: 100vh;
  background: #f4f6fd;
  margin: 0 auto;

  .in-maintenance-wrapper {
    position: relative;
    padding: 10% 4.5% 4% 4.5%;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    text-align: center;

    figure {
      img {
        width: 500px;
      }
    }
    h2 {
      font-family: "Roboto Bold";
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      color: #263238;
      margin: 45px 0 20px;
    }
    p {
      font-family: "Roboto Regular";
      font-size: 17px;
      color: #2d2e8f;
    }
  }
}

.component-modal-container {
  position: relative;
  width: 90%;
  height: 600px;
  background: white;
  margin: 0 auto;
  top: calc(40vh - 250px);
  box-shadow: 0px 25px 50px 0px rgb(0 0 0 / 25%);
}

.component-modal-wrapper {
  position: relative;
  padding: 10% 4.5% 4% 4.5%;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.component-modal-title-container {
  text-align: center;
  padding: 1.5% 0% 0% 0%;

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    color: #040560;
  }
}

.component-modal-description-container {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 3% 0% 2% 0;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2d2e8f;
    opacity: 1;
    line-height: 16px;
  }
}
