@import '../../../../scss/colors.scss';
@import '../../../../scss/fonts.scss';

.section-title {
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #040560;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 26px;

    .container-header-title {
      color: #040560;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 26px;
    }

    .container-header-referCode {
      color: $color-primary;
      font-size: $roboto-12-medium-font-size;
      margin-left: 20px;
      font-weight: $font-weight-sm;
      gap: 5px;
      padding: 5px 10px 5px 10px;
      border-radius: 16px;
      border: 1px solid $color-primary-light;
      background-color: $color-secondary-light;
      button {
        background: none;
        outline: none;
        border: none;
        color: $color-primary;
        cursor: pointer;
        .copy-text {
          margin-left: 7px;
          color: $color-success;
          font-weight: $font-weight-sm;
          font-size: $msg-font-size;
        }
      }
    }
  }
}
