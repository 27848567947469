@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.login-general-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: $color-background;
  .login-left-image-container {
    position: relative;
    display: flex;
    width: 50%;
    overflow: hidden;
    background-image: url('../../utils/image-home-left.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-primary;
  }
  .login-left-logo-container {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 167px;
    height: 65px;
    background-image: url('../../utils/logo-banza-left.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.login-right-form-general-container {
  position: relative;
  display: flex;
  width: 50%;
  margin: 0 auto;
  .login-right-form-wrapper {
    position: relative;
    height: 100%;
    padding: 12% 12% 12% 12%;
    text-align: center;
    .login-header-logo-container {
      position: relative;
      top:7%;
      .login-header-logo {
        width: 35%;
      }
    }
    .login-title-container {
      position: relative;
      width: 100%;
      top: 15%;
      text-align: center;
    }
    .login-form-container {
      position: relative;
      top: 16%;
      background-color: $color-background;
      .login-form-wrapper {
        position: relative;
        .login-form-input {
          position: relative;
          padding-top: 15px;
          margin: 0 auto;
        }
        .login-form-warning {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: rgba(245, 34, 45, 0.1);
            height: 32px;
          color: $color-error;
          border: 1px solid $color-error;
          border-radius: 100px;
          align-items: center;
          padding: 5px 25px 5px 25px;
          margin-top: 15px;
          .login-form-text{
            color: $color-error;
            font-size: $footnote-description-font-size;
            font-weight: $font-weight-sm;
            font-family: 'Roboto';      
            text-align: center;
            margin: 0 auto;
            padding: 0;
          }
        }
        .message-hidden {
          display: none;
        }
      }

      .login-form-button {
        position: relative;
        padding-top: 4.5%;
      }
      
    }
  }
  .login-forget-links-container {
    position: relative;
    display: inline-flex;
    padding-top: 4.5%;
    .login-recover-user,
    .login-recover-password {
      font-family: 'Roboto Regular';
      font-size: $roboto-12-medium-font-size;
      color: $color-primary;
      font-weight: $font-weight-medium;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.resend-password {
  font-family: 'Roboto Regular';
  font-size: 13px;
  color: #5c9afe;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
}

.loguin-forget-links-divider {
  width: 16px;
}

.login-footer-question-container {
  position: absolute;
  bottom: 3%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.login-agree-question-container {
  position: relative;
  padding-top: 2.5%;
}
@media screen and (max-width: 1024px) {
  .login-general-container .login-left-image-container {
    display: none;
  }
  .login-right-form-general-container {
    width: 100%;
  }
  .login-right-form-wrapper {
    width: 100%;
    padding: 0% 0% 0% 0%;
  }
  .login-header-logo-container {
    top: 2.5%;
  }
  .login-form-input {
    width: 100%;
    .password-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
@media (max-width: 1440px) {
  .login-left-image-container {
    background-position: center top;
  }
}
