/* Basic Adjustments */

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.team-dashboard-container-right {
  position: relative;
  width: 70%;
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #EFEFEF;
  box-shadow: 0px 15px 15px 0px rgb(66 57 148 / 5%),
    0px 0px 25px 0px rgb(66 57 148 / 5%);
  overflow-y: scroll;
}

.team-dashboard-container-right-full-size {
  position: relative;
  width: 95%;
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #EFEFEF;
  box-shadow: 0px 15px 15px 0px rgb(66 57 148 / 5%),
    0px 0px 25px 0px rgb(66 57 148 / 5%);
  overflow-y: scroll;
}

.top-dashboard-right-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 1rem;
}

.title-teams-right {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  color: #2d2e8f;
  font-family: "Roboto" !important;
  font-size: 1.375rem;
  font-weight: bold;
}

.title-teams-right-full-size {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.5rem 0 0 0;
  color: #2d2e8f;
  font-family: "Roboto" !important;
  font-size: 1.375rem;
  font-weight: bold;
}

.ticket-buttons-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem 1rem;
}

/*refactoring false-header for profit-container-card*/
.select-period-components-and-fee-profit-top-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
}

.fee-profit-top-container {
  position: relative;
  width: 40%;
  height: auto;
  padding: 0;
}

/* Select Period Buttons & Datepicker Container(s) */
.select-period-components-container {
  position: relative;
  width: 60%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 1rem;
}

/* Select Team Performance Container */
.team-performance-selector-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 1rem;
}

.team-performance-selector-container > select {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  font-weight: normal;
  border: 0;
  border-radius: 0.25rem;
}

.inactive-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #717171;
  background-color: #ffffff;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  cursor: pointer;
}

.inactive-period-button:hover {
  color: #423994;
  background-color: #D6D8FA;
}

.selected-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #ffffff;
  background-color: #423994;
  border: 1px solid #423994;
  border-radius: 5px;
  cursor: pointer;
}

.selected-period-button:hover {
  color: #423994;
  background-color: #D6D8FA;
}

.disabled-period-button {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 0.8rem;
  color: #00000029;
  background-color: #C4C4C4;
  border: 1px solid #00000029;
  border-radius: 5px;
  cursor: pointer;
}

/* .team-button-tickets-container {
  text-align: right;
}

.team-counts-information-container {
  box-sizing: border-box;
  margin-top: 2.5%;
}

.mbottom {
  margin-bottom: 40px;
} */

.fee-details-plus-team-business-intelligence-container {
  position: relative;
  width: 100%;
  height: 175px;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem 1rem;
}

.profit-fee-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
}

.team-business-intelligence-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}

.team-counts-information-container {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
}