.hide-modal-disclaimer {
  display: none;
}

.container-modal {
  position: fixed;
  width: 97%;
  height: 100%;
  top: 50px;
  left: 57.5px;
  z-index: 5;
  background-color: rgba(2, 29, 135, 0.19);
}

.modal {
  font-family: "Roboto Regular";
  display: block;
  position: relative;
  width: 900px;
  height: 700px;
  background: white;
  margin: 0 auto;
  top: calc(40vh - 250px);
  box-shadow: 0px 25px 50px 0px rgb(0 0 0 / 25%);

  .modal-wrapper {
    position: relative;
    padding: 15px 20px;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }

  .modal-title {
    font-family: "Roboto Bold";
    font-size: 26px;
    font-weight: bold;
    color: #040560;
    text-align: center;
    margin: 0 0 30px;
  }

  h4 {
    font-family: "Roboto Bold";
    font-size: 14px;
    color: #2d2e8f;
  }

  .justification {
    margin: 20px 0;

    .input-search-producer {
      width: 100%;
      padding: 0 10px;
    }

    .input-search-producer input {
      top: 4px;
    }
  }

  .resign-portfolio {
    text-align: center;

    button {
      height: 44px;
      background-color: #423994;
      font-family: "Roboto Regular";
      font-size: 13px;
      color: #ffffff;
      padding: 0 30px;
      border: 0 none;
      border-radius: 30px;
      margin: 15px;
    }

    .resign {
      display: block;
      font-size: 13px;
      font-weight: bold;
      color: #737dff;
      text-decoration: underline;
    }
  }

  // a {
  //   font-family: "Roboto Bold";
  //   font-size: 14px;
  //   color: #737dff;
  // }
}

.calendar-container {
  padding: 0;
  border: 2px solid #5c9afe !important;
  border-radius: 15px;
  box-shadow: 0px 1px 6px #0055ff29;
  overflow: hidden;
  height: 260px;
  margin-bottom: 20px;

  .card {
    background-color: transparent;
  }

  .card-header {
    background-color: transparent;
  }

  .table td,
  .table th {
    padding: 3px;
  }

  .__cal__ {
    min-width: none;
    max-width: 100%;
  }
}

.input-search-producer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070 !important;
  border-radius: 6px;
  opacity: 1;
  // width: 95%;
  margin: 5px 0px;
  height: 35px;
  display: flex;
  align-items: center;

  img {
    margin: 10px;
  }

  input {
    font-style: italic;
    font-size: 14px;
    // position: relative;
    // top: 0;
    align-self: center;
    width: 85%;
    color: #a4a7be;
  }
}

.assignment-time {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 8px;
}
