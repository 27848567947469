@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.home-container {
  position: relative;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 100px;
  .home-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 27px 0px 15px 0px;
    margin: 0px;
    .home-container-header-title {
      color: $color-secondary;
      font-family: "Roboto";
      font-weight: $font-weight-medium;
      font-size: $large-font-size;
    }
    .home-container-referCode {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      gap: 10px;
      padding: 5px 10px 5px 10px;
      border-radius: 16px;
      border: 1px solid $color-primary-light;
      background-color: $color-secondary-light;
      .home-container-header-referCode {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $color-primary;
        font-size: $roboto-12-medium-font-size;
        gap: 5px;
        font-weight: $font-weight-sm;
        button {
          background: none;
          outline: none;
          border: none;
          color: $color-primary;
          cursor: pointer;
          font-weight: $font-weight-bold;
          color: $color-primary;
          font-size: $msg-font-size;
          img {
            margin-left: 7px;
          }
        }
        .copy-text {
          margin-left: 7px;
          color: $color-success;
          font-weight: $font-weight-sm;
          font-size: $msg-font-size;
        }
      }
    }
  }

  .home-container-cards {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
  }

  .home-container-cards-client {
    display: grid;
    padding: 30px 0px;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: auto;
    background: transparent;
  }
}

@media screen and (max-width: 1618px) {
  .home-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #040560;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 26px;
    padding: 15px 0px 10px 50px;
  }

  .home-container-cards {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: auto;
    padding: 0px;
    margin: 0;
    gap: 0;
  }
}

@media screen and (max-width: 1200px) {
  .home-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #040560;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 26px;
    padding: 15px 0px 10px 50px;
  }

  .home-container-cards {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: auto;
    padding: 0px;
    margin: 0;
    gap: 0;
  }
}
