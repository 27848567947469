@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.container-shadow {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  .container-center {
    position: absolute;
    top: 40px;
    right: 60px;
    bottom: 0px;
    left: 60px;
    border-radius: 20px;
    overflow-y: scroll;
    max-width: 1200px;
    margin: 40px auto;
    .container-tems-conditions {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 50px 60px 0px 60px;
      border-radius: 20px;
      overflow-y: scroll;
      z-index: 1000;
      font-family: "Roboto", sans-serif;
      color: $color-secondary;
      .container-cancel {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .title-terms-and-conditions {
        font-size: $title-modal-font-size;
        font-weight: $font-weight-semi-bold;
        text-align: center;
        margin-bottom: 30px;
      }
      .text {
        font-size: $h5-font-size;
        text-align: start;
        color: $color-secondary;
        margin-bottom: 14px;
        font-family: "Roboto Regular";
      }
      .subtitle{
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .container-shadow {
    .container-center{
      .container-tems-conditions{
        padding: 50px 20px 0px 20px;
      }
    }
  }
}