.component-disclaimer-modal-general-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 2%;
  z-index: 5;
  background-color: rgba(2, 29, 135, 0.19);
}

.show-modal-disclaimer {
  display: block;
}

.hide-modal-disclaimer {
  display: none;
}

.component-disclaimer-modal-container {
  position: relative;
  width: 90%;
  height: 600px;
  background: white;
  margin: 0 auto;
  top: calc(40vh - 250px);
  box-shadow: 0px 25px 50px 0px rgb(0 0 0 / 25%);
  border-radius: 1rem;
}

.component-disclaimer-modal-wrapper {
  position: relative;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  padding: 3%;
}

.component-disclaimer-modal-x-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.component-disclaimer-modal-close-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.component-disclaimer-modal-close-icon {
  width: 17px;
  height: 17px;
  cursor: pointer;
  opacity: .8;
}

.component-disclaimer-modal-close-icon:hover {
  opacity: 1;
}

.component-disclaimer-modal-title-container {
  text-align: center;
  padding: 1%;
}

.component-disclaimer-modal-title {
  font-family: "Roboto" !important;
  font-size: 26px;
  font-weight: bold;
  color: #040560;
}

.component-disclaimer-modal-description-container {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 3% 1%;
}

.component-disclaimer-modal-description {
  font-family: "Roboto" !important;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2d2e8f;
  opacity: 1;
  line-height: 16px;
}

.component-disclaimer-modal-byma-description-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #f4f6fd 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
}

.component-disclaimer-modal-byma-description {
  font: normal normal 600 14px/22px Segoe UI;
  letter-spacing: 0px;
  color: #2d2e8f;
  opacity: 1;
}

.component-disclaimer-modal-time-container {
  position: relative;
  display: flex;
  width: 333px;
  height: 39px;
  margin: 0 auto;
  background: #e7ca04 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 10px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.component-disclaimer-modal-calendar-container {
  position: relative;
  display: flex;
  padding: 0% 4.5% 0% 0%;
}

.component-disclaimer-modal-time {
  text-align: center;
  font: normal normal bold 14px/32px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0% 2.5% 0% 0%;
}

.component-disclaimer-modal-prop-time {
  text-align: center;
  font: normal normal normal 12px/16px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.component-disclaimer-modal-confirmation-container {
  padding: 12% 0% 0% 0%;
}

.component-disclaimer-modal-confirmation-description {
  position: relative;
  display: flex;
  width: 210px;
  height: 43px;
  margin: 0 auto;
  background: #423994;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  font-family: "Roboto" !important;
  font-weight: 600;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
