@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.operate-container-schedule {
  .operate-container-schedule-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border-radius: 100px;
    padding: 5px 25px 5px 25px;
    height: 32px;
    background: #52b5b11a;
      color: $color-accent;
      border: 1px solid $color-accent;
    .operate-container-schedule-title,
    .operate-container-schedule-atention {
      font-size: $roboto-12-medium-font-size;
      font-weight: $font-weight-sm;
      text-align: center;
      padding: 0 5px 0px 0px;
      color: $color-accent;
      font-family: "Roboto";
    }
  }
}