.operate-custom-modal-finish-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(2, 29, 135, 0.19);
  height: 100vh;
  width: 100vw;
  z-index: 6000;
  display: none;
}

.operate-custom-modal-finish-container.open-modal {
  display: block;
}

.operate-custom-modal-finish-container-region {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 560px;
  z-index: 1000;
  box-shadow: 0px 3px 15px #00000029;
  border: none;
}

.operate-custom-modal-finish-container-region-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  min-height: 400px;
  background-color: #fff;
  margin: auto;
  top: 10vh;
  left: 33vw;
  position: absolute;
}

.operate-custom-modal-finish-container-region-content-cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  height: 5%;
  padding-top: 25px;
  padding-right: 25px;
  opacity: .8;
}

.operate-custom-modal-finish-container-region-content-cancel:hover {
  opacity: 1;
}

.operate-custom-modal-finish-container-region-content-cancel > img {
  cursor: pointer;
}

.operate-custom-modal-finish-container-region-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 280px;
  text-align: center;
}

.icon-modal {
  width: 10vw;
  margin: 20px;
}

.operate-custom-modal-finish-container-region-content-body-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  font-size: 26px;
  color: #040560;
  font-family: "Roboto" !important;
  font-weight: 700;
  margin: 10px;
}

.operate-custom-modal-finish-container-region-content-body-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  height: 10%;
  font-size: 14px;
  color: #040560;
  font-family: "Roboto" !important;
  font-weight: 700;
}

.operate-custom-modal-finish-container-region-content-body-description-no-load {
  color: #737dff;
  font-size: 14px;
  font-family: "Roboto" !important;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.operate-custom-modal-finish-container-region-content-body-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
}

.operate-custom-modal-finish-container-region-content-body-buttons-no-load {
  text-align: center;
  color: #737dff;
  font-size: 14px;
  font-family: "Roboto" !important;
  text-decoration: underline;
  cursor: pointer;
}

.icon {
  width: 120px;
  margin: 20px;
}
