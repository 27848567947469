@import '../../scss/fonts.scss';
@import '../../scss/colors.scss';

.input-container {
  position: relative;
  width: 100%;
  font-family: 'Roboto Regular', sans-serif;
  font-weight: 400;
  line-height: 1.3;
  font-size: $body-sm-font-size;
  .input-text {
    position: relative;
    width: 100%;
    outline: none;
    border: 1px solid $color-gray;
    background: transparent;
    padding: 10px 15px;
    border-radius: 20px;
    font-family: 'Roboto Regular', sans-serif;
    &::placeholder {
      font-family: 'Roboto Regular', sans-serif;
      font-size: 16px;
      background-color: transparent;
      color: $color-text-black;;
    }
  }
  > .anticon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $color-gray;
}
}
.input-text:-webkit-autofill,
.input-text:-webkit-autofill:hover,
.input-text:-webkit-autofill:focus,
.input-text:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    color: $color-text-black;
    background-color: transparent !important;
    font-family: 'Roboto Regular', sans-serif;
}
.input-text::placeholder {
  font-family: 'Roboto Regular', sans-serif;
}

.input-text:-ms-input-placeholder {
  font-family: 'Roboto Regular', sans-serif;
}

.input-text::-webkit-input-placeholder {
  font-family: 'Roboto Regular', sans-serif;
}
