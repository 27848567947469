@import "../../../../scss/colors.scss";
@import "../../../../scss/fonts.scss";

.movements-container {
  position: relative;
  box-shadow: 0px 2px 8px 0px #00000026;
  height: 430px;
  border-radius: 20px;
  padding: 20px;
  background: $white;
  width: 50%;
  .movements-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    .movements-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      img {
        width: 35px;
        margin: 0px 15px 0px 0px;
      }
      h2 {
        font-size: $h3-font-size;
      }
    }
  }

  .movements-content {
    overflow: auto;
    width: 100%;
    height: calc(100% - 60px);
    &::-webkit-scrollbar {
      background: #ffffff;
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #f4f6fd;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 6px #d2daf4;
    }

    &:hover::-webkit-scrollbar {
      background: #f4f6fd;
    }
  }
}

.dot{
  width: 7px;
  height: 7px;
  margin: 0px 10px;
}