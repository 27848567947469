@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';

.navbar-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  width: 100%;
  background: $color-secondary;
  padding: 0px 30px;
  .navbar-user-full-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0;
    margin: 0;
    gap: 10px;
    cursor: pointer;
    .user-full-name {
      font-size: $footnote-description-font-size;
      font-family: 'Roboto';
      font-weight: $font-weight-sm;
      color: $color-background;
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }
  }
  .bank-account-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
    gap: 10px;
    .bank-account-icon {
      color: $color-background;
      & > svg {
        width: 25px;
        height: 25px;
      }
    }
    .bank-account-title {
      font-size: $footnote-description-font-size;
      font-family: 'Roboto';
      font-weight: $font-weight-sm;
      color: $color-background;
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }
  }
}

.navbar--main--container--left--tenencia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: start;
  color: #a4a7be;
}

.navbar--main--container--left--porcentual {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 600;
}

/* ****************************************** */

.lds-ellipsis {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 140px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 44px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 44px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 68px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 92px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.navbar-user-full-name.loading-user-information {
  background: #f4f6fd;
}
/* ****************************************** */

.icon-right-container {
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.icon-right-container.open-profile-view {
  transform: rotate(180deg);
}
.icon-right-container.open-bank-view{
  transform: rotate(180deg);
}

.divider-navbar {
  height: 25px;
  width: 1px;
  background: #d6d6d6;
  color: #d6d6d6;
  padding-bottom: 3px;
}

@media (max-width: 1168px) {
  .navbar--main--container--left {
    display: none;
  }
}

@media (max-width: 1168px) {
  .navbar--main--container--right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.navbar-icon-ticket-container {
  position: relative;
  display: inline-table;
  padding: 0 5% 0 0;
}

.navbar-icon-faq-container {
  position: relative;
  display: inline-table;
  padding: 0 5% 0 0;
}

.navbar-icon-mode-container {
  position: relative;
  display: inline-table;
}

.divider-navbar-container {
  position: relative;
  display: inline-block;
  padding: 0% 1.5%;
}

.navbar-icon-profile-container {
  position: relative;
  display: inline-table;
  padding: 0% 0% 0% 2%;
}

.navbar-user-profile-icon {
  position: relative;
  width: 30px;
  height: 30px;
}

.navbar-cart-icon {
  position: relative;
  width: 20px;
}

.navbar-general-icon {
  position: relative;
  width: 19px;
  height: 19px;
}

.navbar-icon-container {
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .logout-icon{
    height: 20px;
    width: 20px;
  }
}
